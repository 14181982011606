<template>


  <ion-page>


      <!-- <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start" >
            <ion-menu-button menu="custom"></ion-menu-button>
            <ion-back-button ></ion-back-button>
          </ion-buttons>
          <ion-title>ActivityEntry Page</ion-title>

        </ion-toolbar>
      </ion-header> -->

       <ion-header>
      <ion-toolbar>
        <ion-title>Code Example</ion-title>
        <ion-buttons slot="start">
          <ion-menu-button menu="custom"></ion-menu-button>
          <ion-back-button ></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>




    <ion-content>
      <ion-button @click="() => router.push('/ActivityEntryDetail/m1')">Geolocation Example</ion-button>
      <ion-button @click="() => router.push('/VuexExample/')">Vuex  Example</ion-button>
      <ion-button @click="() => router.push('/AutoComplete/')">Auto Complete Example</ion-button>
      <!-- <ion-button @click="() => router.push('/GeolocationExample/')">Geolocation Example</ion-button> -->

      <h1>IMPLEMENTING NATIVE DATE PICKER</h1>

        <ion-item>
          <ion-label fixed>From Date </ion-label>
          <!-- {{ $store.state.fromdate}} -->
          <ion-label fixed>:{{param.fromdate}}</ion-label>
          <ion-button fill="clear"  @click="formating_date('fromdate')">Select Date </ion-button>
        </ion-item>

        <ion-item>
          <ion-label fixed>To Date </ion-label>
           <!-- {{ $store.state.todate}} -->
          <ion-label fixed>:{{param.todate}}</ion-label>
          <ion-button fill="clear"  @click="formating_date('todate')">Select Date </ion-button>
        </ion-item>


       <!-- <ion-button expand="block" @click="opendatepicker">Date Picker</ion-button>
       <ion-label fixed>:{{$store.state.displaydate}}</ion-label> -->

    </ion-content>
  </ion-page>
</template>

<script >
  import { IonButton, IonContent, IonPage ,IonHeader,
  IonToolbar,IonTitle,IonBackButton,IonButtons,modalController} from '@ionic/vue';
  import { defineComponent } from 'vue';
  import { useRouter } from 'vue-router';
 // import { useStore  } from 'vuex'
  import {reactive} from 'vue'
  import datepickermodal from '@/components/others/datepickermodal.vue';

  export default defineComponent({
    name: 'ActivityEntry',
    components: {
      IonButton,
      IonContent,
      IonPage,
      IonHeader,IonToolbar,IonTitle,IonBackButton,IonButtons
    },

    setup() {
      const router = useRouter();
     // const store = useStore();
      const param=reactive({fromdate:'',todate:''});

      //console.log('store.state.fromdate'+store.state.fromdate);


       const getdate= async (dates,date_picker_name)=>
      {
          console.log('received date:'+dates);
          if(date_picker_name=='fromdate')
          {param.fromdate=dates.data;}

          if(date_picker_name=='todate')
          {param.todate=dates.data;}

      }



      const formating_date= async (date_picker_name)=>
      {
          const modal = await modalController
            .create({
              component: datepickermodal,
              cssClass: 'my-custom-class',
              componentProps: {
                title: 'Date Pick',
                date_picker_name: date_picker_name
              },
            });

            modal.onDidDismiss().then((data) => {
                getdate(data,date_picker_name);
            });

          return modal.present();
      }




      return { router,formating_date,getdate,param };
    },
  });
</script>