<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ $store.state.tour_plan.tour_plan[tourplan_index].fields_output }}</ion-title>

            <ion-buttons slot="primary">
                <ion-button @click="closemodal">X</ion-button>
            </ion-buttons>

    </ion-toolbar>
  </ion-header>
 <!-- {{$store.state.tour_plan.tour_plan[tourplan_index]}} -->
  <ion-content class="ion-padding">
           <ion-item>
              <ion-label>Other Plan (if Any)</ion-label>
                <ion-select v-model="$store.state.tour_plan.tour_plan[tourplan_index].other_activity_id">
                  <ion-select-option
                  v-for="other_activity_list in $store.state.user.other_activity_list" :key="other_activity_list.id"
                  :value="other_activity_list.id"  >{{other_activity_list.name}}
                  </ion-select-option>
                </ion-select>
            </ion-item>

          <ion-item>
                <ion-label position="floating">Details</ion-label>
                <ion-textarea v-model="$store.state.tour_plan.tour_plan[tourplan_index].other_activity_detail"></ion-textarea>
          </ion-item>


          <ion-item v-for="(tour_plan_list,tour_index) in $store.state.tour_plan.tour_plan[tourplan_index].fields" :key="tour_plan_list.id">
              <ion-label >{{tour_index+1}} - {{tour_plan_list.hierarchy_name}}</ion-label>
              <ion-checkbox slot="end"  v-model="tour_plan_list.selected" >
                {{tour_plan_list.selected}}
              </ion-checkbox>
          </ion-item>
      <!-- {{ $store.state.tour_plan.tour_plan[tourplan_index].fields }} -->
  </ion-content>


</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar ,IonButtons,
IonButton,IonLabel,IonCheckbox,IonItem,
IonTextarea, IonSelect,  IonSelectOption,
 modalController} from '@ionic/vue';



//import config from '../config.js'
import { defineComponent } from 'vue';
//import { useStore  } from 'vuex'
//import {ref} from 'vue'
//import {reactive} from 'vue'

export default defineComponent({
  name: 'Modal',
  props: {
    title: { type: String, default: 'Super Modal' },
    tourplan_index: { type: String  , default: 'Super Modal' },
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar,IonButtons,IonButton,IonLabel,IonCheckbox,IonItem,IonTextarea, IonSelect,  IonSelectOption,},
  setup()
  {

    const closemodal= async ()=>
    { await modalController.dismiss();}

    return { content: 'Content123654',closemodal }
  }

});
</script>