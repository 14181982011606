<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>VueX Example</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <!-- <slot> </slot> -->
      <!-- <ion-button expand="full" @click="showUsers()">Show Users</ion-button>
      <ion-card v-for="user in allUsers" :key="user.id">
        <ion-card-content>
          <ion-card-title>{{user.name}}</ion-card-title>
        </ion-card-content>
        <ion-item>
          <ion-label>Email</ion-label>{{user.email}}
        </ion-item>
      </ion-card> -->
       <ion-button @click="() => router.push('/')">Go To main page</ion-button>
        <ion-item>
          <ion-label>Name from VueX : </ion-label>{{$store.state.name}}
         </ion-item>

          <ion-item>
           <ion-label><ion-button expand="full" @click="getuser" >Show Users</ion-button> </ion-label>
           Address:  {{$store.state.Address}}
          </ion-item>

           <ion-item>
           <ion-label>All user from Computed : {{SetUserComputedMethod}}</ion-label>
          </ion-item>

           <ion-item>
           <ion-label><ion-button expand="full" @click="AllUser" >All Users</ion-button> </ion-label>
           {{$store.state.user[1]}}
          </ion-item>


          <!-- USE OF GETTERS IN VUEX -->
          <ion-item>
           <ion-label>Mobile No : {{GetUserComputedMethod}}</ion-label>
           <ion-label>return_val : {{return_val}}</ion-label>

          </ion-item>






    </ion-content>
  </ion-page>
</template>
<script>
import {IonPage,IonHeader,IonContent,IonToolbar,IonTitle,IonItem,IonLabel,IonButton} from "@ionic/vue";
import {ref,reactive, defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
//import {mapGetters,mapActions} from 'vuex';

import { useStore  } from 'vuex'
import {computed} from 'vue'

export default defineComponent({

  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonItem,IonLabel,IonButton

  },
  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const param_ref=ref(0);
    //const error=ref(null);
    const param_reactive=reactive({
      Name:'Adipta Das',
      mob:8013834443,
      Address:'Kolkata 79'
    });




    const store = useStore();

    //COMPUTED METHOD
    const SetUserComputedMethod=computed(()=>store.dispatch('action_fetchUsers'));
    const GetUserComputedMethod=computed(()=>store.state.mobno);


    const return_val=reactive(store.state.user );
    //METHOD PORTION
    //calling Action method of Store
    const getuser=async ()=>
    { await store.dispatch('action_setuser',param_reactive)};

    const AllUser= async ()=>{
      await store.dispatch('action_fetchUsers');
    }



    //RETURN PORTION
    return{router,param_ref,param_reactive,getuser,AllUser,SetUserComputedMethod,GetUserComputedMethod,return_val};


  }
});
</script>
<style scoped>
ion-toolbar{
    --background: var(--ion-color-primary);
    --color: var(--ion-color-primary-contrast)
}
</style>