<template>

  <ion-page>

      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start" >
            <ion-menu-button menu="custom"></ion-menu-button>
            <ion-back-button ></ion-back-button>
          </ion-buttons>
          <ion-title>Missed Call Details</ion-title>
        </ion-toolbar>
      </ion-header>

     <ion-content>
              <!-- {{$store.state.user.hqlist.hq_id}} -->
              <ion-item>
                <ion-label fixed>Select HQ</ion-label>
                  <ion-select v-model="param.hq_id">
                      <ion-select-option  v-for="hqs in $store.state.user.hqlist.hq_list" :key="hqs.hq_id" :value="hqs.hq_id">
                      {{hqs.hq_name}}
                      </ion-select-option>
                  </ion-select>
              </ion-item>

              <ion-item>
                <ion-label fixed>Month Year</ion-label>
                  <ion-select v-model="param.monthyear">
                      <ion-select-option  v-for="monthyr in $store.state.user.month_year" :key="monthyr.month_year_numeric" :value="monthyr.month_year_numeric">
                      {{monthyr.monthyear}}
                      </ion-select-option>
                  </ion-select>
              </ion-item>


              <ion-button expand="block" @click="LoadMissedDoctorvisitList">Load Visit</ion-button>

                <!-- {{$store.state.user}} -->
               <!-- {{$store.state.SaveServerResponse}} -->

                <!-- {{$store.state.DoctorList.DOCTORS}} -->

              <ion-item-group v-for="(data_list,data_index) in $store.state.DoctorList.DOCTORS" :key="data_index">

                    <!-- <ion-item-divider> <ion-label>DOCTOR WISE VISIT</ion-label> </ion-item-divider> -->

                        <ion-item-group>

                                <ion-item   class="ion-divider-main" v-if="data_list.color_code==='OK'" >
                                  <ion-label>{{data_index+1}}. {{data_list.name}}</ion-label>
                                </ion-item>
                                <ion-item   class="ion-divider-main lessvisit" v-if="data_list.color_code==='NOT_OK'" >
                                  <ion-label>{{data_index+1}}. {{data_list.name}} (Less Visit)</ion-label>
                                </ion-item>

                                <ion-item lines="none">
                                  <ion-label>Location     :  {{data_list.Location}}</ion-label>
                                </ion-item>
                                  <ion-item lines="none">
                                  <ion-label>Last Date of Visit: {{data_list.last_visit_date}}</ion-label>
                                </ion-item>
                                  <!-- <ion-item lines="none">
                                  <ion-label>Total Visit  :  {{data_list.no_of_visit}}</ion-label> -->
                                <!-- </ion-item> -->

                        </ion-item-group>

                         <!-- <ion-item-group  v-if="data_list.color_code==='OK'" class="lessvisit"  >
                                <ion-item   class="ion-divider-main" >
                                  <ion-label>{{data_index+1}}. {{data_list.name}} </ion-label>
                                </ion-item>
                                <ion-item lines="none">
                                  <ion-label>Location     :  {{data_list.Location}}</ion-label>
                                </ion-item>
                                  <ion-item lines="none">
                                  <ion-label>Date of Visit: {{data_list.visit_days}}</ion-label>
                                </ion-item>
                                  <ion-item lines="none">
                                  <ion-label>Total Visit  :  {{data_list.no_of_visit}}</ion-label>
                                </ion-item>
                        </ion-item-group> -->

            </ion-item-group>



    </ion-content>

  </ion-page>
</template>

<script>

import {
  IonPage,IonHeader,IonContent,IonToolbar,IonTitle,
  IonItem,IonSelect,IonSelectOption,
  IonButtons,IonMenuButton,
  IonButton,IonLabel,loadingController, toastController,IonItemGroup}
  from "@ionic/vue";

  //IonItemGroup, IonItemDivider,

import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex'
import {reactive} from 'vue'
//import doctor_wise_monthly_sale_entry from '@/components/doctor_section/doctor_wise_monthly_sale_entry.vue';

export default defineComponent({

  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonButtons,IonMenuButton,
    IonItem,  IonLabel,IonSelect,IonSelectOption,IonButton,IonItemGroup,
  },

    //IonItemGroup, IonItemDivider,

  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

    const param=reactive({

      monthyear:'',
      hq_id:'',
      subtype:'DOC_LIST_SALE',
      hostname:config.hostname,
      hostconfig: config.config
    });

     param.hq_id=store.state.user.tbl_hierarchy_org_id;

     const LoadMissedDoctorvisitList= async ()=>{

        console.log(param.monthyear);
        //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Loading ..... Please wait...',
        });
        await loading.present();

      //LOADING FUNCTION
     // await store.dispatch('LoadDoctorSaleList',param);

      await store.dispatch('LoadMissedDoctorvisitList',param);
      loading.dismiss();

      //  //Toast START
      const toast = await toastController
        .create({
          message: 'Doctor Loaded..',
          duration: 1000
        })
      toast.present();
      // //Toast END

    }
    //LOAD TOUR PLAN END

    //OPEN MODAL POPUP FOR DETAIL TOUR PLAN



    return{router,store,param,LoadMissedDoctorvisitList};

  }
});
</script>