<template>

  <ion-page>

      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start" >
            <ion-menu-button menu="custom"></ion-menu-button>
            <ion-back-button ></ion-back-button>
          </ion-buttons>
          <ion-title>Tour Plan</ion-title>
        </ion-toolbar>
      </ion-header>

     <ion-content>
              <!-- {{$store.state.user.hqlist.hq_id}} -->
              <ion-item>
                <ion-label fixed>Select HQ</ion-label>
                  <ion-select v-model="param.hq_id">
                      <ion-select-option  v-for="hqs in $store.state.user.hqlist.hq_list" :key="hqs.hq_id" :value="hqs.hq_id">
                      {{hqs.hq_name}}
                      </ion-select-option>
                  </ion-select>

              </ion-item>

               <ion-item>
                 <ion-label fixed>From Date </ion-label>
                 <ion-label fixed>:{{param.fromdate}}</ion-label>
                 <ion-button fill="clear"  @click="formating_date('fromdate')">Select Date </ion-button>
              </ion-item>

              <ion-item>
                <ion-label fixed>To Date </ion-label>
                <ion-label fixed>:{{param.todate}}</ion-label>
                <ion-button fill="clear"  @click="formating_date('todate')">Select Date </ion-button>
              </ion-item>

              <ion-button expand="block" @click="display_tour_plan">Load Tour Plan</ion-button>


            <ion-item v-for="(tourplan_list,tourplan_index)
            in $store.state.tour_plan.tour_plan" :key="tourplan_list.tour_date"
            @click="openModal(tourplan_list.mst_office_calender_id,tourplan_index)">

                <ion-label >{{tourplan_index+1}}-{{tourplan_list.fields_output}}</ion-label>


            </ion-item>

           <!-- Response :   {{$store.state.SaveServerResponse}} -->
           <ion-button expand="block" @click="SaveTourPlan">Save Tour Plan</ion-button>


    </ion-content>

  </ion-page>
</template>

<script>

import {
  IonPage,IonHeader,IonContent,IonToolbar,IonTitle,
  IonItem, IonSelect,IonSelectOption,
  IonButton,
  loadingController,
  toastController,modalController }
  from "@ionic/vue";


import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex'
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue'
import TourPlanDetail from '@/components/tour_section/TourPlanDetail_v1.vue';
import datepickermodal from '@/components/others/datepickermodal.vue';

export default defineComponent({

  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonItem,IonSelect,IonSelectOption,IonButton
  },


  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

    const param=reactive({
      fromdate:'',
      todate:'',
      tbl_hierarchy_org_id:'',
      hq_id:'',
      tour_plan_data:'',
      hostname:config.hostname,
      hostconfig: config.config
    });


      var initial_date=  new Date().toLocaleString();  //06/04/2022
      initial_date=initial_date.slice(6, 10)+'-'+initial_date.slice(3, 5)+'-'+initial_date.slice(0, 2);
      param.fromdate=param.todate=initial_date;
      param.hq_id=store.state.user.tbl_hierarchy_org_id;

      //formating date function
      const formating_date= async (date_picker_name)=>
      {

           const modal = await modalController
            .create({
              component: datepickermodal,
              cssClass: 'my-custom-class',
              componentProps: {
                title: 'Date Pick',
                date_picker_name: date_picker_name
              },
            });

            modal.onDidDismiss().then((data) => {
                getdate(data,date_picker_name);
            });

          return modal.present();
      }

      const getdate= async (dates,date_picker_name)=>
      {
          console.log('received date:'+dates);
          if(date_picker_name=='fromdate')
          {param.fromdate=dates.data;}

          if(date_picker_name=='todate')
          {param.todate=dates.data;}

      }

    //formating date function END


    //LOAD TOUR PLAN

     const display_tour_plan= async ()=>{

      //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Loading ..... Please wait...',

        });
        await loading.present();


        //CREATE PAYLOAD
         param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;

         // PAYLOAD END

        console.log(param);

      //LOADING FUNCTION
      await store.dispatch('display_tour_plan',param);
      loading.dismiss();

      //  //Toast START
      const toast = await toastController
        .create({
          message: 'Tour Plan Loaded..',
          duration: 1000
        })
      toast.present();
      // //Toast END

    }
    //LOAD TOUR PLAN END


    //OPEN MODAL POPUP FOR DETAIL TOUR PLAN
    const openModal= async (mst_office_calender_id,tourplan_index,date_picker_name)=>
    {
        const modal = await modalController
          .create({
            component: TourPlanDetail,
            cssClass: 'my-custom-class',
            componentProps: {
              title: mst_office_calender_id,
              tourplan_index: tourplan_index,
              date_picker_name: date_picker_name
            },
          });

          modal.onDidDismiss().then((data) => {
              getdate(data,date_picker_name);
          });

        return modal.present();
    }





    //SAVE TOUR PLAN
    const SaveTourPlan= async ()=>{

      //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Tour Plan Saving...',
        });
        await loading.present();


       //CREATE PAYLOAD
          param.tour_plan_data=store.state.tour_plan;
      // PAYLOAD END

      //SAVING SECTION
      await store.dispatch('SaveTourPlan',param);
      loading.dismiss();

       //Toast START
      const toast = await toastController
        .create({
          message: store.state.SaveServerResponse.message,
          duration: 3000
        })
      toast.present();
      //Toast END


    }



     //SAVE TOUR PLAN END


    return{router,store,param,formating_date,display_tour_plan,openModal,SaveTourPlan,getdate};
  }
});
</script>