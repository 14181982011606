<template>

   <ion-page>

      <ion-header>
        <ion-toolbar>
            <ion-title>Daily Plan</ion-title>
            <ion-buttons slot="start">
              <ion-menu-button menu="custom"></ion-menu-button>
            </ion-buttons>
            <img  slot="end" @click="DisplayPlan" src="assets/imgs/reload_small.png"  />
          </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">

              <!-- {{$store.state.dailytour_plan[0]['other_plan']}} -->

            <ion-card>

            <!-- <ion-item>
                  <ion-label>Other Plan (if Any)</ion-label>

              </ion-item>

            <ion-item>
                    <ion-label position="floating">Details  </ion-label>
                    <ion-textarea ></ion-textarea>
              </ion-item> -->

         <!-- <ion-card>
                <ion-item>
                      <ion-label>Other Plan (if Any)</ion-label>
                        <ion-select v-model="$store.state.dailytour_plan['other_plan']['other_activity_id']">
                          <ion-select-option
                          v-for="other_activity_list in $store.state.user.other_activity_list" :key="other_activity_list.id"
                          :value="other_activity_list.id"  >{{other_activity_list.name}}
                          </ion-select-option>
                        </ion-select>

                  </ion-item>

                <ion-item>
                        <ion-label position="floating">Details  </ion-label>
                        <ion-textarea v-model="$store.state.dailytour_plan['other_plan']['other_activity_details']"></ion-textarea>
                  </ion-item>
        </ion-card> -->

            </ion-card>


                  <ion-card v-for="(alllist,list_index)  in $store.state.dailytour_plan" :key="list_index" >
                      <ion-card-header  v-if="alllist.tbl_designation_id!= 5" class="ion-card-manager" >
                          <img  src="assets/imgs/manager.png" style="float: right; " />
                          <ion-card-title  >{{alllist.emp_name}} </ion-card-title>
                          <ion-card-subtitle color="ion-color-tertiary-shade">({{alllist.hq_name}}) <br><strong>Current Plan as Follows
                            </strong></ion-card-subtitle>
                      </ion-card-header>

                        <ion-card-header  v-if="alllist.tbl_designation_id==5" class="ion-card-mr">
                          <img  src="assets/imgs/manager.png" style="float: right; " />
                          <ion-card-title  >{{alllist.emp_name}} </ion-card-title>
                          <ion-card-subtitle color="ion-color-tertiary-shade">({{alllist.hq_name}}) <br><strong>Current Plan as Follows </strong></ion-card-subtitle>
                      </ion-card-header>


                      <ion-card-content>

                      <h4 v-for="(field_list,field_index) in alllist['view_plan']" :key="field_index"
                      style="padding-top:5px; white-space: pre-wrap;">
                        {{field_list.hierarchy_name}}<span v-if="alllist.tbl_designation_id != 5">
                          ({{field_list.HqEmpNmae}})</span>,
                      </h4>

                        <ion-item  class="ion-card-title" v-if="alllist.tbl_designation_id == 5 && $store.state.hq_desig_id_from_list != 5" >Add/Edit Join Plan with {{alllist.emp_name}}</ion-item>
                        <ion-item  class="ion-card-title" style="white-space: pre-wrap; "
                        v-if="alllist.tbl_designation_id == 5 && $store.state.hq_desig_id_from_list == 5" >Add/Edit  Plan of {{alllist.emp_name}}</ion-item>

                        <ion-list slot="content">
                                  <ion-item v-for="(field_list,field_index) in alllist['working_plan']" :key="field_index">
                                    <ion-label>{{field_list.hierarchy_name}}  </ion-label>
                                    <ion-checkbox slot="end"  v-model="field_list.selected" ></ion-checkbox>
                                  </ion-item>
                        </ion-list>

                      </ion-card-content>
                </ion-card>

                  <!-- {{$store.state.dailytour_plan['working_plan'][hq_index]}} -->
                  <!-- Selected : {{$store.state.dailytour_plan_save}} -->
              <ion-button expand="block" @click="SavedailyPlan">Save Daily Plan</ion-button>



      </ion-content>

   </ion-page>
</template>

<script>


// import { IonContent, IonHeader, IonTitle, IonToolbar ,IonButtons,
// IonButton,IonLabel,IonCheckbox,IonItem,
// IonTextarea, IonSelect,  IonSelectOption,
//  modalController} from '@ionic/vue';


//IonItemGroup,IonItemDivider,
import {IonPage, IonContent, IonHeader, IonTitle, IonToolbar ,IonButtons,IonMenuButton,
IonButton, IonItem,IonList, IonLabel,IonCheckbox,
//IonSelect,  IonSelectOption,IonTextarea,
IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
loadingController,toastController} from '@ionic/vue';

//import { add  } from 'ionicons/icons';

import { defineComponent } from 'vue';
import config from '@/config.js'
import { useStore  } from 'vuex'
//import {ref} from 'vue'
import {reactive,onMounted} from 'vue'

export default defineComponent({
  name: 'dailyPlanDetail',
  components: {IonPage, IonContent, IonHeader, IonTitle, IonToolbar,IonButtons,IonButton,IonMenuButton,
   IonItem,IonList,  IonLabel,IonCheckbox,
  IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
  //IonSelect,  IonSelectOption,IonTextarea,
  },

  setup()
  {

        const store = useStore();

        const param=reactive({
            employee_id:'',
            tbl_hierarchy_org_id:'',
            tbl_designation_id:'',
            hq_id:'',
            currentdate:'',
            other_activity_id:'',
            other_activity_details:'',
            tour_plan_data:{},
            hostname:config.hostname,
            hostconfig: config.config
        });

        store.state.dailytour_plan={};

    //  var initial_date=  new Date().toLocaleString();  //06/04/2022
    //  initial_date=initial_date.slice(6, 10)+'-'+initial_date.slice(3, 5)+'-'+initial_date.slice(0, 2);
    //  param.currentdate= initial_date;
    //  param.hq_id=store.state.user.tbl_hierarchy_org_id;
    //  param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
    //  param.tbl_designation_id=store.state.user.tbl_designation_id;
    //  param.employee_id=store.state.user.id;


         //OPEN MODAL POPUP FOR DETAIL TOUR PLAN
    const DisplayPlan= async ()=>
    {
          //tran_date,hq_id,currenthq_designation_id
           var initial_date=  new Date().toLocaleString();  //06/04/2022
          initial_date=initial_date.slice(6, 10)+'-'+initial_date.slice(3, 5)+'-'+initial_date.slice(0, 2);
          param.currentdate= initial_date;
          param.hq_id=store.state.user.tbl_hierarchy_org_id;
          param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
          store.state.hq_desig_id_from_list=param.tbl_designation_id=store.state.user.tbl_designation_id;
          param.employee_id=store.state.user.id;

        console.log('currentdate '+param.currentdate+' tbl_hierarchy_org_id '+param.hq_id);

            //param.hq_id=hq_id;
            //param.currentdate=tran_date;
            //store.state.hq_desig_id_from_list=currenthq_designation_id;
            store.state.dailytour_plan_save={};

           const loading = await loadingController
            .create({
              cssClass: 'my-custom-class',
              message: 'Loading ..... Please wait...',

            });
            await loading.present();

            try{

                await store.dispatch('display_daily_plan',param);
                loading.dismiss();

            }
            catch(e)
            {

                  loading.dismiss()
                  //Toast START
                    const toast = await toastController
                      .create({
                        message: e,
                        duration: 3000
                      })
                    toast.present();
            }

    }

      const SavedailyPlan= async ()=>
      {

              param.other_activity_id=store.state.dailytour_plan[0]['other_plan'].other_activity_id;
              param.other_activity_details=store.state.dailytour_plan[0]['other_plan'].other_activity_details;

              //console.log('other_activity_id  '+param.other_activity_id);
              //console.log('other_activity_details  '+param.other_activity_details);

              //LOADING SECTION
               const loading = await loadingController
                .create({
                cssClass: 'my-custom-class',
                message: 'Saving ..... Please wait...',

                });
                await loading.present();


                //CREATE PAYLOAD
                      var saveindex=0;
                      store.state.dailytour_plan.forEach((hq_list, hq_index) =>
                      {
                          store.state.dailytour_plan[hq_index]['working_plan'].forEach((working_list, working_index) =>
                          {


                              if(working_list['selected'])
                              {
                                //param.tour_plan_data[working_index]=working_list;
                                store.state.dailytour_plan_save[saveindex]=working_list;
                                saveindex=saveindex+1;
                                console.log(working_index+' working_list'+working_list['selected']);
                                // console.log('==========================');
                              }
                          })
                    });

                    param.tour_plan_data=store.state.dailytour_plan_save;
                // PAYLOAD END

                //SAVING DATA

                try{

                       await store.dispatch('SavedailyPlan',param);
                       loading.dismiss();

                        //Toast START
                        const toast = await toastController
                        .create({
                            message: store.state.SaveServerResponse.message,
                            duration: 3000
                        })
                        toast.present();
                        //Toast END

                }
                catch (e)
                {

                    loading.dismiss()
                    //Toast START
                    const toast = await toastController
                        .create({
                        message: e,
                        duration: 3000
                        })
                    toast.present();

                }

      }

    //GOT THE CODE FROM CHATGPT OF MY LOGIN
    onMounted(() => {
      console.log('Component loaded');
      DisplayPlan();
      // Call any other initialization functions here
    });

    return { SavedailyPlan,DisplayPlan }
  }

});
</script>