<template>
  <ion-page>

   <ion-header>
      <ion-toolbar>
        <ion-title>{{param.name}}</ion-title>
        <!-- <img  slot="end" @click="LoadList" src="assets/imgs/reload_small.png"  /> -->
         <ion-button @click="closemodal" slot="end">X</ion-button>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
        <!-- <ion-item> Area Index:  {{$store.state.general_purpose_indx}} -- Doc Index  {{$store.state.general_purpose_indx2}}</ion-item> -->
        <!-- {{$store.state.DoctorList.DOCTORS.ACTIVE_DOCTORS[$store.state.general_purpose_indx]['doctors'][$store.state.general_purpose_indx2]}} -->

                <ion-item>
                    <ion-label position="floating">Retailer Name</ion-label>
                    <ion-input  type="text" v-model="param.retail_name" ></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label>Location</ion-label>
                        <ion-select v-model="param.retail_field">
                        <ion-select-option
                        v-for="list in $store.state.DoctorList.RETAILERS.RETAILERS.field_list" :key="list.id"
                        :value="list.id"  >{{list.hierarchy_name}}
                        </ion-select-option>
                        </ion-select>
                </ion-item>

                <ion-item>
                        <ion-label position="floating">Address</ion-label>
                        <ion-textarea v-model="param.retail_address"></ion-textarea>
                </ion-item>
                 <ion-item>
                    <ion-label position="floating">Contact No</ion-label>
                    <ion-input  type="text" v-model="param.retail_contact" ></ion-input>
                </ion-item>
                 <ion-item>
                    <ion-label position="floating">Contact Person</ion-label>
                    <ion-input  type="text" v-model="param.retail_contact_person" ></ion-input>
                </ion-item>

                 <ion-item>
                    <ion-label>Status</ion-label>
                        <ion-select v-model="param.status">
                        <ion-select-option
                        v-for="list in $store.state.DoctorList.RETAILERS.RETAILERS.status" :key="list"
                        :value="list"  >{{list}}
                        </ion-select-option>
                        </ion-select>
                </ion-item>

                <ion-button expand="block" @click="SaveRetailer">Save Retailer</ion-button>


    </ion-content>

  </ion-page>
</template>

<script>



//IonButton,
import {
 IonContent,IonHeader,IonPage,IonTitle,IonToolbar,IonItem,
 IonButton,IonLabel,
  IonInput,IonSelect,IonSelectOption,IonTextarea,
 //IonMenuButton, IonItemGroup,IonLabel,IonItemDivider,
 //IonButton
 modalController,toastController,loadingController,
} from '@ionic/vue';

import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex';
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue';
// import DCRdetailcomponent from '@/components/dcr_section/DCRdetail.vue';
// import datepickermodal from '@/components/datepickermodal.vue';
// import { Geolocation } from '@capacitor/geolocation';

export default defineComponent({
  components: { IonContent,IonHeader,IonPage,IonTitle,IonToolbar,IonItem,
 IonButton,IonLabel,
  IonInput,IonSelect,IonSelectOption,IonTextarea,
  //IonMenuButton,IonItemGroup,IonLabel,IonItemDivider
  //IonButton
  },

  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

     const param=reactive({

          REDIRECT_FROM_PAGE:'',
          ENTRY_TYPE:'',
          tbl_hierarchy_org_id:0,
          data_type:'',
          id:'',
          name:'',
          retail_field:0,
          hq_id:0,
          address:'',
          status:'ACTIVE',
          field_index:0,
          doctor_retailer_stockist_index:0,
          retail_name:'',
          retail_address:'',
          retail_contact:'',
          retail_contact_person:'',
          hostname:config.hostname,
          hostconfig: config.config
    });

      param.field_index= store.state.general_purpose_indx;
      param.doctor_retailer_stockist_index= store.state.general_purpose_indx2;
      param.ENTRY_TYPE= store.state.REPORT_TYPE;
      param.hq_id=store.state.hq_id;
      param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
      param.REDIRECT_FROM_PAGE=store.state.REDIRECT_FROM_PAGE;


      //console.log('field_index '+param.field_index+' doctor_retailer_stockist_index '+param.doctor_retailer_stockist_index);

      if(param.ENTRY_TYPE=='ADD')
      {
          param.id=0;
         // param.name='New Retailer';
          param.name= param.retail_name='';
          param.retail_field=0;
          param.retail_address='';
          param.retail_contact_person='';
          param.retail_contact='';

      }
      else
      {
          if(param.REDIRECT_FROM_PAGE=='ACTIVE')
          {
              param.id=store.state.DoctorList.RETAILERS.RETAILERS.ACTIVE_RETAILERS[param.field_index]['retailer'][param.doctor_retailer_stockist_index].id;
              param.retail_name=param.name=store.state.DoctorList.RETAILERS.RETAILERS.ACTIVE_RETAILERS[param.field_index]['retailer'][param.doctor_retailer_stockist_index].retail_name;
              param.retail_field=store.state.DoctorList.RETAILERS.RETAILERS.ACTIVE_RETAILERS[param.field_index]['retailer'][param.doctor_retailer_stockist_index].retail_field;
              param.retail_address=store.state.DoctorList.RETAILERS.RETAILERS.ACTIVE_RETAILERS[param.field_index]['retailer'][param.doctor_retailer_stockist_index].retail_address;
              param.retail_contact_person=store.state.DoctorList.RETAILERS.RETAILERS.ACTIVE_RETAILERS[param.field_index]['retailer'][param.doctor_retailer_stockist_index].retail_contact_person;
              param.retail_contact=store.state.DoctorList.RETAILERS.RETAILERS.ACTIVE_RETAILERS[param.field_index]['retailer'][param.doctor_retailer_stockist_index].retail_contact;
              param.hq_id=store.state.DoctorList.RETAILERS.RETAILERS.ACTIVE_RETAILERS[param.field_index]['retailer'][param.doctor_retailer_stockist_index].hq_id;
              param.status=store.state.DoctorList.RETAILERS.RETAILERS.ACTIVE_RETAILERS[param.field_index]['retailer'][param.doctor_retailer_stockist_index].status;
          }
          else
          {
              param.id=store.state.DoctorList.RETAILERS.RETAILERS.INACTIVE_RETAILERS[param.field_index]['retailer'][param.doctor_retailer_stockist_index].id;
              param.retail_name=param.name=store.state.DoctorList.RETAILERS.RETAILERS.INACTIVE_RETAILERS[param.field_index]['retailer'][param.doctor_retailer_stockist_index].retail_name;
              param.retail_field=store.state.DoctorList.RETAILERS.RETAILERS.INACTIVE_RETAILERS[param.field_index]['retailer'][param.doctor_retailer_stockist_index].retail_field;
              param.retail_address=store.state.DoctorList.RETAILERS.RETAILERS.INACTIVE_RETAILERS[param.field_index]['retailer'][param.doctor_retailer_stockist_index].retail_address;
              param.retail_contact_person=store.state.DoctorList.RETAILERS.RETAILERS.INACTIVE_RETAILERS[param.field_index]['retailer'][param.doctor_retailer_stockist_index].retail_contact_person;
              param.retail_contact=store.state.DoctorList.RETAILERS.RETAILERS.INACTIVE_RETAILERS[param.field_index]['retailer'][param.doctor_retailer_stockist_index].retail_contact;
              param.hq_id=store.state.DoctorList.RETAILERS.RETAILERS.INACTIVE_RETAILERS[param.field_index]['retailer'][param.doctor_retailer_stockist_index].hq_id;
              param.status=store.state.DoctorList.RETAILERS.RETAILERS.INACTIVE_RETAILERS[param.field_index]['retailer'][param.doctor_retailer_stockist_index].status;
          }

      }


    const SaveRetailer= async ()=>{


      //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Report Saving. Please wait...',

        });
        await loading.present();

      // PAYLOAD END


        try{
                //SAVING SECTION
                await store.dispatch('SaveRetailer',param);
                loading.dismiss();

                //Toast START
                const toast = await toastController
                  .create({
                    message: store.state.SaveServerResponse.message,
                    duration: 3000
                  })
                toast.present();
                //Toast END

               // closemodal();
        }
        catch (e)
        {
            //param.gps_error = e;
               loading.dismiss()
             //Toast START
              const toast = await toastController
                .create({
                  message: e,
                  duration: 3000
                })
              toast.present();

        }

    }

    const closemodal= async ()=>
    { await modalController.dismiss();}

    return{router,store,param,closemodal,SaveRetailer};
  }

});
</script>