<template>

  <ion-page>

      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start" >
            <ion-menu-button menu="custom"></ion-menu-button>
            <ion-back-button ></ion-back-button>
          </ion-buttons>
          <ion-title>Doctor wise monthly return Entry</ion-title>
        </ion-toolbar>
      </ion-header>

     <ion-content>
              <!-- {{$store.state.user.hqlist.hq_id}} -->
              <ion-item>
                <ion-label fixed>Select HQ</ion-label>
                  <ion-select v-model="param.hq_id">
                      <ion-select-option  v-for="hqs in $store.state.user.hqlist.hq_list" :key="hqs.hq_id" :value="hqs.hq_id">
                      {{hqs.hq_name}}
                      </ion-select-option>
                  </ion-select>
              </ion-item>

              <ion-item>
                <ion-label fixed>Month Year</ion-label>
                  <ion-select v-model="param.monthyear">
                      <ion-select-option  v-for="monthyr in $store.state.user.month_year" :key="monthyr.month_year_numeric" :value="monthyr.month_year_numeric">
                      {{monthyr.monthyear}}
                      </ion-select-option>
                  </ion-select>
              </ion-item>


              <ion-button expand="block" @click="doctor_wise_product_wise_sale_entry">Load Doctor</ion-button>

                <!-- {{$store.state.user}} -->
               <!-- {{$store.state.SaveServerResponse}} -->

              <!-- {{$store.state.DoctorSales.list}} -->


               <ion-item  color="danger"><ion-label>ALL DOCTORS </ion-label></ion-item>
                  <ion-item-group  class="ionheader" v-for="(area_list,area_index) in $store.state.DoctorSales.list" :key="area_list.area_id">

                        <ion-item-divider> <ion-label>{{area_index+1}}. Area/Location : {{area_list.area_name}} </ion-label> </ion-item-divider>

                            <ion-item-group  v-for="(doctor_list,doctor_index) in area_list.doctors" :key="doctor_list.id"
                            @click="()=>sale_entry(area_index,doctor_index,'doctors')" >

                                    <ion-item>
                                      <ion-label>{{doctor_index+1}}. {{doctor_list.name}} {{doctor_list.ENTRY_TYPE}}</ion-label>
                                        <ion-label v-if="doctor_list.ENTRY_TYPE === 'YES'" slot="end">
                                          <img src="assets/imgs/correct.png"  /></ion-label>


                                      </ion-item>



                            </ion-item-group>
                  </ion-item-group>






    </ion-content>

  </ion-page>
</template>

<script>

import {
  IonPage,IonHeader,IonContent,IonToolbar,IonTitle,
  IonItem,IonSelect,IonSelectOption,
  IonButtons,IonMenuButton,
  IonButton,IonItemGroup,  IonLabel, IonItemDivider,
  loadingController,
  toastController,modalController }
  from "@ionic/vue";


import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex'
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue'
import doctor_wise_product_wise_sale_entry_details
from '@/components/proposal_section/doctor_wise_product_wise_sale_entry_details.vue';


export default defineComponent({

  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonButtons,IonMenuButton,
    IonItem,IonItemGroup,  IonLabel, IonItemDivider,
    IonSelect,IonSelectOption,IonButton,
  },


  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

    const param=reactive({

      monthyear:'',
      hq_id:'',
      subtype:'doctor_wise_product_wise_sale_entry',
      hostname:config.hostname,
      hostconfig: config.config
    });

     param.hq_id=store.state.user.tbl_hierarchy_org_id;

     const doctor_wise_product_wise_sale_entry= async ()=>{

        console.log(param.monthyear);
        //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Loading ..... Please wait...',

        });
        await loading.present();

      //LOADING FUNCTION
      await store.dispatch('doctor_wise_product_wise_sale_entry',param);
      loading.dismiss();

      //  //Toast START
      const toast = await toastController
        .create({
          message: 'Doctor Loaded..',
          duration: 1000
        })
      toast.present();
      // //Toast END

    }
    //LOAD TOUR PLAN END

    //OPEN MODAL POPUP FOR DETAIL TOUR PLAN

    const sale_entry= async (area_index,doctor_index,TYPE_ENTRY)=>
    {
        console.log(param.monthyear);

        store.state.DCRdetail.hq_index=0;
        store.state.DCRdetail.field_index=area_index;
        store.state.DCRdetail.doctor_retailer_stockist_index=doctor_index;
        store.state.DCRdetail.data_type=TYPE_ENTRY;

        const modal = await modalController
          .create({
            component: doctor_wise_product_wise_sale_entry_details,
            cssClass: 'my-custom-class',
            componentProps: {
              doctor_index: doctor_index,
              area_index : area_index,
              TYPE_ENTRY : TYPE_ENTRY,
              monthyear:param.monthyear,
              hq_id:param.hq_id,

            },
          })
        return modal.present();
    }


    return{router,store,param,doctor_wise_product_wise_sale_entry,sale_entry};

  }
});
</script>