<template>
<ion-page>
      <ion-header>
      <ion-toolbar>
        <ion-title>Today's DCR</ion-title>
        <ion-buttons slot="start">
          <ion-menu-button menu="custom"></ion-menu-button>
        </ion-buttons>
        <img  slot="end" @click="GetTodayDCR" src="assets/imgs/reload_small.png"  />
      </ion-toolbar>
    </ion-header>

  <ion-content class="ion-padding">
      <!-- {{$store.state.SaveServerResponse}} -->
      <ion-card v-for="(alllist,list_index)  in $store.state.SaveServerResponse" :key="list_index" >

                  <ion-card-header  v-if="alllist.tbl_designation_id!= 5" class="ion-card-manager" >
                      <img  src="assets/imgs/manager.png" style="float: right; " />
                      <ion-card-title  >{{alllist.emp_name}} </ion-card-title>
                      <ion-card-subtitle color="ion-color-tertiary-shade">({{alllist.hq_name}}) </ion-card-subtitle>
                  </ion-card-header>

                    <ion-card-header  v-if="alllist.tbl_designation_id==5" class="ion-card-mr">
                      <img  src="assets/imgs/manager.png" style="float: right; " />
                      <ion-card-title  >{{alllist.emp_name}} </ion-card-title>
                      <ion-card-subtitle color="ion-color-tertiary-shade">({{alllist.hq_name}})
                       </ion-card-subtitle>
                  </ion-card-header>


                  <ion-card-content>

                   <!-- <h4 v-for="(doctor_dcr,doctor_dcr_index) in alllist['DOCTOR_DCR']" :key="doctor_dcr_index"
                   style="padding-top:5px; white-space: pre-wrap;">
                    {{doctor_dcr.Name}}
                   </h4> -->

                          <ion-list slot="content">

                                <ion-item  class="ion-card-title" >Doctor DCR</ion-item>
                                 <ion-grid  class="grid_header">
                                    <ion-row>
                                      <ion-col size="6" size-sm="2" >DocTor Name</ion-col>
                                      <ion-col size="6" size-sm="2" >Location</ion-col>
                                    </ion-row>
                                </ion-grid>

                                  <ion-grid class="grid_body" v-for="(dcr,dcr_index) in alllist['DOCTOR_DCR']" :key="dcr_index">
                                      <ion-row >
                                        <ion-col size="6" size-sm="3" >{{dcr.Name}} on {{dcr.report_date_time}}</ion-col>
                                        <ion-col size="6" size-sm="3" >{{dcr.Location}}</ion-col>
                                     </ion-row>
                                      <ion-item class="grid_body" style="word-wrap: break-word">
                                       Tracking Address : {{dcr.lat_long_address}}
                                      </ion-item>
                                      <ion-row v-if="dcr.tbl_designation_id != 5">
                                        <ion-col size="12" size-sm="3" >With ({{dcr.hqname}})</ion-col>
                                     </ion-row>
                                </ion-grid>

                              <ion-item  class="ion-card-title" >Retailer DCR</ion-item>
                                 <ion-grid  class="grid_header">
                                    <ion-row>
                                      <ion-col size="6" size-sm="2" >Retailer Name</ion-col>
                                      <ion-col size="6" size-sm="2" >Location</ion-col>
                                    </ion-row>
                                </ion-grid>

                                  <ion-grid class="grid_body" v-for="(dcr,dcr_index) in alllist['RETAILER_DCR']" :key="dcr_index">
                                      <ion-row >
                                        <ion-col size="6" size-sm="3" >{{dcr.Name}} on {{dcr.report_date_time}}</ion-col>
                                        <ion-col size="6" size-sm="3" >{{dcr.Location}}</ion-col>
                                     </ion-row>
                                       <ion-item class="grid_body" style="word-wrap: break-word">
                                       Tracking Address : {{dcr.lat_long_address}}
                                      </ion-item>
                                      <ion-row v-if="dcr.tbl_designation_id != 5">
                                        <ion-col size="12" size-sm="3" >With ({{dcr.hqname}})</ion-col>
                                     </ion-row>
                                </ion-grid>


                                <ion-item  class="ion-card-title" >Stockist DCR</ion-item>
                                 <ion-grid  class="grid_header">
                                    <ion-row>
                                      <ion-col size="6" size-sm="2" >Stockist Name</ion-col>
                                      <ion-col size="6" size-sm="2" >Location</ion-col>
                                    </ion-row>
                                </ion-grid>

                                  <ion-grid class="grid_body" v-for="(dcr,dcr_index) in alllist['STOCKIST_DCR']" :key="dcr_index">
                                      <ion-row >
                                        <ion-col size="6" size-sm="3" >{{dcr.Name}} on {{dcr.report_date_time}}</ion-col>
                                        <ion-col size="6" size-sm="3" >{{dcr.Location}}</ion-col>
                                     </ion-row>
                                      <ion-row v-if="dcr.tbl_designation_id != 5">
                                        <ion-col size="12" size-sm="3" >With ({{dcr.hqname}})</ion-col>
                                     </ion-row>
                                </ion-grid>

                                <ion-item  class="ion-card-title" >Other</ion-item>
                                 <ion-grid  class="grid_header">
                                    <ion-row>
                                      <ion-col size="6" size-sm="2" >Type</ion-col>
                                      <ion-col size="6" size-sm="2" >Description</ion-col>
                                    </ion-row>
                                </ion-grid>

                                  <ion-grid class="grid_body" v-for="(dcr,dcr_index) in alllist['OTHER_DCR']" :key="dcr_index">
                                      <ion-row >
                                        <ion-col size="12" size-sm="3" >{{dcr.tran_type}}</ion-col>
                                     </ion-row>
                                     <ion-row >
                                        <ion-col size="12" size-sm="3" >{{dcr.tran_desc}}</ion-col>
                                     </ion-row>

                                </ion-grid>




                              <!-- <ion-item  class="ion-card-title"  >Other </ion-item>
                              <ion-item v-for="(dcr,dcr_index) in alllist['OTHER_DCR']" :key="dcr_index">
                                <ion-grid>
                                  <ion-row><ion-col col-12  class="ion-card-title">{{dcr.tran_type}} </ion-col></ion-row>
                                  <ion-row><ion-col col-12>{{dcr.tran_desc}}</ion-col></ion-row>

                                </ion-grid>
                              </ion-item> -->



                                <!-- <ion-item v-for="(dcr,dcr_index) in alllist['DOCTOR_DCR']" :key="dcr_index">
                                <ion-grid>
                                  <ion-row><ion-col col-12  class="ion-card-title">{{dcr.Name}} on {{dcr.report_date_time}}</ion-col></ion-row>
                                  <ion-row v-if="dcr.tbl_designation_id != 5"><ion-col col-6>With ({{dcr.hqname}})</ion-col></ion-row>
                                  <ion-row><ion-col col-12>Location: {{dcr.Location}}</ion-col></ion-row>
                                  <ion-row><ion-col col-12>Tracking Address : {{dcr.lat_long_address}}</ion-col></ion-row>
                                </ion-grid>
                              </ion-item> -->


                             <!-- <ion-item  class="ion-card-title"     >Retailer DCR</ion-item>
                             <ion-item v-for="(dcr,dcr_index) in alllist['RETAILER_DCR']" :key="dcr_index">
                                <ion-grid>
                                  <ion-row><ion-col col-12  class="ion-card-title">{{dcr.Name}} on {{dcr.report_date_time}}</ion-col></ion-row>
                                  <ion-row v-if="dcr.tbl_designation_id != 5"><ion-col col-6>With ({{dcr.hqname}})</ion-col></ion-row>
                                  <ion-row><ion-col col-12>Location: {{dcr.Location}}</ion-col></ion-row>
                                </ion-grid>
                              </ion-item> -->


                              <!-- <ion-item  class="ion-card-title"    >Stockist DCR</ion-item>
                              <ion-item v-for="(dcr,dcr_index) in alllist['STOCKIST_DCR']" :key="dcr_index">
                                <ion-grid>
                                  <ion-row><ion-col col-12  class="ion-card-title">{{dcr.Name}} on {{dcr.report_date_time}}</ion-col></ion-row>
                                  <ion-row v-if="dcr.tbl_designation_id != 5"><ion-col col-6>With ({{dcr.hqname}})</ion-col></ion-row>
                                  <ion-row><ion-col col-12>Location: {{dcr.Location}}</ion-col></ion-row>

                                </ion-grid>
                              </ion-item> -->


                              <!-- v-if="alllist['RETAILER_DCR'].length != 0" -->


                               <!-- <ion-item v-for="(doctor_dcr,doctor_dcr_index) in alllist['DOCTOR_DCR']" :key="doctor_dcr_index">
                                {{doctor_dcr.Name}}
                                <span v-if="doctor_dcr.tbl_designation_id != 5">({{doctor_dcr.hqname}})</span>
                                <br>
                                Location: {{doctor_dcr.Location}}<br>
                                Tracking Address : {{doctor_dcr.lat_long_address}}
                              </ion-item> -->

                    </ion-list>

                  </ion-card-content>
            </ion-card>


       <!-- {{$store.state.SaveServerResponse}} -->

  </ion-content>

</ion-page>
</template>

<script>


import {IonPage,IonHeader,IonToolbar ,IonTitle,IonButtons,IonMenuButton, IonContent,
IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
IonGrid,IonRow,IonCol,
loadingController,} from '@ionic/vue';
//loadingController,toastController


import { defineComponent } from 'vue';
import config from '@/config.js'
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex'
//import {ref} from 'vue'
import {reactive,onMounted} from 'vue'
import {computed} from 'vue'


export default defineComponent({
  name: 'Modal',
  components: { IonPage,IonHeader,IonToolbar ,IonTitle,IonButtons,IonMenuButton, IonContent,
  IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,IonGrid,IonRow,IonCol, },

  setup()
  {
        const router=useRouter();
        const store = useStore();

        const param=reactive({
            employee_id:'',
            tbl_hierarchy_org_id:'',
            tbl_designation_id:'',
            hostname:config.hostname,
            hostconfig: config.config
        });

      const { x, y } = config.samplereuseable();

      console.log('X VALUE '+x.value+' Y value :'+y.value);

    param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
    param.tbl_designation_id=store.state.user.tbl_designation_id;
    param.employee_id=store.state.user.id;



    const GetTodayDCR=async ()=>
    {

        const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',

        });
        await loading.present();
        await store.dispatch('dcr_today',param);
        loading.dismiss();

    };

     const callfunction = computed(() => {
      //return author.books.length > 0 ? 'Yes' : 'No'
     GetTodayDCR()
      return '';
    });



      //GOT THE CODE FROM CHATGPT OF MY LOGIN
    onMounted(() => {
      console.log('Component loaded');
      GetTodayDCR();
      // Call any other initialization functions here
    });



    return { router,store,param,GetTodayDCR,callfunction,x,y}
  }

});
</script>