<template>
  <ion-page>

   <ion-header>
      <ion-toolbar>
        <ion-title>Report For {{param.Doctor_Retailer_stockist_name}} Date:{{param.trandate}}</ion-title>
        <ion-buttons slot="start">
          <!-- <ion-menu-button menu="custom"></ion-menu-button> -->
          <!-- <ion-back-button ></ion-back-button>          -->
        </ion-buttons>
         <ion-button @click="closemodal" slot="end">X</ion-button>
      </ion-toolbar>
    </ion-header>


    <ion-content class="ion-padding">

     <!-- {{$store.state.user['PRODUCT_GROUP']}} -->
     <!-- test {{$store.state.geotaging_data.geofence_message}} -->

      <!-- {{$store.state.DCRdetail.DCRdetail_data.sample}} -->

     <!-- DOCTOR SECTION -->
     <div v-if="$store.state.user.tbl_designation_id ==='5'">

          <div v-if="$store.state.DCRdetail.data_type === 'doctor_list' || $store.state.DCRdetail.data_type === 'chemist_list' ">


                  <!-- <ion-accordion-group >
                      <ion-accordion  v-for="(product_grp_list,grp_index) in $store.state.user['PRODUCT_GROUP']" :key="grp_index" >
                          <ion-item slot="header" color="light"><ion-label>{{product_grp_list.name}} </ion-label></ion-item>
                          <ion-item v-for="(sample_list,sample_index)  in $store.state.DCRdetail.DCRdetail_data.sample" :key="sample_index">

                                      <ion-label position="floating">{{sample_index+1}}-{{sample_list.brand_name}}</ion-label>
                                      <ion-input v-model="sample_list.qnty" ></ion-input>
                                      <ion-checkbox slot="end"  v-model="sample_list.promotion" @click="someHandler(sample_index)">
                                        {{sample_list.promotion}}
                                      </ion-checkbox>
                          </ion-item>
                      </ion-accordion>
                </ion-accordion-group> -->


                  <ion-list-header lines="inset" v-if="$store.state.DCRdetail.data_type === 'doctor_list'" ><ion-label> Sample/Gift Entry</ion-label></ion-list-header>
                <ion-item-divider><ion-label v-if="$store.state.DCRdetail.data_type === 'doctor_list'">Sample Section</ion-label></ion-item-divider>

                <ion-list-header lines="inset" v-if="$store.state.DCRdetail.data_type === 'chemist_list'" ><ion-label> POB/Gift Entry</ion-label></ion-list-header>
                <ion-item-divider><ion-label v-if="$store.state.DCRdetail.data_type === 'chemist_list'">POB Section</ion-label></ion-item-divider>

                 <ion-item >
                    <ion-label>Product Name</ion-label>
                    <ion-label>Qnty</ion-label>
                    <ion-label>Prescribe ?</ion-label>
                 </ion-item>

                    <ion-item v-for="(sample_list,sample_index)  in $store.state.DCRdetail.DCRdetail_data.sample" :key="sample_list.id">
                            <ion-label position="floating">{{sample_index+1}}-{{sample_list.brand_name}}</ion-label>
                            <ion-input v-model="sample_list.qnty" ></ion-input>
                            <ion-checkbox slot="end"  v-model="sample_list.promotion" >
                              <!-- @click="someHandler(sample_index)" -->
                              {{sample_list.promotion}}
                            </ion-checkbox>
                  </ion-item>


                  <ion-item-divider> <ion-label>Gift Section</ion-label></ion-item-divider>
                  <ion-item >
                      <ion-label>Gift Name</ion-label>
                      <ion-label>Qnty</ion-label>
                  </ion-item>
                  <ion-item v-for="(gift_list,gift_index) in $store.state.DCRdetail.DCRdetail_data.gift" :key="gift_list.id">
                    <ion-label position="floating">{{gift_index+1}}-{{gift_list.gift_name}}</ion-label>
                    <ion-input v-model="gift_list.qnty" ></ion-input>
                  </ion-item>
          </div>
       </div>



                <ion-item>
                  <ion-label>Work With</ion-label>
                    <ion-select v-model="$store.state.DCRdetail.DCRdetail_data.work_with_id">
                      <ion-select-option
                      v-for="work_with_list in $store.state.user.work_with" :key="work_with_list.work_with_id"
                      :value="work_with_list.work_with_id"  >{{work_with_list.work_with_name}}
                      </ion-select-option>
                    </ion-select>
              </ion-item>

              <ion-item>
                   <ion-label position="floating">Comment/Call Outcome</ion-label>
                    <ion-textarea v-model="$store.state.DCRdetail.DCRdetail_data.visit_details"></ion-textarea>
              </ion-item>
              <ion-button expand="block" @click="SaveDCR">Save Report</ion-button>


              <!-- <div padding>RESPONSE:   {{$store.state.SaveServerResponse}} </div> -->


             <!-- RETAILER SECTION -->


             <!-- STOKIST SECTION -->


    </ion-content>

  </ion-page>
</template>

<script>


// IonAccordion,IonAccordionGroup,
import {
  IonContent,IonHeader,IonPage,IonTitle,IonToolbar,
  IonButtons,IonItem,IonLabel,IonItemDivider,
  IonInput,IonSelect,IonSelectOption,IonTextarea,IonCheckbox,
  IonButton,loadingController,toastController,modalController
  //,IonListHeader,IonCheckbox,
} from '@ionic/vue';

import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex'
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue'
import { Geolocation } from '@capacitor/geolocation';


//IonAccordion,IonAccordionGroup,
export default defineComponent({
  components: { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
  IonButtons,IonItem,IonLabel,IonItemDivider,
 IonInput,IonSelect,IonSelectOption,IonTextarea,IonCheckbox,
  IonButton,
  //IonListHeader, IonCheckbox,
  },
  props: {
    title: { type: String, default: 'Super Modal' },
  },
  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

    const param=reactive({

          trandate:'',
          hq_index:0,
          field_index:0,
          doctor_retailer_stockist_index:0,
          tbl_hierarchy_org_id:'',
          tbl_designation_id:'',
          hq_id:'',
          field_id:'',
          id:'',
          Doctor_Retailer_stockist_name:'',
          visit_details:'',
          work_with_id:'',
          data_type:'',
          giftvalues:'0=0',
          samplevalues:'0=0:0=0',
          promotevalues:'0=0',
          gps_chkpermission_status:'',
          gps_error:'',
          latitude:'0',
          longitude:'0',
          hostname:config.hostname,
          hostconfig: config.config
    });



      param.trandate=store.state.DCRdetail.trandate;
      param.data_type=store.state.DCRdetail.data_type;
      param.hq_index= store.state.DCRdetail.hq_index;
      param.field_index= store.state.DCRdetail.field_index;
      param.doctor_retailer_stockist_index= store.state.DCRdetail.doctor_retailer_stockist_index;

      if(param.data_type=='doctor_list')
      {param.Doctor_Retailer_stockist_name=store.state.populate_data.hq_list[param.hq_index].field_list[param.field_index].doctor_list[param.doctor_retailer_stockist_index].name;}

      if(param.data_type=='chemist_list')
      {param.Doctor_Retailer_stockist_name=store.state.populate_data.hq_list[param.hq_index].field_list[param.field_index].chemist_list[param.doctor_retailer_stockist_index].retail_name;}

      if(param.data_type=='stockist_list')
      {param.Doctor_Retailer_stockist_name=store.state.populate_data.hq_list[param.hq_index].field_list[param.field_index].stockist_list[param.doctor_retailer_stockist_index].retail_name;}

      console.log('============AT DCR DETAIL OPEN======================== ');
      console.log('NAME  : '+ param.Doctor_Retailer_stockist_name);
      console.log('store.state.DCRdetail.hq_index : '+ store.state.DCRdetail.hq_index);
      console.log('store.state.DCRdetail.field_index=field_index : '+ store.state.DCRdetail.field_index);
      console.log('store.state.DCRdetail.doctor_retailer_stockist_index: '+ store.state.DCRdetail.doctor_retailer_stockist_index);
      console.log('store.state.DCRdetail.data_type '+ store.state.DCRdetail.data_type);


    const SaveDCR= async ()=>{


      //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Report Saving. Please wait...',

        });
        await loading.present();

        //GPS TRACKING
        try {

          const chkpermission = await Geolocation.checkPermissions();
          param.gps_chkpermission_status=chkpermission.coarseLocation;
          console.log('permission '+param.gps_chkpermission_status);
          const json = await  Geolocation.getCurrentPosition({ enableHighAccuracy: true,timeout: 5000});
          param.latitude = json.coords.latitude;
          param.longitude = json.coords.longitude;
        }
        catch (e)
        {
          param.gps_error = e;

        }

      //GPS TRACKING END



      param.trandate=store.state.DCRdetail.trandate;
      param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
      param.tbl_designation_id=store.state.user.tbl_designation_id;
      param.hq_id=store.state.populate_data.hq_list[param.hq_index].hq_id;

      param.data_type=store.state.DCRdetail.data_type;
      param.field_id=store.state.populate_data.hq_list[param.hq_index].field_list[param.field_index].field_id;
      param.work_with_id=store.state.DCRdetail.DCRdetail_data.work_with_id;
      param.visit_details=store.state.DCRdetail.DCRdetail_data.visit_details;

     //DOCTOR ID
      if(param.data_type=='doctor_list')
      {param.id=store.state.populate_data.hq_list[param.hq_index].field_list[param.field_index].doctor_list[param.doctor_retailer_stockist_index].id;}

      if(param.data_type=='chemist_list')
      {param.id=store.state.populate_data.hq_list[param.hq_index].field_list[param.field_index].chemist_list[param.doctor_retailer_stockist_index].id;}

      if(param.data_type=='stockist_list')
      {param.id=store.state.populate_data.hq_list[param.hq_index].field_list[param.field_index].stockist_list[param.doctor_retailer_stockist_index].id;}


      //SAMPLE SECTION
      store.state.DCRdetail.DCRdetail_data.sample.forEach((value, index) =>
      {
          console.log('store.state.DCRdetail.DCRdetail_data.sample[index].promotion '+store.state.DCRdetail.DCRdetail_data.sample[index].promotion);

          if(store.state.DCRdetail.DCRdetail_data.sample[index].qnty>0 || store.state.DCRdetail.DCRdetail_data.sample[index].promotion==true)
          {
            let promote=0;
            if(store.state.DCRdetail.DCRdetail_data.sample[index].promotion==true)
            {promote=1;}

            param.samplevalues=param.samplevalues+'|'+store.state.DCRdetail.DCRdetail_data.sample[index].id+
            '='+Number(store.state.DCRdetail.DCRdetail_data.sample[index].qnty)
           +':PROMOTE='+Number(promote);
          }

     });

      //GIFT SECTION
      if(param.data_type!='stockist_list')
      {
           store.state.DCRdetail.DCRdetail_data.gift.forEach((value, index) =>
            {

                if(store.state.DCRdetail.DCRdetail_data.gift[index].qnty>0)
                {
                  param.giftvalues=param.giftvalues+'|'+store.state.DCRdetail.DCRdetail_data.gift[index].id+
                  '='+Number(store.state.DCRdetail.DCRdetail_data.gift[index].qnty);
                }

          });
      }


    // PAYLOAD END


        try{


              //console.log('hq_index='+param.hq_index+' field_index'+param.field_index+' doctor_retailer_stockist_index'+param.doctor_retailer_stockist_index);
              //console.log('Doctor id='+param.id+'param.data_type'+param.data_type);

                  // console.log('============BEFORE SAVE======================== ');
                  // console.log('store.state.DCRdetail.hq_index : '+ store.state.DCRdetail.hq_index);
                  // console.log('store.state.DCRdetail.field_index=field_index : '+ store.state.DCRdetail.field_index);
                  // console.log('store.state.DCRdetail.doctor_retailer_stockist_index: '+ store.state.DCRdetail.doctor_retailer_stockist_index);
                  // console.log('param.trandate '+ param.trandate);

                //SAVING SECTION

                await store.dispatch('SaveDCR',param);
                //await store.dispatch('populate_data',param);

                  if(param.data_type=='doctor_list')
                  {store.state.populate_data.hq_list[param.hq_index].field_list[param.field_index].doctor_list[param.doctor_retailer_stockist_index].reporting_activity_details='REPORTED';}

                  if(param.data_type=='chemist_list')
                  {
                    store.state.populate_data.hq_list[param.hq_index].field_list[param.field_index].chemist_list[param.doctor_retailer_stockist_index].reporting_activity_details='REPORTED';
                  }

                  if(param.data_type=='stockist_list')
                  {
                     store.state.populate_data.hq_list[param.hq_index].field_list[param.field_index].stockist_list[param.doctor_retailer_stockist_index].reporting_activity_details='REPORTED';
                  }


                  // console.log('============AFTER SAVE======================== ');
                  // console.log('store.state.DCRdetail.hq_index : '+ store.state.DCRdetail.hq_index);
                  // console.log('store.state.DCRdetail.field_index=field_index : '+ store.state.DCRdetail.field_index);
                  // console.log('store.state.DCRdetail.doctor_retailer_stockist_index: '+ store.state.DCRdetail.doctor_retailer_stockist_index);
                  // console.log('store.state.DCRdetail.data_type '+ store.state.DCRdetail.data_type);

                  store.state.DCRdetail.hq_index=0;
                  store.state.DCRdetail.field_index=0;
                  store.state.DCRdetail.doctor_retailer_stockist_index=0;
                  store.state.DCRdetail.data_type='';
                  store.state.DCRdetail.DCRdetail_data.work_with_id=0;
                  store.state.DCRdetail.DCRdetail_data.visit_details='';


                loading.dismiss();

                //Toast START
                const toast = await toastController
                  .create({
                    message: store.state.SaveServerResponse.message,
                    duration: 3000
                  })
                toast.present();
                //Toast END

                closemodal();

                  // if(param.data_type=='doctor_list')
                  // { router.push('/DCREntry/Doctor/');}

                  // if(param.data_type=='chemist_list')
                  // { router.push('/DCREntry/Retailer/');}

                  // if(param.data_type=='stockist_list')
                  // { router.push('/DCREntry/Stockist/');}

        }
        catch (e)
        {
            //param.gps_error = e;
               loading.dismiss()
             //Toast START
              const toast = await toastController
                .create({
                  message: e,
                  duration: 3000
                })
              toast.present();

        }

    }

     const closemodal= async ()=>
    { await modalController.dismiss();}

    return{router,store,param,SaveDCR,closemodal};
  }

});
</script>