<template>

<ion-page>

     <ion-header>
        <ion-toolbar>
            <ion-title>Proposal Entry For {{$store.state.DCRdetail.name}}</ion-title>
            <ion-buttons slot="start">
            <ion-menu-button menu="custom"></ion-menu-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>

        <ion-content class="ion-padding">
              <ion-item>
                <ion-label fixed>Tran Date </ion-label>
                <ion-label fixed>:{{param.tran_date}}</ion-label>
                <ion-button fill="clear"  @click="formating_date('tran_date')">Select Date </ion-button>
              </ion-item>

                <!-- <ion-item>
                    <ion-label>Type</ion-label>
                        <ion-select v-model="param.tran_type">
                        <ion-select-option
                        v-for="other_activity_list in $store.state.user.other_activity_list" :key="other_activity_list.id"
                        :value="other_activity_list.id"  >{{other_activity_list.name}}
                        </ion-select-option>
                        </ion-select>
                </ion-item> -->

                <ion-item>
                        <ion-label position="floating">Details</ion-label>
                        <ion-textarea v-model="param.tran_desc"></ion-textarea>
                </ion-item>

                   <ion-item>
                        <ion-label position="floating">Proposal Amount</ion-label>
                        <ion-input  type="text" v-model="param.other_expense" ></ion-input>
                    </ion-item>

                <ion-button expand="block" @click="save_proposal">Save Proposal</ion-button>
        </ion-content>

</ion-page>

</template>

<script>
import {IonPage, IonContent, IonHeader, IonTitle, IonToolbar ,
IonButtons,IonMenuButton,
IonButton,IonLabel,IonItem,IonInput,
IonTextarea,
//IonSelect,  IonSelectOption,
loadingController,toastController,modalController
} from '@ionic/vue';

import config from '@/config.js'
import { defineComponent } from 'vue';
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex'
//import {ref} from 'vue'
import {reactive} from 'vue'
import datepickermodal from '@/components/others/datepickermodal.vue';

export default defineComponent({
  name: 'Modal',
  props: {
    title: { type: String, default: 'Super Modal' },
    tourplan_index: { type: String  , default: 'Super Modal' },
  },
  components: {IonPage, IonContent, IonHeader, IonTitle, IonToolbar,
  IonButtons,IonMenuButton,IonInput,
  IonButton,IonLabel,
  //IonSelect,  IonSelectOption,
  IonItem,IonTextarea, },
  setup()
  {
        const router=useRouter();
        const store = useStore();


        const param=reactive({
            other_activity_id:'',
            tbl_employee_mstr_id:'',
            doctor_retailer_id:'',
            tran_max_date:'',
            tran_date:'',
            tran_type:'',
            tran_desc:'',
            other_expense:'',
            tbl_hierarchy_org_id:'',
            hq_id:'',
            hostname:config.hostname,
            hostconfig: config.config
        });

      var initial_date=  new Date().toLocaleString();  //06/04/2022
      param.tran_max_date= initial_date=initial_date.slice(6, 10)+'-'+initial_date.slice(3, 5)+'-'+initial_date.slice(0, 2);
      param.tran_date=initial_date;

      //formating date function
      const formating_date= async (date_picker_name)=>
      {

           const modal = await modalController
            .create({
              component: datepickermodal,
              cssClass: 'my-custom-class',
              componentProps: {
                title: 'Date Pick',
                date_picker_name: date_picker_name
              },
            });

            modal.onDidDismiss().then((data) => {
                getdate(data,date_picker_name);
            });

          return modal.present();
      }

      const getdate= async (dates,date_picker_name)=>
      {
          if(date_picker_name=='tran_date')
          {param.tran_date=dates.data;}

      }

    //formating date function END

      const save_proposal= async ()=>
      {
        //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Loading ..... Please wait...',

        });
        await loading.present();


        //CREATE PAYLOAD
         param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
         param.tbl_employee_mstr_id=store.state.user.id;
         param.doctor_retailer_id=store.state.DCRdetail.doctor_retailer_id;

        //console.log('param.tbl_employee_mstr_id'+param.tbl_employee_mstr_id);

         // PAYLOAD END

          //console.log(param);

        //LOADING FUNCTION
        await store.dispatch('save_proposal',param);
        loading.dismiss();

        //  //Toast START
        const toast = await toastController
          .create({
            message: store.state.SaveServerResponse.server_msg,
            duration: 3000
          })
        toast.present();
        // //Toast END


      }


    return { content: 'Content123654',router,param,formating_date,save_proposal }

  }

});
</script>