import { ref } from 'vue'


export default
{
    //company_name:'DEVELOPMENT',
    //hostname: 'http://localhost/ALL_PHARMACEUTICAL_SOFTWARE/develop_for_surojit_company/ControllerForDcrMobile/',

    //company_name:'PHARMACIA LAB',
    //hostname: 'https://pharmacialab.co.in/ControllerForDcrMobile/',
   // hostname: 'https://pharmacialab.co.in/development/ControllerForDcrMobile/',

   // company_name:'Pharmacia Lab old',
   // hostname: 'https://pharmacialab.co.in/Android_api_ionic_vue/',

    //company_name:'Lasco Medicare',
    //domain_name: 'https://lascomedicare.com/activity/',
    //hostname: 'https://lascomedicare.com/activitynew/ControllerForDcrMobile/',

    company_name:'Saviour Healthcare',
    //hostname: 'https://saviourhealthcare.ind.in/ControllerForDcrMobile/',
    hostname: 'https://saviourhealthcare.ind.in/development/ControllerForDcrMobile/',

    // company_name:'AITIANA',
    // hostname: 'https://aitiana.in/bus/Android_api/',

    config:{headers :{'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'}},

    samplereuseable()
    {
        // state encapsulated and managed by the composable
        const x = ref(0)
        const y = ref(0)
         x.value=100;
         y.value=250;
         // expose managed state as return value
         return { x, y }

    }


}


