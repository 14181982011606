<template>
  <ion-page>

    <ion-content>
      <ion-tabs>
        <ion-router-outlet></ion-router-outlet>

        <!-- <ion-fab slot="fixed" vertical="top" horizontal="end">
            <ion-fab-button  @click="()=>Doctor_add_edit()">
              <ion-icon :icon="add"></ion-icon>
            </ion-fab-button>
        </ion-fab> -->

        <ion-tab-bar slot="bottom" >

          <ion-tab-button  tab="Retailer_master_active"   @click="() => router.push('/Retailer_master/Retailer_master_active/')">
             <ion-icon :icon="ellipse" />
             <ion-label>Active</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="Retailer_master_inactive" @click="() => router.push('/Retailer_master/Retailer_master_inactive/')"  >
              <ion-icon :icon="ellipse" />
              <ion-label>In-Active</ion-label>
          </ion-tab-button>

          <!-- <ion-tab-button tab="Doctor_master_approve" @click="() => router.push('/Doctor_master/Doctor_master_approve/')"  >
            <ion-icon :icon="ellipse" />
            <ion-label>Approval Section</ion-label>
          </ion-tab-button> -->

        </ion-tab-bar>
      </ion-tabs>
    </ion-content>
  </ion-page>


</template>

<script >
  import {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonContent,
    IonLabel,
    IonIcon,
    IonPage,
    IonRouterOutlet,

    //loadingController, toastController,
  } from '@ionic/vue';
  import { ellipse, square, triangle,add  } from 'ionicons/icons';
  import {useRouter} from 'vue-router';
  //import { useStore  } from 'vuex';
  import {reactive} from 'vue';
  import config from '@/config.js'

  export default {
    name: 'Doctor_master',
    components: {
      IonContent,
      IonLabel,
      IonTabs,
      IonTabBar,
      IonTabButton,
      IonIcon,
      IonPage,
      IonRouterOutlet,

    },
    setup() {
      const router=useRouter();
      //const store = useStore();

      const param=reactive({
          tbl_hierarchy_org_id:'',
          data_type:'',
          id:'',
          hostname:config.hostname,
          hostconfig: config.config
       });



      return {router,ellipse,square,triangle,add,param,};

    },
  };
</script>