<template>
  <ion-page>

   <ion-header>
      <ion-toolbar>
        <ion-title></ion-title>
        <!-- <img  slot="end" @click="LoadList" src="assets/imgs/reload_small.png"  /> -->
         <ion-button @click="closemodal" slot="end">X</ion-button>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
        <!-- <ion-item> Area Index:  {{$store.state.general_purpose_indx}} -- Doc Index  {{$store.state.general_purpose_indx2}}</ion-item> -->
        <!-- {{$store.state.DoctorList.DOCTORS.ACTIVE_DOCTORS[$store.state.general_purpose_indx]['doctors'][$store.state.general_purpose_indx2]}} -->
        <!-- {{$store.state.SaveServerResponse['LOCATIONS']}} -->

                <ion-item>
                    <ion-label position="floating">Location Name</ion-label>
                    <ion-input  type="text" v-model="param.name" ></ion-input>
                </ion-item>

                <ion-button expand="block" @click="SaveLocation">Save Location</ion-button>


    </ion-content>

  </ion-page>
</template>

<script>



//IonButton,
import {
 IonContent,IonHeader,IonPage,IonTitle,IonToolbar,IonItem,
 IonButton,IonLabel,
  IonInput,
 //IonMenuButton, IonItemGroup,IonLabel,IonItemDivider,IonSelect,IonSelectOption,IonTextarea,
 //IonButton
 modalController,toastController,loadingController,
} from '@ionic/vue';

import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex';
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue';
// import DCRdetailcomponent from '@/components/dcr_section/DCRdetail.vue';
// import datepickermodal from '@/components/datepickermodal.vue';
// import { Geolocation } from '@capacitor/geolocation';

export default defineComponent({
  components: { IonContent,IonHeader,IonPage,IonTitle,IonToolbar,IonItem,
 IonButton,IonLabel,
  IonInput,
  //IonSelect,IonSelectOption,IonTextarea,
  //IonMenuButton,IonItemGroup,IonLabel,IonItemDivider
  //IonButton
  },

  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

     const param=reactive({

          ENTRY_TYPE:'',
          tbl_hierarchy_org_id:'',
          id:'',
          name:'',
          hq_id:0,
          field_index:0,
          hostname:config.hostname,
          hostconfig: config.config
    });

       param.field_index= store.state.general_purpose_indx;
       param.ENTRY_TYPE= store.state.REPORT_TYPE;
       param.hq_id=store.state.hq_id;
       param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;


      if(param.ENTRY_TYPE=='ADD')
      {
          param.id=0;
          param.name='';

      }
      else
      {
          param.id=store.state.SaveServerResponse['LOCATIONS'][param.field_index].area_id;
          param.name=store.state.SaveServerResponse['LOCATIONS'][param.field_index].area_name;
      }


    const SaveLocation= async ()=>{

      //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Report Saving. Please wait...',

        });
        await loading.present();

      // PAYLOAD END
        try{
                //SAVING SECTION
                await store.dispatch('SaveLocation',param);
                loading.dismiss();

                //Toast START
                const toast = await toastController
                  .create({
                    message: store.state.SaveServerResponse2.message,
                    duration: 3000
                  })
                toast.present();
                //Toast END

                closemodal();
        }
        catch (e)
        {
            //param.gps_error = e;
               loading.dismiss()
             //Toast START
              const toast = await toastController
                .create({
                  message: e,
                  duration: 3000
                })
              toast.present();

        }

    }

    const closemodal= async ()=>
    { await modalController.dismiss();}

    return{router,store,param,closemodal,SaveLocation};
  }

});
</script>