<template>
  <ion-page>

   <ion-header>
      <ion-toolbar>
        <ion-title>Detail Report</ion-title>
        <ion-buttons slot="start">
        </ion-buttons>
         <ion-button @click="closemodal" slot="end">X</ion-button>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

     <!-- {{$store.state.user['PRODUCT_GROUP']}} -->
      <!-- {{$store.state.populate_data}} -->
      <!-- {{$store.state.populate_data}} -->


         <ion-item-group v-for="(data_list,data_index) in $store.state.populate_data" :key="data_index">

              <div v-if="$store.state.REPORT_TYPE==='MONTHLY_DOC_VISIT'">
                <ion-item   class="ion-divider-main"  v-if="data_list.color_code==='OK'">
                  <ion-label>{{data_index+1}}. {{data_list.name}}</ion-label>
                </ion-item>
                <ion-item   class="ion-divider-main lessvisit" v-if="data_list.color_code==='NOT_OK'" >
                  <ion-label>{{data_index+1}}. {{data_list.name}}</ion-label>
                </ion-item>
                <ion-item lines="none">
                  <ion-label>Location     :  {{data_list.Location}}</ion-label>
                </ion-item>
                  <ion-item lines="none">
                  <ion-label>Date of Visit: {{data_list.visit_days}}</ion-label>
                </ion-item>
                  <ion-item lines="none">
                  <ion-label>Total Visit  :  {{data_list.no_of_visit}}</ion-label>
                </ion-item>
              </div>


              <div v-if="$store.state.REPORT_TYPE==='MONTHLY_MISSED_CALL_REPORT'">

                  <ion-item   class="ion-divider-main" v-if="data_list.color_code==='OK'" >
                    <ion-label>{{data_index+1}}. {{data_list.name}}</ion-label>
                  </ion-item>
                  <ion-item   class="ion-divider-main lessvisit" v-if="data_list.color_code==='NOT_OK'" >
                    <ion-label>{{data_index+1}}. {{data_list.name}} (Less Visit)</ion-label>
                  </ion-item>

                  <ion-item lines="none">
                    <ion-label>Location     :  {{data_list.Location}}</ion-label>
                  </ion-item>
                    <ion-item lines="none">
                    <ion-label>Last Date of Visit: {{data_list.last_visit_date}}</ion-label>
                  </ion-item>
              </div>


            <div v-if="$store.state.REPORT_TYPE==='YEARLY_DOC_VISIT'">

                    <ion-grid  class="grid_header">
                          <ion-row>
                            <ion-col size="6" size-sm="2" style="background:#eb445a">DocTor Name</ion-col>
                            <ion-col size="6" size-sm="2" style="background:#eb445a">Location</ion-col>
                          </ion-row>

                          <ion-row>
                            <ion-col size="4" size-sm="3" style="background:#eb445a">{{$store.state.populate_data.month1}}</ion-col>
                            <ion-col size="4" size-sm="3" style="background:#eb445a">{{$store.state.populate_data.month2}}</ion-col>
                            <ion-col size="4" size-sm="3" style="background:#eb445a">{{$store.state.populate_data.month3}}</ion-col>
                          </ion-row>
                      </ion-grid>


                    <ion-grid class="grid_body"  v-for="(data_list,data_index) in $store.state.populate_data.doctors" :key="data_index">
                        <ion-row>
                          <ion-col size="6" size-sm="2" >{{data_list.name}}</ion-col>
                           <ion-col size="6" size-sm="2" >{{data_list.hierarchy_name}}</ion-col>
                        </ion-row>

                        <ion-row>
                          <ion-col size="4" size-sm="3" >{{data_list.month1}}</ion-col>
                           <ion-col size="4" size-sm="3" >{{data_list.month2}}</ion-col>
                           <ion-col size="4" size-sm="3" >{{data_list.month3}}</ion-col>
                        </ion-row>
                    </ion-grid>

                    <ion-grid >
                        <ion-row>
                          <ion-col size="4" size-sm="3" >Month-1 Total</ion-col>
                           <ion-col size="4" size-sm="3" >Month-2 Total</ion-col>
                           <ion-col size="4" size-sm="3" >Month-3 Total</ion-col>
                        </ion-row>
                    </ion-grid>

                 </div>



        </ion-item-group>





    </ion-content>

  </ion-page>
</template>

<script>


// IonAccordion,IonAccordionGroup,
import {
  IonContent,IonHeader,IonPage,IonTitle,IonToolbar,
  IonItemGroup,IonItem,IonLabel,
  IonGrid,IonRow,IonCol,
  IonButton,modalController
} from '@ionic/vue';

import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex'
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue'



//IonAccordion,IonAccordionGroup,
export default defineComponent({
  components: { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonButton,
  IonItemGroup,IonItem,IonLabel,
  IonGrid,IonRow,IonCol,
  //IonListHeader, IonCheckbox,
  },
  props: {
    title: { type: String, default: 'Super Modal' },
  },
  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

    const param=reactive({

          trandate:'',
          hq_index:0,
          field_index:0,
          doctor_retailer_stockist_index:0,
          tbl_hierarchy_org_id:'',
          tbl_designation_id:'',
          hq_id:'',
          field_id:'',
          id:'',
          Doctor_Retailer_stockist_name:'',
          hostname:config.hostname,
          hostconfig: config.config
    });

    // param.doctor_retailer_stockist_index= store.state.DCRdetail.doctor_retailer_stockist_index;
    // console.log('store.state.DCRdetail.doctor_retailer_stockist_index: '+ store.state.DCRdetail.doctor_retailer_stockist_index);
    //  console.log('store.state.DCRdetail.data_type '+ store.state.DCRdetail.data_type);


     const closemodal= async ()=>
    { await modalController.dismiss();}

    return{router,store,param,closemodal};
  }

});
</script>