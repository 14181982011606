<template>

  <ion-page>

      <ion-header colour="primary">
        <ion-toolbar>
          <ion-buttons slot="start" >
            <ion-menu-button menu="custom"></ion-menu-button>
            <ion-back-button ></ion-back-button>
          </ion-buttons>
          <ion-title>DCR Report</ion-title>
        </ion-toolbar>
      </ion-header>

     <ion-content>
              <!-- {{$store.state.user.hqlist.hq_id}} -->
              <ion-item>
                <ion-label fixed>Select HQ</ion-label>
                  <ion-select v-model="param.hq_id">
                      <ion-select-option  v-for="hqs in $store.state.user.hqlist.hq_list" :key="hqs.hq_id" :value="hqs.hq_id">
                      {{hqs.hq_name}}
                      </ion-select-option>
                  </ion-select>
              </ion-item>

              <ion-item>
                 <ion-label fixed>From Date </ion-label>
                 <ion-label fixed>:{{param.fromdate}}</ion-label>
                 <ion-button fill="clear"  @click="formating_date('fromdate')">Select Date </ion-button>
              </ion-item>

              <ion-item>
                <ion-label fixed>To Date </ion-label>
                <ion-label fixed>:{{param.todate}}</ion-label>
                <ion-button fill="clear"  @click="formating_date('todate')">Select Date </ion-button>
              </ion-item>

              <ion-button expand="block" @click="LoadDCR">Load DCR</ion-button>

                <!-- {{$store.state.Report_DCR.DCR_REPORT}} -->

                <div v-for="(expense_list,field_index) in $store.state.Report_DCR.DCR_REPORT" :key="field_index">

                    <ion-card>
                        <ion-card-header   class="ion-card-manager" >
                          <!-- <img  src="assets/imgs/manager.png" style="float: right; " /> -->
                          <ion-card-title  >Date : {{expense_list.current_date}} </ion-card-title>
                          <ion-card-subtitle color="ion-color-tertiary-shade"><strong> DOCTOR VISIT </strong></ion-card-subtitle>
                        </ion-card-header>

                        <div v-for="(detail,field_index1) in expense_list.DOCTOR_DCR_LIST" :key="field_index1" >
                            <ion-item><ion-label >
                                Doctor Name: {{detail.Name}} <br>
                                Location : {{detail.Location}} at {{detail.report_date_time}}
                            </ion-label></ion-item>
                            <!-- <ion-item><ion-label >Location : {{detail.Location}} at {{detail.report_date_time}}</ion-label> </ion-item> -->
                        </div>

                        <ion-card-header  color="ion-color-tertiary-shade"><ion-card-title>RETAILER VISIT</ion-card-title></ion-card-header>
                        <div v-for="(detail,field_index1) in expense_list.RETAILER_DCR_LIST" :key="field_index1" >
                              <ion-item><ion-label >
                                Retailer Name: {{detail.Name}} <br>
                                Location : {{detail.Location}} at {{detail.report_date_time}}
                              </ion-label></ion-item>
                            <!-- <ion-item><ion-label >Location : {{detail.Location}} at {{detail.report_date_time}}</ion-label> </ion-item> -->
                        </div>

                        <ion-card-header  color="ion-color-tertiary-shade"><ion-card-title>STOCKIST VISIT</ion-card-title></ion-card-header>
                        <div v-for="(detail,field_index1) in expense_list.STOCKIST_DCR_LIST" :key="field_index1" >
                            <ion-item><ion-label >
                              Stockist Name: {{detail.Name}} <br>
                              Location : {{detail.Location}} at {{detail.report_date_time}}
                            </ion-label></ion-item>
                            <!-- <ion-item><ion-label >Location : {{detail.Location}} at {{detail.report_date_time}}</ion-label> </ion-item> -->
                        </div>

                         <ion-card-header  color="ion-color-tertiary-shade"><ion-card-title>OTHER</ion-card-title></ion-card-header>
                        <div v-for="(detail,field_index1) in expense_list.OTHER_REPORT" :key="field_index1" >
                            <ion-item><ion-label >Type: {{detail.tran_type}} </ion-label></ion-item>
                            <ion-item><ion-label >Details : {{detail.tran_desc}} </ion-label> </ion-item>
                        </div>


                    </ion-card>

              </div>




    </ion-content>

  </ion-page>
</template>

<script>

import {
  IonPage,IonHeader,IonContent,IonToolbar,IonTitle,
  IonItem,IonSelect,IonSelectOption,
  IonCard, IonCardHeader,IonCardSubtitle,
  IonButton,
  toastController,
  modalController }
  from "@ionic/vue";


import config from '../../config.js'
import {defineComponent } from "@vue/runtime-core";

import {useRouter} from 'vue-router';
import { useStore  } from 'vuex'
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue'
import TourPlanDetail from '@/components/tour_section/TourPlanDetail_v1.vue';
import datepickermodal from '@/components/others/datepickermodal.vue';

//9433449500
export default defineComponent({

  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonItem,
    IonSelect,IonSelectOption,IonButton,
    IonCard, IonCardHeader,IonCardSubtitle,
  },


  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

    const param=reactive({
      fromdate:'',
      todate:'',
      tbl_hierarchy_org_id:'',
      hq_id:'',
      tour_plan_data:'',
      hostname:config.hostname,
      hostconfig: config.config
    });

      //INITIAL VALUE SET
      var initial_date=  new Date().toLocaleString();  //06/04/2022
      initial_date=initial_date.slice(6, 10)+'-'+initial_date.slice(3, 5)+'-'+initial_date.slice(0, 2);
      param.fromdate=param.todate=initial_date;
      param.hq_id=store.state.user.tbl_hierarchy_org_id;


      //INITIAL VALUE SET END

       //formating date function
      const formating_date= async (date_picker_name)=>
      {

           const modal = await modalController
            .create({
              component: datepickermodal,
              cssClass: 'my-custom-class',
              componentProps: {
                title: 'Date Pick',
                date_picker_name: date_picker_name
              },
            });

            modal.onDidDismiss().then((data) => {
                getdate(data,date_picker_name);
            });

          return modal.present();
      }

      const getdate= async (dates,date_picker_name)=>
      {
          console.log('received date:'+dates);
          if(date_picker_name=='fromdate')
          {param.fromdate=dates.data;}

          if(date_picker_name=='todate')
          {param.todate=dates.data;}

      }

    //formating date function END



    console.log('param.fromdate '+param.fromdate);



    //LOAD TOUR PLAN

     const LoadDCR= async ()=>{

      //LOADING SECTION
      //  const loading = await loadingController
      //   .create({
      //     cssClass: 'my-custom-class',
      //     message: 'Loading ..... Please wait...',

      //   });
      //   await loading.present();


        //CREATE PAYLOAD
         param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;

         // PAYLOAD END

        console.log(param);

      //LOADING FUNCTION
      await store.dispatch('LoadDCR',param);
     // loading.dismiss();

      //  //Toast START
      const toast = await toastController
        .create({
          message: 'DCR Loaded..',
          duration: 1000
        })
      toast.present();
      // //Toast END

    }
    //LOAD TOUR PLAN END

    //OPEN MODAL POPUP FOR DETAIL TOUR PLAN
    const openModal= async (mst_office_calender_id,tourplan_index)=>
    {
        const modal = await modalController
          .create({
            component: TourPlanDetail,
            cssClass: 'my-custom-class',
            componentProps: {
              title: mst_office_calender_id,
              tourplan_index: tourplan_index,
            },
          })
        return modal.present();
    }


    return{router,store,param,formating_date,LoadDCR,openModal,getdate};

  }
});
</script>