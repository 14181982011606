<template>

  <ion-page>

      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start" >
            <ion-menu-button menu="custom"></ion-menu-button>
            <ion-back-button ></ion-back-button>
          </ion-buttons>
          <ion-title>Monthly Tour Plan</ion-title>
        </ion-toolbar>
      </ion-header>

     <ion-content>
              <!-- {{$store.state.user.hqlist.hq_id}} -->
              <ion-item>
                <ion-label fixed>Select HQ</ion-label>
                  <ion-select v-model="param.hq_id">
                      <ion-select-option  v-for="hqs in $store.state.user.hqlist.hq_list" :key="hqs.hq_id" :value="hqs.hq_id">
                      {{hqs.hq_name}}
                      </ion-select-option>
                  </ion-select>

              </ion-item>

              <ion-item>
                <!-- {{$store.state.user.month_year}} -->
                <ion-label fixed>Month Year</ion-label>
                  <ion-select v-model="param.monthyear">
                      <ion-select-option  v-for="monthyr in $store.state.user.month_year_tour_plan"
                      :key="monthyr.month_year_numeric" :value="monthyr.month_year_numeric">
                      {{monthyr.monthyear}}
                      </ion-select-option>
                  </ion-select>
              </ion-item>

               <!-- <ion-item>
                 <ion-label fixed>From Date </ion-label>
                 <ion-label fixed>:{{param.fromdate}}</ion-label>
                 <ion-button fill="clear"  @click="formating_date('fromdate')">Select Date </ion-button>
              </ion-item>

              <ion-item>
                <ion-label fixed>To Date </ion-label>
                <ion-label fixed>:{{param.todate}}</ion-label>
                <ion-button fill="clear"  @click="formating_date('todate')">Select Date </ion-button>
              </ion-item> -->

              <ion-button expand="block" @click="tour_plan_summary">Load Tour Plan</ion-button>




              <div v-for="tourplan_list in $store.state.SaveServerResponse" :key="tourplan_list.tran_date"
              @click="openModal(tourplan_list.tran_date,tourplan_list.tbl_hierarchy_org_id,tourplan_list.tbl_designation_id)">

               <ion-item class="ionheader">
                <ion-label >{{tourplan_list.tran_date_complete}} </ion-label>
                <ion-label v-if="tourplan_list.approve_by_manager_status === 'APPROVED'" slot="end"><img src="assets/imgs/correct.png"  /></ion-label>
                <ion-label v-if="tourplan_list.approve_by_manager_status === 'NOT_APPROVED'" slot="end"><img src="assets/imgs/correct.png"  /></ion-label>

               </ion-item>

                <ion-item text-wrap class="grid_body">{{tourplan_list.plandtl}}
                  <!-- <ion-label >{{tourplan_list.plandtl}} </ion-label> -->
                </ion-item>

              </div>




    </ion-content>

  </ion-page>
</template>

<script>

import {
  IonPage,IonHeader,IonContent,IonToolbar,IonTitle,
  IonItem, IonSelect,IonSelectOption,
  IonButton,
  loadingController,
  toastController,modalController }
  from "@ionic/vue";


import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex'
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue'
import TourPlanDetail from '@/components/tour_section/TourPlanDetail.vue';
import datepickermodal from '@/components/others/datepickermodal.vue';

export default defineComponent({

  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonItem,IonSelect,IonSelectOption,IonButton
  },


  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

    const param=reactive({
      currentdate:'',
      fromdate:'',
      todate:'',
      monthyear:'',
      tbl_hierarchy_org_id:'',
      hq_id:'',
      tour_plan_data:'',
      hostname:config.hostname,
      hostconfig: config.config
    });


      var initial_date=  new Date().toLocaleString();  //06/04/2022
      initial_date=initial_date.slice(6, 10)+'-'+initial_date.slice(3, 5)+'-'+initial_date.slice(0, 2);
      param.fromdate=param.todate=initial_date;
      param.hq_id=store.state.user.tbl_hierarchy_org_id;

      //formating date function
      const formating_date= async (date_picker_name)=>
      {

           const modal = await modalController
            .create({
              component: datepickermodal,
              cssClass: 'my-custom-class',
              componentProps: {
                title: 'Date Pick',
                date_picker_name: date_picker_name
              },
            });

            modal.onDidDismiss().then((data) => {
                getdate(data,date_picker_name);
            });

          return modal.present();
      }

      const getdate= async (dates,date_picker_name)=>
      {
          console.log('received date:'+dates);
          if(date_picker_name=='fromdate')
          {param.fromdate=dates.data;}

          if(date_picker_name=='todate')
          {param.todate=dates.data;}

      }

    //formating date function END


    //LOAD TOUR PLAN

     const tour_plan_summary= async ()=>{

      //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Loading ..... Please wait...',

        });
        await loading.present();


        //CREATE PAYLOAD
        param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;

        // PAYLOAD END
        //console.log(param);

      //LOADING FUNCTION
      await store.dispatch('tour_plan_summary',param);
      loading.dismiss();

      //  //Toast START
      const toast = await toastController
        .create({
          message: 'Tour Plan Loaded..',
          duration: 1000
        })
      toast.present();
      // //Toast END

    }
    //LOAD TOUR PLAN END


    //OPEN MODAL POPUP FOR DETAIL TOUR PLAN
    const openModal= async (tran_date,hq_id,currenthq_designation_id)=>
    {
            console.log(tran_date + hq_id + 'tbl_designation_id'+currenthq_designation_id);
            param.currentdate=tran_date;
            store.state.hq_desig_id_from_list=currenthq_designation_id;
            store.state.tour_plan_save={};

           const loading = await loadingController
            .create({
              cssClass: 'my-custom-class',
              message: 'Loading ..... Please wait...',

            });
            await loading.present();

            try{

                    await store.dispatch('display_tour_plan_updated',param);
                    loading.dismiss();

                    const modal = await modalController
                    .create({
                      component: TourPlanDetail,
                      cssClass: 'my-custom-class',
                      componentProps: {
                        currentdate: param.currentdate,
                        hq_id: hq_id,
                        currenthq_designation_id:currenthq_designation_id
                      },
                    });

                    modal.onDidDismiss().then((data) => {
                      console.log(data);
                      tour_plan_summary();
                        //getdate(data,date_picker_name);
                    });

                  return modal.present();

            }
            catch(e)
            {

                  loading.dismiss()
                  //Toast START
                    const toast = await toastController
                      .create({
                        message: e,
                        duration: 3000
                      })
                    toast.present();
            }

    }





    //SAVE TOUR PLAN
    const SaveTourPlan= async ()=>{

      //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Tour Plan Saving...',
        });
        await loading.present();


       //CREATE PAYLOAD
          param.tour_plan_data=store.state.tour_plan;
      // PAYLOAD END

      //SAVING SECTION
      await store.dispatch('SaveTourPlan',param);
      loading.dismiss();

       //Toast START
      const toast = await toastController
        .create({
          message: store.state.SaveServerResponse.message,
          duration: 3000
        })
      toast.present();
      //Toast END


    }



     //SAVE TOUR PLAN END


    return{router,store,param,formating_date,tour_plan_summary,openModal,SaveTourPlan,getdate};
  }
});
</script>