<template>

<ion-page>

     <ion-header>
        <ion-toolbar>
            <ion-title>Test Auto Complete</ion-title>
            <ion-buttons slot="start">
            <ion-menu-button menu="custom"></ion-menu-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>

        <ion-content class="ion-padding">

             <ion-item>
                <ion-label fixed>Select HQ</ion-label>
                  <ion-select v-model="param.hq_id">
                      <ion-select-option  v-for="hqs in $store.state.user.hqlist.hq_list" :key="hqs.hq_id" :value="hqs.hq_id">
                      {{hqs.hq_name}}
                      </ion-select-option>
                  </ion-select>
              </ion-item>
              <ion-button expand="block" @click="LoadDoctor">Load Doctor</ion-button>

               <ion-input type="text"
                ref="testinput"
                v-model="param.area_name"
                @change="changeval"
                @keydown.up="up"
                @keydown.down="down"
                @keydown.enter="selectItem"
                placeholder="Start Typing...">
                </ion-input>

                <div class="options" ref="optionsList">
                  <ul>
                    <li v-for="(area_list,index) in $store.state.SaveServerResponse"   :key="index"
                      :class="{ 'selected': (selected == index)}"
                      @click="itemClicked(index)"
                     >{{area_list.area_name}}</li>
                  </ul>
                </div>
        </ion-content>

</ion-page>

</template>

<script>

// TURIAL :
// YOUTUBE : https://www.youtube.com/watch?v=mdl3tLZ7sdA&list=PLAcPUNGCR-6BnzUlEMyjhQePM3DC4Tmvr&index=6
// GITHUB : https://github.com/AfikDeri/VueJS-Autocomplete


import {IonPage, IonContent, IonHeader, IonTitle, IonToolbar ,
IonButtons,
IonButton,IonLabel,IonItem,IonInput,
loadingController,toastController
} from '@ionic/vue';

import config from '@/config.js'
import { defineComponent } from 'vue';
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex'
//import {ref} from 'vue'
import {reactive,ref} from 'vue'


export default defineComponent({
  name: 'Modal',
  components: {IonPage, IonContent, IonHeader, IonTitle, IonToolbar,
  IonButtons,
  IonButton,IonLabel,IonInput,
  IonItem,},

   props: {
    doctor_index: { type: String, default: 'Super Modal' },
    area_index:  { type: String, default: 'Super Modal' },
    monthyear:  { type: String, default: 'Super Modal' },
    hq_id:  { type: Number, default: 0 },


  },

  setup(props,context)
  {
        const router=useRouter();
        const store = useStore();

      const param=reactive({
          itemHeight:10,
          visible: false,
          selected:0,
          selectedItem:{},
          matches:{},


          area_name:'',
          areaid:'',
          tbl_hierarchy_org_id:'',
          subtype:'DOC_LIST',
          hq_id:'',
          tour_plan_data:'',
          hostname:config.hostname,
          hostconfig: config.config
    });

    const testinput = ref(null);
    const optionsList = ref(null);


    param.hq_id=store.state.user.tbl_hierarchy_org_id;


     const changeval = (event) =>
      {
            context.emit("changeval", event.target.value);

            console.log(param.area_name);


      }

    const itemClicked=(index)=>
    {
      param.selected = index;
      console.log('param.selected:'+param.selected);
      //selectItem();
    }





    const LoadDoctor= async ()=>{

      //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Loading ..... Please wait...',

        });
        await loading.present();


        //CREATE PAYLOAD
         param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
         // PAYLOAD END

        console.log(param);

      //LOADING FUNCTION
      await store.dispatch('LoadDoctor',param);
      loading.dismiss();

      //  //Toast START
      const toast = await toastController
        .create({
          message: 'Report Loaded..',
          duration: 1000
        })
      toast.present();
      // //Toast END

    }

    //AUTOCOMPLETE RELATED FUNCTIONS
  /*
   const toggleVisible=()=>
    {
        param.visible = !param.visible;
        // setTimeout(() => {
        //   $refs.input.focus();
        // }, 50);
    }



  const  selectItem=()=>
    {
      if (!param.matches.length) { return;}

        param.selectedItem = param.matches[param.selected];
        console.log('test:'+param.selectedItem);

        param.visible = false;
        if (param.shouldReset) {
          param.query = '';
          param.selected = 0;
        }
        //this.$emit('selected', JSON.parse(JSON.stringify(this.selectedItem)));
    }

  const  up=()=>
    {
      if (param.selected == 0) {return;}
      param.selected -= 1;
      scrollToItem();
    }

  const  down=()=>
    {
        if (param.selected >= matches.length - 1) {return;}
        param.selected += 1;
        scrollToItem();
    }



*/
  const  scrollToItem =()=> { optionsList.value.scrollTop = param.selected * param.itemHeight;}

  return { content: 'Content123654',router,param,LoadDoctor,scrollToItem,changeval,testinput,optionsList,itemClicked}

  //return { content: 'Content123654',router,param,toggleVisible,itemClicked,selectItem,up,down,scrollToItem}

  }

});
</script>




<style scoped>
.autocomplete {
    width: 100%;
    position: relative;
}
.input {
    height: 40px;
    border-radius: 3px;
    border: 2px solid lightgray;
    box-shadow: 0 0 10px #eceaea;
    font-size: 25px;
    padding-left: 10px;
    padding-top: 10px;
    cursor: text;
}
.close {
    position: absolute;
    right: 2px;
    top: 4px;
    background: none;
    border: none;
    font-size: 30px;
    color: lightgrey;
    cursor: pointer;
}
.placeholder {
    position: absolute;
    top: 11px;
    left: 11px;
    font-size: 25px;
    color: #d0d0d0;
    pointer-events: none;
}
.popover {
    min-height: 50px;
    border: 2px solid lightgray;
    position: absolute;
    top: 46px;
    left: 0;
    right: 0;
    background: #fff;
    border-radius: 3px;
    text-align: center;
}
.popover input {
    width: 95%;
    margin-top: 5px;
    height: 40px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid lightgray;
    padding-left: 8px;
}
.options {
    max-height: 150px;
    overflow-y: scroll;
    margin-top: 5px;
}
.options ul {
    list-style-type: none;
    text-align: left;
    padding-left: 0;
}
.options ul li {
    border-bottom: 1px solid lightgray;
    padding: 10px;
    cursor: pointer;
    background: #f1f1f1;
}
.options ul li:first-child {
    border-top: 2px solid #d6d6d6;
}
.options ul li:not(.selected):hover {
    background: #8c8c8c;
    color: #fff;
}
.options ul li.selected {
    background: #58bd4c;
    color: #fff;
    font-weight: 600;
}
</style>