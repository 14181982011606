<template>
  <ion-page>

   <ion-header>
      <ion-toolbar>
        <ion-title>InActive Doctor List</ion-title>
        <ion-buttons slot="start">
          <ion-menu-button menu="custom"></ion-menu-button>
        </ion-buttons>
        <!-- <img  slot="end" @click="LoadList" src="assets/imgs/reload_small.png"  /> -->



      </ion-toolbar>
    </ion-header>

     <!-- <ion-fab slot="fixed" vertical="top" horizontal="end">
            <ion-fab-button  @click="()=>LoadDoctorForAddEdit(0,0,'ADD_DOCTOR')">
              <ion-icon :icon="add"></ion-icon>
            </ion-fab-button>
        </ion-fab> -->

     <!-- {{$store.state.user.hqlist.hq_id}} -->



    <ion-content >

             <ion-item>
                <ion-label fixed>Select HQ</ion-label>
                  <ion-select v-model="param.hq_id">
                      <ion-select-option  v-for="hqs in $store.state.user.hqlist.hq_list" :key="hqs.hq_id" :value="hqs.hq_id">
                      {{hqs.hq_name}}
                      </ion-select-option>
                  </ion-select>

              </ion-item>
              <ion-button expand="block" @click="LoadDoctorList">Load Doctor</ion-button>

              <ion-item-group v-for="(area_list,area_index) in $store.state.DoctorList.DOCTORS.INACTIVE_DOCTORS" :key="area_list.area_id">

                      <ion-item-divider class="ion-divider-main lessvisit">
                        <ion-label>{{area_index+1}}. Area/Location : {{area_list.area_name}} </ion-label>
                      </ion-item-divider>

                        <ion-item-group  v-for="(doctor_list,doctor_index) in area_list.doctors" :key="doctor_list.id" >
                              <div @click="()=>LoadDoctorForAddEdit(area_index,doctor_index,'EDIT_DOCTOR')">
                                <ion-item class="ion-divider-main">
                                  <ion-label>{{doctor_index+1}}. {{doctor_list.name}} ({{doctor_list.doc_type}})</ion-label>
                                </ion-item>
                                 <ion-item lines="none">
                                  <ion-label>Qualification: </ion-label>
                                  <ion-label >{{doctor_list.qualification}} </ion-label>
                                </ion-item>
                                 <ion-item lines="none">
                                  <ion-label>Contact No : </ion-label>
                                  <ion-label>{{doctor_list.contactno}} </ion-label>
                                </ion-item>
                                <ion-item lines="none">
                                  <ion-label>Address: </ion-label>
                                  <ion-label>{{doctor_list.chamber1}} </ion-label>
                                </ion-item>
                              </div>

                        </ion-item-group>
                  </ion-item-group>


    </ion-content>

  </ion-page>
</template>

<script>



//IonButton,
import {
 IonContent,IonHeader,IonPage,IonTitle,IonToolbar,
 IonButtons,IonMenuButton,IonLabel,
  IonItemGroup,IonItemDivider,IonItem,
//IonCol, IonGrid,  IonRow,
 IonButton,
 IonSelect,IonSelectOption,
loadingController, toastController,modalController,
// IonFab, IonFabButton,
} from '@ionic/vue';
import { add  } from 'ionicons/icons';
import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex';
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue';
import Doctor_master_add_edit from '@/components/master/doctor/Doctor_master_add_edit.vue';
// import { Geolocation } from '@capacitor/geolocation';

export default defineComponent({
  components: { IonContent,IonHeader,IonPage,IonTitle,IonToolbar,
  IonButtons,IonMenuButton,IonLabel, IonItemGroup,IonItemDivider,IonItem,
  IonSelect,IonSelectOption,IonButton,
  // IonFab, IonFabButton,
  //IonCol, IonGrid,  IonRow,
  //
  },

  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

     const param=reactive({
          hq_id:'',
          tbl_hierarchy_org_id:'',
          subtype:'DOC_LIST',
          id:'',
          hostname:config.hostname,
          hostconfig: config.config
    });



     const LoadDoctorList= async ()=>{

      //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Loading ..... Please wait...',

        });
        await loading.present();

        //CREATE PAYLOAD

         store.state.hq_id=param.hq_id;
         param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
         console.log(param);

      //LOADING FUNCTION
      await store.dispatch('LoadDoctorList',param);
      loading.dismiss();

      //  //Toast START
      const toast = await toastController
        .create({
          message: 'Report Loaded..',
          duration: 1000
        })
      toast.present();
      // //Toast END

    }


   const LoadDoctorForAddEdit= async (area_index,doctor_indx,ENTRY_TYPE)=>{

       //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',

        });
        await loading.present();

        store.state.general_purpose_indx=area_index;
        store.state.general_purpose_indx2=doctor_indx;
        store.state.REPORT_TYPE=ENTRY_TYPE;
        store.state.REDIRECT_FROM_PAGE='INACTIVE';


        try{

         // await store.dispatch('DCRdetail',param);
          loading.dismiss();

          //LOAD DCR DETAIL MODAL POPUP
          var title="Doctor";
          const modal = await modalController
          .create({
            component: Doctor_master_add_edit,
            cssClass: 'my-custom-class',
            componentProps: {
              title: title,
            },
          });

          modal.onDidDismiss().then((data) => {
               console.log(data);
              // getdate(data,date_picker_name);
          });

          return modal.present();

        }
        catch(e)
        {

              loading.dismiss()
              //Toast START
                const toast = await toastController
                  .create({
                    message: e,
                    duration: 3000
                  })
                toast.present();
        }

    }


    return{router,store,param,add,LoadDoctorList,LoadDoctorForAddEdit};

  }

});
</script>