<template>

  <ion-page>
       <!-- {{$store.state.user.hqlist.hq_id}} -->
      <ion-header colour="primary">
        <ion-toolbar>
          <ion-buttons slot="start" >
            <ion-menu-button menu="custom"></ion-menu-button>
            <ion-back-button ></ion-back-button>
          </ion-buttons>
          <ion-title>Home </ion-title>
        </ion-toolbar>
      </ion-header>


     <ion-content>


                 <ion-grid >

                          <ion-row>

                                  <ion-col size="6" class="transparent-bg">
                                      <ion-card @click="dailyPlan">
                                        <!-- class="ion-card-manager" -->
                                          <ion-card-header   class="ion-card-manager" >
                                              <img  src="assets/imgs/manager.png" style="float: right;  " />
                                              <ion-card-title  >Daily Plan</ion-card-title>
                                              <ion-card-subtitle color="ion-color-tertiary-shade">Your Daily Plan Approved</ion-card-subtitle>
                                          </ion-card-header>
                                      </ion-card>
                                  </ion-col>

                                  <ion-col size="6" class="transparent-bg ">
                                        <ion-card>
                                        <!-- class="ion-card-manager" -->
                                          <ion-card-header   class="ion-card-manager" >
                                              <img  src="assets/imgs/manager.png" style="float: right;  " />
                                              <ion-card-title  >Start Work</ion-card-title>
                                              <ion-card-subtitle color="ion-color-tertiary-shade">Working Progress</ion-card-subtitle>
                                          </ion-card-header>
                                      </ion-card>
                                  </ion-col>

                          </ion-row>

                           <ion-row>
                                  <ion-col size="6" class="transparent-bg ">
                                      <ion-card @click="dcr_entry_section">
                                        <ion-card-header   class="ion-card-manager" >
                                              <img  src="assets/imgs/manager.png" style="float: right;  " />
                                              <ion-card-title  >DCR Entry</ion-card-title>
                                              <ion-card-subtitle color="ion-color-tertiary-shade">Enter your DCR</ion-card-subtitle>
                                          </ion-card-header>
                                      </ion-card>
                                  </ion-col>

                                   <ion-col size="6" class="transparent-bg ">
                                      <ion-card @click="dcr_entry_section">
                                        <ion-card-header   class="ion-card-manager" >
                                              <img  src="assets/imgs/manager.png" style="float: right;  " />
                                              <ion-card-title  >Pending Approvals</ion-card-title>
                                              <ion-card-subtitle color="ion-color-tertiary-shade">Your Pending Approvals</ion-card-subtitle>
                                          </ion-card-header>
                                      </ion-card>
                                  </ion-col>

                        </ion-row>


                 </ion-grid>


                                <!-- <ion-card>
                                  <ion-card-header   class="ion-card-manager" >
                                        <img  src="assets/imgs/manager.png" style="float: right;  " />
                                        <ion-card-title  >Report summary as follows</ion-card-title>
                                        <ion-card-subtitle color="ion-color-tertiary-shade">Description</ion-card-subtitle>
                                    </ion-card-header>
                                </ion-card> -->

                    <ion-grid >
                        <ion-row>

                        </ion-row>

                    </ion-grid>





             <ion-item>
                <ion-label fixed>Select HQ</ion-label>
                  <ion-select v-model="param.hq_id"        :multiple="true"                  >
                      <ion-select-option  v-for="hqs in $store.state.user.hqlist.hq_list"  :key="hqs.hq_id"   :value="hqs.hq_id">
                      {{hqs.hq_name}}
                      </ion-select-option>
                  </ion-select>
              </ion-item>

              <ion-item>
                <!-- {{$store.state.user.month_year}} -->
                <ion-label fixed>Month Year</ion-label>
                  <ion-select v-model="param.monthyear">
                      <ion-select-option  v-for="monthyr in $store.state.user.month_year"
                      :key="monthyr.month_year_numeric" :value="monthyr.month_year_numeric">
                      {{monthyr.monthyear}}
                      </ion-select-option>
                  </ion-select>
              </ion-item>

              <ion-button expand="block" @click="LoadList">Load Report</ion-button>


              <!-- {{$store.state.SaveServerResponse['HQ']}} -->


             <ion-card v-for="(alllist,list_index)  in $store.state.SaveServerResponse['HQ']" :key="list_index" >
                <!-- class="ion-card-manager" -->
                  <ion-card-header   class="ion-card-manager" >
                      <img  src="assets/imgs/manager.png" style="float: right;  " />
                      <ion-card-title  >{{alllist.hq_name}}</ion-card-title>
                      <ion-card-subtitle color="ion-color-tertiary-shade">{{alllist.emp_name}}</ion-card-subtitle>
                  </ion-card-header>

                    <ion-grid >
                        <ion-row>
                          <ion-col size="12" size-sm="3" color="tertiary">Doctor Reports</ion-col>
                        </ion-row>

                        <ion-row>
                          <ion-col size="8" size-sm="3" >{{alllist.monthyear}}</ion-col>
                           <ion-col size="4" size-sm="3" >{{alllist.quaterly_months}}</ion-col>
                        </ion-row>
                        <ion-row>
                          <ion-col size="4" size-sm="3" >Reported</ion-col>
                          <ion-col size="4" size-sm="3" >Missed</ion-col>
                           <ion-col size="4" size-sm="3" >Tot Visit</ion-col>
                        </ion-row>

                        <ion-row>
                          <ion-col size="4" size-sm="3"  @click="()=>Reportdetail(alllist.hq_id,alllist.monthyear,'MONTHLY_DOC_VISIT')" >{{alllist.unique_doc_visit_month3}}</ion-col>
                          <ion-col size="4" size-sm="3"  @click="()=>Reportdetail(alllist.hq_id,alllist.monthyear,'MONTHLY_MISSED_CALL_REPORT')">{{alllist.doc_missed_month3}}</ion-col>
                           <ion-col size="4" size-sm="3" @click="()=>Reportdetail(alllist.hq_id,alllist.monthyear,'YEARLY_DOC_VISIT')">{{alllist.quaterly_total_visit}}</ion-col>
                        </ion-row>

                        <ion-row>
                          <ion-col size="12" size-sm="3" >
                          </ion-col>
                        </ion-row>

                       <Line :data="alllist.graph_data" :options="param.chart_option" />

                    </ion-grid>

            </ion-card>





    </ion-content>


    <!-- <ion-content>

    </ion-content> -->



  </ion-page>
</template>



<script>

import {
  IonPage,IonHeader,IonContent,IonToolbar,IonTitle,
  IonItem,
   IonSelect,IonSelectOption,IonButton ,
  IonCol, IonGrid,  IonRow,
  IonCard,  IonCardHeader, IonCardSubtitle, IonCardTitle,//IonCardContent,
  loadingController, toastController,modalController,
  }
  from "@ionic/vue";

import config from '../../config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex'
import {reactive} from 'vue'
//import { GChart } from 'vue-google-charts'
import HomeDetails from '@/components/reports/HomeDetails.vue';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'
//import * as chartConfig from './chartConfig.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)



//9433449500
export default defineComponent({

  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonItem,
    IonSelect,IonSelectOption,IonButton,
    IonCol, IonGrid,  IonRow,
    IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, //, IonCardContent
    //GChart,
    Line
  },

  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

    const param=reactive({
      fromdate:'',
      todate:'',
      tbl_hierarchy_org_id:'',
      hq_id:'',
      REPORT_TYPE:'',
      monthyear:'',
      tour_plan_data:'',
      hostname:config.hostname,
      hostconfig: config.config,
      chartdata:{},
      chart_option:{},
    });

    param.chart_option=
    {
      responsive: true,
      maintainAspectRatio: true
    };

    //MADAL POPUP SECTION

    const LoadList= async ()=>{

        const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',
           duration: 3000

        });
        await loading.present();

        console.log('HQS :'+param.hq_id);

        await store.dispatch('team_report',param);

        // param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
        // param.tbl_designation_id=store.state.user.tbl_designation_id;
        // await store.dispatch('populate_data',param);
        // update_visit();

        loading.dismiss();

    }

       const dcr_entry_section= async ()=>{router.push('/DCREntry/Doctor/');}
       const dailyPlan= async ()=>{router.push('/dailyPlanDetail/');}


     const Reportdetail= async (hq_id,monthyear,REPORT_TYPE)=>{

       //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',
          duration: 30000

        });
        await loading.present();

        store.state.hq_id=param.hq_id=hq_id;
        store.state.monthyear=param.monthyear=monthyear;
        store.state.REPORT_TYPE=param.REPORT_TYPE=REPORT_TYPE;

      // store.state.DCRdetail.trandate=param.trandate;
      // param.id=store.state.populate_data.hq_list[hq_index_save].field_list[field_index_save].doctor_list[doctor_retailer_stockist_index_save].id;

        try{

          // console.log('param.latitude '+param.latitude+' param.longitude '+param.longitude);

          await store.dispatch('team_report_detail',param);
          loading.dismiss();

          //LOAD DCR DETAIL MODAL POPUP
          var title="Doctor";
          const modal = await modalController
          .create({
            component: HomeDetails,
            cssClass: 'my-custom-class',
            componentProps: {
              title: title,
            },
          });

          modal.onDidDismiss().then((data) => {
            console.log(data);
             // getdate(data,date_picker_name);
          });

        return modal.present();


         // router.push('/DCRdetail/');
        }
        catch(e)
        {

              loading.dismiss()
              //Toast START
                const toast = await toastController
                  .create({
                    message: e,
                    duration: 3000
                  })
                toast.present();
        }

    }



    return{router,store,param,LoadList,Reportdetail,dcr_entry_section,dailyPlan};

  }
});
</script>

