
<template>
<div>
  <h1>Geolocation</h1>
  <p>Your location is:</p>
  <p>Latitude: {{ location.latitude }}</p>
  <p>Longitude: {{ location.longitude }}</p>

  <button @click="getCurrentPosition">
    Get Current Location
  </button>

  {{data}}
</div>
</template>

<script>
/* https://capacitorjs.com/solution/vue*/
import { defineComponent, ref,reactive } from 'vue';
import { Geolocation } from '@capacitor/geolocation';
export default defineComponent({

  name: 'GeolocationExample',
  setup() {

    const lat=ref(0);
    const long=ref(0);
    const data = ref(null);
    const error = ref(null);

    const location=reactive({
        latitude:0,
        longitude:0
    });

     const getCurrentPosition = async () => {
      try {
        const json = await  Geolocation.getCurrentPosition();
        location.latitude = json.coords.latitude;
        location.longitude = json.coords.longitude;
      } catch (e) {
        error.value = e;
      }
      console.log(location);
    };

    return { getCurrentPosition,location,lat, long ,data,error };
  },
});
</script>