<template>
  <ion-page>

   <ion-header>
      <ion-toolbar>
        <ion-title>Doctor Approval Section</ion-title>
        <ion-buttons slot="start">
          <ion-menu-button menu="custom"></ion-menu-button>
        </ion-buttons>
        <!-- <img  slot="end" @click="LoadList" src="assets/imgs/reload_small.png"  /> -->
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

            <ion-item>
                <ion-label fixed>Select HQ</ion-label>
                  <ion-select v-model="param.hq_id" >
                      <ion-select-option  v-for="hqs in $store.state.user.hqlist.hq_list" :key="hqs.hq_id" :value="hqs.hq_id">
                      {{hqs.hq_name}}
                      </ion-select-option>
                  </ion-select>
              </ion-item>
              <ion-button expand="block" @click="LoadPendingApprovalList">Load Doctor</ion-button>

              <!-- {{$store.state.SaveServerResponse}} -->


              <ion-item-group  v-for="(item_list,item_index) in $store.state.SaveServerResponse" :key="item_list.id" >
              <ion-item-divider class="ionheader">
                <ion-label>{{item_index+1}} - {{item_list.apply_type}} </ion-label>

                    <!-- @click="someHandler(sample_index)" -->
                    <ion-checkbox slot="end"  v-model="item_list.approve_by_manager_status" >
                              {{item_list.approve_by_manager_status}}
                    </ion-checkbox>
                </ion-item-divider>


             <ion-row>
                        <ion-col size="6" >
                          APPLY FOR
                        </ion-col>

                          <ion-col size="6" >
                          PREVIOUS RECORD
                        </ion-col>
              </ion-row>

                <ion-row>
                        <ion-col size="6" class="transparent-bg2">
                            <div v-html="item_list.apply_content" ></div>
                        </ion-col>

                          <ion-col size="6" class="transparent-bg2">
                           <div v-html="item_list.previous_record" ></div>
                        </ion-col>
              </ion-row>


              </ion-item-group>

              <ion-button expand="block" @click="SaveApproval">Save</ion-button>


    </ion-content>

  </ion-page>
</template>

<script>



//IonButton,
import {
 IonContent,IonHeader,IonPage,IonTitle,IonToolbar,IonItem,
 IonButtons,IonMenuButton,
  IonItemGroup,IonLabel,IonItemDivider,
loadingController, toastController,
IonSelect,IonSelectOption,IonCheckbox
 //IonButton
//loadingController, toastController,modalController,
} from '@ionic/vue';

import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex';
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue';
// import DCRdetailcomponent from '@/components/dcr_section/DCRdetail.vue';
// import datepickermodal from '@/components/datepickermodal.vue';
// import { Geolocation } from '@capacitor/geolocation';

export default defineComponent({
  components: { IonContent,IonHeader,IonPage,IonTitle,IonToolbar,IonItem,
  IonButtons,IonMenuButton,IonItemGroup,IonLabel,IonItemDivider,
  IonSelect,IonSelectOption,IonCheckbox
  //IonButton
  },

  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

     const param=reactive({
          tbl_hierarchy_org_id:0,
          hq_id:0,
          subtype:'DOCTOR_PENDING_APPROVAL_LIST',
          subtype_save:'DOCTOR_PENDING_APPROVAL_SAVE',
          id:'',
          approve_by_manager_status:false,
          saverecords:'',
          hostname:config.hostname,
          hostconfig: config.config
    });


    const LoadPendingApprovalList= async ()=>{

      //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Loading ..... Please wait...',

        });
        await loading.present();

        //CREATE PAYLOAD
         store.state.hq_id=param.hq_id;
         param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
         console.log(param);

      //LOADING FUNCTION
      await store.dispatch('LoadPendingApprovalList',param);
      loading.dismiss();

      //  //Toast START
      const toast = await toastController
        .create({
          message: 'Report Loaded..',
          duration: 1000
        })
      toast.present();
      // //Toast END

    }


    const SaveApproval= async ()=>{

          const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Loading ..... Please wait...',

        });
        await loading.present();

        try{


                //SAVING SECTION

                //await store.dispatch('SaveDCR',param);
                //await store.dispatch('populate_data',param);

                //store.state.populate_data.hq_list[param.hq_index].field_list[param.field_index].doctor_list[param.doctor_retailer_stockist_index].reporting_activity_details='REPORTED'

                  // console.log('============AFTER SAVE======================== ');

                console.log('Approval status');

                param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
                param.saverecords=store.state.SaveServerResponse;


                param.saverecords.forEach((value, index) =>
                {
                   console.log('Data for Index :'+index+ ' STATUS Is '+value.approve_by_manager_status);

                    param.id=value.id;
                    if(value.approve_by_manager_status==true)
                    {param.approve_by_manager_status=1;}
                    else
                    {param.approve_by_manager_status=0;}
                    store.dispatch('SaveApproval',param);

                  //  param.approve_by_manager_status=value.approve_by_manager_status;

                });



                loading.dismiss();

                //Toast START
                const toast = await toastController
                  .create({
                    message: store.state.SaveServerResponse.message,
                    duration: 3000
                  })
                toast.present();
                //Toast END

        }
        catch (e)
        {
            //param.gps_error = e;
               loading.dismiss()
             //Toast START
              const toast = await toastController
                .create({
                  message: e,
                  duration: 3000
                })
              toast.present();

        }

    }

    return{router,store,param,LoadPendingApprovalList,SaveApproval};
  }

});
</script>