<template>
  <ion-page>

  <ion-header>
      <ion-toolbar>
        <ion-title>Product Detail</ion-title>
        <ion-buttons slot="start">
          <!-- <ion-menu-button menu="custom"></ion-menu-button> -->
          <!-- <ion-back-button ></ion-back-button>          -->
        </ion-buttons>
         <ion-button @click="closemodal" slot="end">X</ion-button>
      </ion-toolbar>
    </ion-header>



    <ion-content class="ion-padding">

       <ion-item >
         <div v-html="$store.state.SaveServerResponse[$store.state.general_purpose_indx]['brand_details']" ></div>
       </ion-item>

       <ion-item >
        <ion-img :src="$store.state.general_purpose_image"></ion-img>
       </ion-item>


      </ion-content>



  </ion-page>
</template>

<script>



//IonButton,
import {
 IonImg,IonContent, IonHeader, IonPage, IonTitle,IonButton,IonButtons, IonToolbar,IonItem,modalController,
} from '@ionic/vue';

import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex';
import {reactive} from 'vue';
//import { computed } from 'vue'

export default defineComponent({
  components: { IonImg,IonContent, IonHeader, IonPage, IonTitle,IonButton,IonButtons, IonToolbar,IonItem,
    },

  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

     const param=reactive({
          id:'',
          image:'',
          variable:'',
          hostname:config.hostname,
          hostconfig: config.config
    });

    param.variable = {"html": "<p>This is a paragraph</p>"};

     const closemodal= async ()=>
    {
      console.log('test');
      await modalController.dismiss();}

    return{router,store,param,closemodal};
  }

});
</script>