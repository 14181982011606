<template>

  <ion-page>

      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start" >
            <ion-menu-button menu="custom"></ion-menu-button>
            <ion-back-button ></ion-back-button>
          </ion-buttons>
          <ion-title>Proposal Entry</ion-title>
        </ion-toolbar>
      </ion-header>

     <ion-content>
              <!-- {{$store.state.user.hqlist.hq_id}} -->
              <ion-item>
                <ion-label fixed>Select HQ</ion-label>
                  <ion-select v-model="param.hq_id">
                      <ion-select-option  v-for="hqs in $store.state.user.hqlist.hq_list" :key="hqs.hq_id" :value="hqs.hq_id">
                      {{hqs.hq_name}}
                      </ion-select-option>
                  </ion-select>
              </ion-item>

              <!-- <ion-item>
                <ion-label fixed>Month Year</ion-label>
                  <ion-select v-model="param.monthyear">
                      <ion-select-option  v-for="monthyr in $store.state.user.month_year" :key="monthyr.month_year_numeric" :value="monthyr.month_year_numeric">
                      {{monthyr.monthyear}}
                      </ion-select-option>
                  </ion-select>
              </ion-item> -->


              <ion-button expand="block" @click="LoadDoctor">Load Doctor</ion-button>

                <!-- {{$store.state.user}} -->
               <!-- {{$store.state.SaveServerResponse}} -->

              <!-- {{$store.state.DoctorSales.list}} -->


                    <!-- {{store.state.SaveServerResponse[0]["doctors"][0]["name"]}} -->

                   <ion-item-group v-for="(area_list,area_index) in $store.state.SaveServerResponse" :key="area_list.area_id">
                    <ion-item-divider> <ion-label>{{area_index+1}}. Area/Location : {{area_list.area_name}} </ion-label> </ion-item-divider>

                        <div  v-for="(doctor_list,doctor_index) in area_list.doctors" :key="doctor_list.id"
                        @click="()=>detail_entry(area_index,doctor_index,'doctors')">
                            <ion-card >
                                <ion-card-header   class="ion-card-manager" >
                                  <!-- <img  src="assets/imgs/manager.png" style="float: right; " /> -->
                                  <ion-card-title  >{{doctor_index+1}}. {{doctor_list.name}}</ion-card-title>
                                  <ion-card-subtitle color="ion-color-tertiary-shade">(Qualification:{{doctor_list.qualification}})
                                    <!-- <br><strong> Visited At:{{expense_list.mr_worked_fields}}
                                    </strong>-->
                                    </ion-card-subtitle>
                                </ion-card-header>

                                <ion-card-content>
                                      <ion-item><ion-label >MR Wise Last Month Visit::{{doctor_list.MR_WISE_LAST_MONTH_VISIT}}</ion-label></ion-item>
                                      <ion-item><ion-label >Manager Wise Last Month Visit::{{doctor_list.MANAGER_WISE_LAST_MONTH_VISIT}}</ion-label> </ion-item>
                                      <ion-item><ion-label >MR Wise Last 3 Month Visit::{{doctor_list.MR_WISE_LAST_3MONTH_VISIT}}</ion-label> </ion-item>
                                      <ion-item><ion-label >Manager Wise 3 Last Month Visit::{{doctor_list.MANAGER_WISE_LAST_3MONTH_VISIT}}</ion-label> </ion-item>
                                      <ion-item><ion-label >Total Investment::{{doctor_list.TOTAL_INVETMENT}}</ion-label> </ion-item>
                                      <ion-item><ion-label >Total Return::{{doctor_list.TOTAL_RETURN}}</ion-label> </ion-item>
                                      <ion-item><ion-label >Total Balance::{{doctor_list.TOTAL_BALANCE}}</ion-label> </ion-item>
                                </ion-card-content>

                          </ion-card>
                        </div>

                        <!-- <ion-item-group  v-for="(doctor_list,doctor_index) in area_list.doctors" :key="doctor_list.id"
                        @click="()=>detail_entry(area_index,doctor_index,'doctors')" >
                                <ion-item lines="none">
                                  <ion-label>{{doctor_index+1}}. {{doctor_list.name}} </ion-label>
                                  <ion-label slot="end" >{{doctor_list.qualification}} </ion-label>
                                </ion-item>

                                <ion-item >
                                  <ion-label>{{doctor_list.chamber1}} </ion-label>
                                </ion-item>
                        </ion-item-group> -->


                  </ion-item-group>






    </ion-content>

  </ion-page>
</template>

<script>

import {
  IonPage,IonHeader,IonContent,IonToolbar,IonTitle,
  IonItem,IonSelect,IonSelectOption,IonCard, IonCardHeader,IonCardSubtitle,
  IonButtons,IonMenuButton,
  IonButton,IonItemGroup,  IonLabel, IonItemDivider,
  loadingController,
  toastController,modalController }
  from "@ionic/vue";


import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex';
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue';
import proposal_entry_details from '@/components/proposal_section/proposal_entry_details.vue';


export default defineComponent({

  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonCard, IonCardHeader,IonCardSubtitle,
    IonButtons,IonMenuButton,
    IonItem,IonItemGroup,  IonLabel, IonItemDivider,
    IonSelect,IonSelectOption,IonButton,
  },


  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

    const param=reactive({

      //monthyear:'',
      hq_id:'',
      tbl_hierarchy_org_id:'',
      subtype:'DOC_LIST',
      hostname:config.hostname,
      hostconfig: config.config
    });

     param.hq_id=store.state.user.tbl_hierarchy_org_id;


     const LoadDoctor= async ()=>{

      //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Loading ..... Please wait...',
        });
        await loading.present();
        //CREATE PAYLOAD
         param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
         // PAYLOAD END
        console.log(param);

      //LOADING FUNCTION
      await store.dispatch('proposalDoctors',param);
      loading.dismiss();
      //  //Toast START
      const toast = await toastController
        .create({
          message: 'Loaded..',
          duration: 1000
        })
      toast.present();
      // //Toast END

    }


    //LOAD TOUR PLAN END

    //OPEN MODAL POPUP FOR DETAIL TOUR PLAN

    const detail_entry= async (area_index,doctor_index,TYPE_ENTRY)=>
    {
        //console.log(param.monthyear);
        store.state.DCRdetail.hq_index=0;
        store.state.DCRdetail.field_index=area_index;
        store.state.DCRdetail.doctor_retailer_stockist_index=doctor_index;
        store.state.DCRdetail.data_type=TYPE_ENTRY;

        store.state.DCRdetail.name=store.state.SaveServerResponse[area_index]['doctors'][doctor_index]['name'];
        store.state.DCRdetail.doctor_retailer_id=store.state.SaveServerResponse[area_index]['doctors'][doctor_index]['id'];
        //store.state.DCRdetail.name=store.state.SaveServerResponse[area_index]['doctors'][doctor_index]['name'];

        console.log(store.state.SaveServerResponse);

        const modal = await modalController
          .create({
            component: proposal_entry_details,
            cssClass: 'my-custom-class',
            componentProps: {
              doctor_index: doctor_index,
              area_index : area_index,
              TYPE_ENTRY : TYPE_ENTRY,
             // monthyear:param.monthyear,
              hq_id:param.hq_id,

            },
          })
        return modal.present();
    }


    return{router,store,param,LoadDoctor,detail_entry};

  }
});
</script>