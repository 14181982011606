
import { createRouter, createWebHistory } from '@ionic/vue-router';


//AITIANA
/*
import LoginPageAtiana from '@/components/aitiana/LoginPageAtiana.vue';
import bus_doc_expiry from '@/components/aitiana/bus_doc_expiry.vue';
import truck_doc_wise_previous_list from '@/components/aitiana/truck_doc_wise_previous_list.vue';
import bus_doc_expiry_detail from '@/components/aitiana/bus_doc_expiry_detail.vue';
*/

//MISC
import LoginPage from '@/components/LoginPage.vue';
import TourPlan from '@/components/tour_section/TourPlan.vue';
import TourPlanExperiment from '@/components/tour_section/TourPlanExperiment.vue';
import dailyPlanDetail from '@/components/tour_section/dailyPlanDetail.vue';


//DCR ENTRY SECTION
import DCREntry from '@/components/dcr_section/DCREntry.vue';
import Doctor from '@/components/dcr_section/Doctor.vue';
import Retailer from '@/components/dcr_section/Retailer.vue';
import Stockist from '@/components/dcr_section/Stockist.vue';
import Other_Activity from '@/components/dcr_section/Other_Activity.vue';
import DCRdetail from '@/components/dcr_section/DCRdetail.vue';
import dcr_today from '@/components/dcr_section/dcr_today.vue';
//DCR ENTRY SECTION

//doctor_section-reports
//REPORT SECTION
import DCR_Report from '@/components/reports/DCR_Report.vue';
import Doctor_list from '@/components/reports/Doctor_list.vue';
import Retailer_list from '@/components/reports/Retailer_list.vue';

import monthly_doctor_visit_report from '@/components/reports/monthly_doctor_visit_report.vue';
import quaterly_doctor_visit_report from '@/components/reports/quaterly_doctor_visit_report.vue';
import Miss_call_Report from '@/components/reports/Miss_call_Report.vue';
import HomePage from '@/components/reports/HomePage.vue';
import HomeDetails from '@/components/reports/HomeDetails.vue';
import view_expense from '@/components/reports/view_expense.vue';
import product_summary from '@/components/reports/product_summary.vue';
//REPORT SECTION


//PROPOSAL SECTION
import doctor_wise_product_wise_sale_entry from '@/components/proposal_section/doctor_wise_product_wise_sale_entry.vue';
import proposal_entry from '@/components/proposal_section/proposal_entry.vue';
//PROPOSAL SECTION END


//MASTER SECTION
  //DOCTOR RELATED
  import Doctor_master from '@/components/master/doctor/Doctor_master.vue';
  import Doctor_master_active from '@/components/master/doctor/Doctor_master_active.vue';
  import Doctor_master_inactive from '@/components/master/doctor/Doctor_master_inactive.vue';
  import Doctor_master_add_edit from '@/components/master/doctor/Doctor_master_add_edit.vue';

   //RETAILER RELATED
   import Retailer_master from '@/components/master/retailer/Retailer_master.vue';
   import Retailer_master_active from '@/components/master/retailer/Retailer_master_active.vue';
   import Retailer_master_inactive from '@/components/master/retailer/Retailer_master_inactive.vue';
   import Retailer_master_add_edit from '@/components/master/retailer/Retailer_master_add_edit.vue';

  //LOCATION RELATED
  import location_list from '@/components/master/location/location_list.vue';
  import location_master_add_edit from '@/components/master/location/location_master_add_edit.vue';

//MASTER SECTION END


//APPROVAL SECTION
import Location_Approval from '@/components/approval_section/Location_Approval.vue';
import Doctor_Approval from '@/components/approval_section/Doctor_Approval.vue';
import Retailer_Approval from '@/components/approval_section/Retailer_Approval.vue';
import Daily_tour_plan_Approval from '@/components/approval_section/Daily_tour_plan_Approval.vue';
import Monthly_tour_plan_Approval from '@/components/approval_section/Monthly_tour_plan_Approval.vue';
//APPROVAL SECTION END


//SAVIOUR SPECIFIC
import doctor_wise_monthly_sale from '@/components/others/doctor_wise_monthly_sale_new.vue';





//TEST EXPERIMENT
import ActivityEntry from '@/components/test/ActivityEntry.vue';
import ActivityEntryDetail from '@/components/test/ActivityEntryDetail.vue';
import GeolocationExample from '@/components/test/GeolocationExample.vue';
import VuexExample from '@/components/test/VuexExample.vue';
import AutoComplete from '@/components/test/AutoComplete.vue';
import gmail_login_test from '@/components/test/gmail_login_test.vue';
//TEST EXPERIMENT

const routes = [
  {
    path: '/',
    redirect: '/LoginPage'
  },
  //AITIANA SECTION
  /*
  {
    path: '/LoginPage',
    name: 'LoginPage',
    component: LoginPageAtiana
  },
  {
    path: '/bus_doc_expiry',
    name: 'bus_doc_expiry',
    component: bus_doc_expiry
  },
  {
    path: '/truck_doc_wise_previous_list',
    name: 'truck_doc_wise_previous_list',
    component: truck_doc_wise_previous_list
  },
  {
    path: '/bus_doc_expiry_detail',
    name: 'bus_doc_expiry_detail',
    component: bus_doc_expiry_detail
  },
  */


  //PHARMA PROJECT----ALL
  {
    path: '/LoginPage',
    name: 'LoginPage',
    component: LoginPage
  },
  //REPORT SECTION
  {
    path: '/HomePage',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/HomeDetails',
    name: 'HomeDetails',
    component: HomeDetails
  },
  {
    path: '/DCR_Report',
    name: 'DCR_Report',
    component: DCR_Report
  },
  {
    path: '/monthly_doctor_visit_report',
    name: 'monthly_doctor_visit_report',
    component: monthly_doctor_visit_report
  },
  {
    path: '/quaterly_doctor_visit_report',
    name: 'quaterly_doctor_visit_report',
    component: quaterly_doctor_visit_report
  },
  {
    path: '/Miss_call_Report',
    name: 'Miss_call_Report',
    component: Miss_call_Report
  },
  {
    path: '/product_summary',
    name: 'product_summary',
    component: product_summary
  },
  {
    path: '/doctor_wise_monthly_sale',
    name: 'doctor_wise_monthly_sale',
    component: doctor_wise_monthly_sale
  },
  {
    path: '/view_expense',
    name: 'view_expense',
    component: view_expense
  },
  {
    path: '/Doctor_list',
    name: 'Doctor_list',
    component: Doctor_list
  },
  {
    path: '/Retailer_list',
    name: 'Retailer_list',
    component: Retailer_list
  },


  //REPORT SECTION

  //OTHER SECTION
  {
    path: '/proposal_entry',
    name: 'proposal_entry',
    component: proposal_entry
  },
  {
    path: '/TourPlan',
    name: 'TourPlan',
    component: TourPlan
  },
  {
    path: '/TourPlanExperiment',
    name: 'TourPlanExperiment',
    component: TourPlanExperiment
  },
  {
    path: '/dailyPlanDetail',
    name: 'dailyPlanDetail',
    component: dailyPlanDetail
  },


  {
    path: '/doctor_wise_product_wise_sale_entry',
    name: 'doctor_wise_product_wise_sale_entry',
    component: doctor_wise_product_wise_sale_entry
  },
  //OTHER SECTION END

  //DCR SECTION
  {
    path: '/DCREntry/',
    component: DCREntry,
    children: [
      {
        path: '',
        redirect: 'Doctor',
      },
      {
        path: 'Doctor',
        component: Doctor,
      },
      {
        path: 'Retailer',
        component:  Retailer,
      },
      {
        path: 'Stockist',
        component:  Stockist,
      },
      {
        path: 'Other',
        component:  Other_Activity,
      },
      {
        path: 'dcr_today',
        component:  dcr_today,
      },
    ],
  },
  {
    path: '/DCRdetail',
    name: 'DCRdetail',
    component: DCRdetail
  },
  //DCR SECTION END

  //LOCATION MASTER SECTION
  {
    path: '/location_list',
    name: 'location_list',
    component: location_list
  },
  {
    path: '/location_master_add_edit',
    name: 'location_master_add_edit',
    component: location_master_add_edit
  },

  //DOCTOR MASTER SECTION
  {
    path: '/Doctor_master/',
    component: Doctor_master,
    children: [
      {
        path: '',
        redirect: 'Doctor_master_active',
      },
      {
        path: 'Doctor_master_add_edit',
        component: Doctor_master_add_edit,
      },
      {
        path: 'Doctor_master_active',
        component: Doctor_master_active,
      },
      {
        path: 'Doctor_master_inactive',
        component:  Doctor_master_inactive,
      },

    ],
  },

  //RETAILER MASTER SECTION
  {
    path: '/Retailer_master/',
    component: Retailer_master,
    children: [
      {
        path: '',
        redirect: 'Retailer_master_active',
      },
      {
        path: 'Retailer_master_add_edit',
        component: Retailer_master_add_edit,
      },
      {
        path: 'Retailer_master_active',
        component: Retailer_master_active,
      },
      {
        path: 'Retailer_master_inactive',
        component:  Retailer_master_inactive,
      },

    ],
  },

  //MASTER SECTION END

  //APPROVAL SECTION
  {
    path: '/Location_Approval',
    name: 'Location_Approval',
    component:  Location_Approval,
  },
  {
    path: '/Doctor_Approval',
    name: 'Doctor_Approval',
    component:  Doctor_Approval,
  },
  {
    path: '/Retailer_Approval',
    name: 'Retailer_Approval',
    component:  Retailer_Approval,
  },
  {
    path: '/Daily_tour_plan_Approval',
    name: 'Daily_tour_plan_Approval',
    component:  Daily_tour_plan_Approval,
  },
  {
    path: '/Monthly_tour_plan_Approval',
    name: 'Monthly_tour_plan_Approval',
    component:  Monthly_tour_plan_Approval,
  },
  //APPROVAL SECTION END

  //TEST SECTION
  {
    path: '/GeolocationExample',
    name: 'GeolocationExample',
    component: GeolocationExample
  },
  {
    path: '/VuexExample',
    name: 'VuexExample',
    component: VuexExample
  },
  {
    path: '/AutoComplete',
    name: 'AutoComplete',
    component: AutoComplete
  },
  {
    path: '/ActivityEntry',
    name: 'ActivityEntry',
    component: ActivityEntry
  },
  {
    path: '/gmail_login_test',
    name: 'gmail_login_test',
    component: gmail_login_test
  },
  {
    path: '/ActivityEntryDetail/:id',
    name: 'ActivityEntryDetail',
    component: ActivityEntryDetail,
    //component: ()=>import(ActivityEntryDetail) //For Lazy Loading
  },
  //TEST SECTION END


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
