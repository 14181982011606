import axios from 'axios'

const jwtInterceptor = (store) => {
  axios.interceptors.request.use((config) => {
    // Retrieve the JWT token from the store
   // const token = store.getters['auth/getToken']

    // If a token exists, add it to the Authorization header
    if (store.state.JWT_Token) {
      config.headers['Authorization'] = `Bearer ${store.state.JWT_Token}`

      console.log(" config.headers:      " +  config.headers['Authorization']);

      //config.headers['Access-Control-Allow-Origin'] = "*";
      //config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8;';

    }

    return config
  })
}

// Create a new instance of axios with default headers
// const axiosInstance = axios.create({
//   headers: {
//     'Content-Type': 'application/json',
//     'Authorization': 'Bearer ' + store.state.JWT_Token // Set the authorization header with JWT token
//   }
// });

// // Add a request interceptor to modify the headers of the request
// axiosInstance.interceptors.request.use((config) => {
//   // Add custom headers to the request
//   config.headers['Custom-Header'] = 'Custom-Value';
//   return config;
// });

export default jwtInterceptor