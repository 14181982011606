<template>
  <ion-page>

   <ion-header>
      <ion-toolbar>
        <ion-title>Doctor Approval Section</ion-title>
        <ion-buttons slot="start">
          <ion-menu-button menu="custom"></ion-menu-button>
        </ion-buttons>
        <!-- <img  slot="end" @click="LoadList" src="assets/imgs/reload_small.png"  /> -->
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

              <ion-item-group >
                  <ion-item-divider class="ionheader"> <ion-label>HQ NAME</ion-label> </ion-item-divider>
                      <ion-item-group >
                          <ion-item-divider class="ionfield"> <ion-label>Field Name </ion-label> </ion-item-divider>
                              <div>
                                  <ion-item  class="lessvisit">
                                        Doctor name<ion-label  slot="end">123</ion-label>
                                </ion-item>
                              </div>
                      </ion-item-group>
              </ion-item-group>

            <!-- <div padding>  {{$store.state.DCRdetail.DCRdetail_data}} </div> -->
    </ion-content>

  </ion-page>
</template>

<script>



//IonButton,
import {
 IonContent,IonHeader,IonPage,IonTitle,IonToolbar,IonItem,
 IonButtons,IonMenuButton,
  IonItemGroup,IonLabel,IonItemDivider,

 //IonButton
//loadingController, toastController,modalController,
} from '@ionic/vue';

import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex';
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue';
// import DCRdetailcomponent from '@/components/dcr_section/DCRdetail.vue';
// import datepickermodal from '@/components/datepickermodal.vue';
// import { Geolocation } from '@capacitor/geolocation';

export default defineComponent({
  components: { IonContent,IonHeader,IonPage,IonTitle,IonToolbar,IonItem,
  IonButtons,IonMenuButton,IonItemGroup,IonLabel,IonItemDivider
  //IonButton
  },

  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

     const param=reactive({
          tbl_hierarchy_org_id:'',
          data_type:'',
          id:'',
          hostname:config.hostname,
          hostconfig: config.config
    });





    return{router,store,param,};
  }

});
</script>