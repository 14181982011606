<template>

  <ion-page>

     <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start" >
          <ion-back-button default-href="/ActivityEntry"></ion-back-button>
          </ion-buttons>
            <ion-title>Activity Entry Detail</ion-title>
        </ion-toolbar>
      </ion-header>

    <ion-content>
      <ion-button @click="() => router.push('/ActivityEntry')">Go to ActivityEntry Page</ion-button>
      <h1>Detail Page </h1>
        <h1> Param value : {{router.currentRoute._rawValue.params.id}}</h1>
      <button v-on:click="getCurrentPosition">getLocation</button>
      <ion-card>
        <ion-card-header>
          <ion-card-title>Coordinates</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-item>Permission Status: {{ chkpermission_status }}</ion-item>
          <ion-item>Latitude: {{ location.latitude }}</ion-item>
          <ion-item>Longitude: {{ location.longitude }}</ion-item>
        <!-- <ion-item>position: {{ position.getCurrentPosition }}</ion-item> -->

        </ion-card-content>
      </ion-card>

    </ion-content>
  </ion-page>



</template>


<script>
  import { IonButton, IonContent, IonPage ,IonHeader,IonToolbar,IonTitle,IonBackButton,IonButtons,
  IonCard,IonCardHeader,IonCardContent,IonItem } from '@ionic/vue';

  import { useRouter } from 'vue-router';

  //USE OF COMPOSITION VUE API

/* https://capacitorjs.com/solution/vue*/

  import { Geolocation } from '@capacitor/geolocation';
  import {ref,reactive, defineComponent } from 'vue';

  export default defineComponent({
    //latitude: '',
    //longitude: '',
    name: 'ActivityEntryDetail',
    idval:'',
    components: {
      IonButton,
      IonContent,
      IonPage,IonHeader,IonToolbar,IonTitle,IonBackButton,IonButtons,
      IonCard,IonCardHeader,IonCardContent,IonItem
    },
    setup() {
      const router = useRouter();
      const lat=ref(0);
      const long=ref(0);
      const data = ref(null);
      const error = ref(null);
      const chkpermission_status=ref(null);

      const location=reactive({
          latitude:0,
          longitude:0
      });

      const getCurrentPosition = async () => {
        try {

          const chkpermission = await Geolocation.checkPermissions();
          chkpermission_status.value=chkpermission.coarseLocation
          console.log('permission '+chkpermission.coarseLocation);
          const json = await  Geolocation.getCurrentPosition();
          location.latitude = json.coords.latitude;
          location.longitude = json.coords.longitude;
        } catch (e) {
          error.value = e;
        }
        //console.log(location);
        //console.log('permission '+chkpermission);
      };

      return { router,getCurrentPosition,location,chkpermission_status,lat, long ,data,error };
    },


  });


</script>
