<template>
  <ion-page>

   <ion-header>
      <ion-toolbar>
        <ion-title>Add/Edit Location</ion-title>
        <ion-buttons slot="start">
          <ion-menu-button menu="custom"></ion-menu-button>
        </ion-buttons>
        <!-- <img  slot="end" @click="LoadList" src="assets/imgs/reload_small.png"  /> -->



      </ion-toolbar>
    </ion-header>

     <ion-fab slot="fixed" vertical="top" horizontal="end">
            <ion-fab-button  @click="()=>LoadLocationForAddEdit(0,'ADD')">
              <ion-icon :icon="add"></ion-icon>
            </ion-fab-button>
        </ion-fab>

     <!-- {{$store.state.user.hqlist.hq_id}} -->


    <ion-content >

             <ion-item>
                <ion-label fixed>Select HQ</ion-label>
                  <ion-select v-model="param.hq_id" >
                      <ion-select-option  v-for="hqs in $store.state.user.hqlist.hq_list" :key="hqs.hq_id" :value="hqs.hq_id">
                      {{hqs.hq_name}}
                      </ion-select-option>
                  </ion-select>
              </ion-item>
              <ion-button expand="block" @click="LoadLocationList">Load Location</ion-button>

                <!-- {{$store.state.SaveServerResponse['LOCATIONS']}} -->



                <ion-item v-for="(area_list,area_index) in $store.state.SaveServerResponse['LOCATIONS']" :key="area_list.area_id">
                <ion-label @click="()=>LoadLocationForAddEdit(area_index,'EDIT')">{{area_index+1}}. {{area_list.area_name}} </ion-label>
                </ion-item>


    </ion-content>

  </ion-page>
</template>

<script>



//IonButton,
import {
 IonContent,IonHeader,IonPage,IonTitle,IonToolbar,
 IonButtons,IonMenuButton,IonLabel,
  IonItem,
//IonCol, IonGrid,  IonRow,IonItemDivider,IonItemGroup,
 IonButton,
 IonSelect,IonSelectOption,
loadingController, toastController,modalController,
 IonFab, IonFabButton,
} from '@ionic/vue';
import { add  } from 'ionicons/icons';
import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex';
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue';
import location_master_add_edit from '@/components/master/location/location_master_add_edit.vue';
// import { Geolocation } from '@capacitor/geolocation';

export default defineComponent({
  components: { IonContent,IonHeader,IonPage,IonTitle,IonToolbar,
  IonButtons,IonMenuButton,IonLabel, IonItem,
  IonSelect,IonSelectOption,IonButton,
   IonFab, IonFabButton,
  //IonCol, IonGrid,  IonRow,IonItemDivider,IonItemGroup,
  //
  },

  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

     const param=reactive({
          hq_id:'',
          tbl_hierarchy_org_id:'',
          subtype:'HQ_WISE_LOCATION_LIST',
          id:'',
          hostname:config.hostname,
          hostconfig: config.config
    });



     const LoadLocationList= async ()=>{

      //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Loading ..... Please wait...',

        });
        await loading.present();

        //CREATE PAYLOAD


         store.state.hq_id=param.hq_id;
         param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
         console.log(param);

      //LOADING FUNCTION
      await store.dispatch('LoadLocationList',param);
      loading.dismiss();

      //  //Toast START
      const toast = await toastController
        .create({
          message: 'Report Loaded..',
          duration: 1000
        })
      toast.present();
      // //Toast END

    }


   const LoadLocationForAddEdit= async (area_index,ENTRY_TYPE)=>{

       //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',

        });
        await loading.present();

        store.state.general_purpose_indx=area_index;
       // store.state.general_purpose_indx2=doctor_indx;
        store.state.REPORT_TYPE=ENTRY_TYPE;
        //store.state.REDIRECT_FROM_PAGE='ACTIVE';


        try{

         // await store.dispatch('DCRdetail',param);
          loading.dismiss();

          //LOAD DCR DETAIL MODAL POPUP
          var title="Location";
          const modal = await modalController
          .create({
            component: location_master_add_edit,
            cssClass: 'my-custom-class',
            componentProps: {
              title: title,
            },
          });

          modal.onDidDismiss().then((data) => {
               console.log(data);
              // getdate(data,date_picker_name);
          });

          return modal.present();

        }
        catch(e)
        {

              loading.dismiss()
              //Toast START
                const toast = await toastController
                  .create({
                    message: e,
                    duration: 3000
                  })
                toast.present();
        }

    }


    return{router,store,param,add,LoadLocationList,LoadLocationForAddEdit};

  }

});
</script>