<template>
  <ion-page>

   <ion-header>
      <ion-toolbar>
        <ion-title>Retailer Entry</ion-title>
         <ion-buttons slot="start">
          <ion-menu-button menu="custom"></ion-menu-button>
        </ion-buttons>
         <img  slot="end" @click="LoadList" src="assets/imgs/reload_small.png"  />
         <!-- <ion-button slot="end" @click="LoadList">Load</ion-button> -->
      </ion-toolbar>
    </ion-header>


     <ion-item>
            <ion-label fixed>Date </ion-label>
            <ion-label fixed>:{{param.trandate}}</ion-label>
            <ion-button fill="clear"  @click="formating_date('trandate')">Select Date </ion-button>
            <ion-button expand="block" @click="LoadList">View</ion-button>
       </ion-item>


    <ion-content class="ion-padding">
          <!-- <div padding>{{$store.state.user}} </div>
          <div padding>{{$store.state.user.tbl_hierarchy_org_id}}</div> -->
          <!-- <div padding>POULATE DATA : {{$store.state.populate_data.hq_list[0].field_list[0].doctor_list[0]}}</div> -->


                <ion-item-group v-for="(hqlist,hq_index) in $store.state.populate_data.hq_list" :key="hqlist.id">
                    <ion-item-divider class="ionheader"> <ion-label>{{hq_index+1}}. Hq: {{hqlist.hq_name}} </ion-label> </ion-item-divider>
                        <ion-item-group  v-for="(field_list,field_index) in hqlist.field_list" :key="field_list.id" >
                          <ion-item-divider class="ionfield"> <ion-label>{{field_index+1}}.Field: {{field_list.field_name}} </ion-label> </ion-item-divider>
                            <ion-list  v-for="(chemist_list,chemist_index)  in field_list.chemist_list" :key="chemist_list.id" >
                                <ion-item @click="()=>DCRdetail(hq_index,field_index,chemist_index,'chemist_list')">
                                  <ion-label>{{chemist_index+1}}.{{chemist_list.retail_name}}</ion-label>

                                    <ion-label v-if="chemist_list.reporting_activity_details === 'REPORTED'" slot="end">
                                     <img src="assets/imgs/correct.png"  /></ion-label>

                                  </ion-item>
                            </ion-list>
                        </ion-item-group>
                  </ion-item-group>

            <!-- <div padding>  {{$store.state.DCRdetail.DCRdetail_data}} </div> -->
    </ion-content>

  </ion-page>
</template>

<script>

import {
 IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonItem,
 IonButtons,IonButton,
  IonList,  IonItemGroup,  IonLabel, IonItemDivider,loadingController,toastController,modalController,
} from '@ionic/vue';

import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex'
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue'
import DCRdetailcomponent from '@/components/dcr_section/DCRdetail.vue';
import datepickermodal from '@/components/others/datepickermodal.vue';

export default defineComponent({
  components: { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonItem,
  IonButtons,IonButton,
  IonList, IonItemGroup,IonLabel,IonItemDivider  },

  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

     const param=reactive({
          tbl_hierarchy_org_id:'',
          tbl_designation_id:'',
          data_type:'',
          trandate:'',
          id:'',
          hostname:config.hostname,
          hostconfig: config.config
    });

      var initial_date=  new Date().toLocaleString();  //06/04/2022
      initial_date=initial_date.slice(6, 10)+'-'+initial_date.slice(3, 5)+'-'+initial_date.slice(0, 2);
      param.trandate=initial_date;

       //formating date function
      const formating_date= async (date_picker_name)=>
      {

           const modal = await modalController
            .create({
              component: datepickermodal,
              cssClass: 'my-custom-class',
              componentProps: {
                title: 'Date Pick',
                date_picker_name: date_picker_name
              },
            });

            modal.onDidDismiss().then((data) => {
                getdate(data,date_picker_name);
            });

          return modal.present();
      }

      const getdate= async (dates,date_picker_name)=>
      {
          if(date_picker_name=='trandate')
          {param.trandate=dates.data;}

      }



     const update_visit= async ()=>
     {
            var today_visit_status=store.state.populate_data.today_visit_status;
            store.state.populate_data.hq_list.forEach((hq_list, hq_index) =>
            {
                store.state.populate_data.hq_list[hq_index].field_list.forEach((field_list, field_index) =>
                {
                      store.state.populate_data.hq_list[hq_index].field_list[field_index].chemist_list.forEach((chemist_list, chemist_index) =>
                      {
                          //console.log('doctor_list.name:'+doctor_list.name+doctor_index);
                          var splittedStringArray = chemist_list.reporting_activity_details.split("|");

                          splittedStringArray.forEach((status_result, status_index) =>
                          {
                              if(today_visit_status===status_result)
                              {
                                store.state.populate_data.hq_list[hq_index].field_list[field_index].chemist_list[chemist_index].reporting_activity_details='REPORTED';
                                console.log('status_index:'+status_index);
                              }

                          })
                      })
                })
          });
     }

     update_visit();

     const LoadList= async ()=>{

        const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',

        });
        await loading.present();
        param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
        param.tbl_designation_id=store.state.user.tbl_designation_id;
        await store.dispatch('populate_data',param);

        update_visit();
        loading.dismiss();

    }


     const DCRdetail= async (hq_index,field_index,doctor_retailer_stockist_index,data_type)=>{

       //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',

        });
        await loading.present();

      store.state.DCRdetail.trandate=param.trandate;
      store.state.DCRdetail.hq_index=hq_index;
      store.state.DCRdetail.field_index=field_index;
      store.state.DCRdetail.doctor_retailer_stockist_index=doctor_retailer_stockist_index;
      store.state.DCRdetail.data_type=data_type;

      param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
      param.data_type=data_type;
      param.id=store.state.populate_data.hq_list[hq_index].field_list[field_index].chemist_list[doctor_retailer_stockist_index].id;



       try{

          await store.dispatch('DCRdetail',param);
          loading.dismiss();

          //LOAD DCR DETAIL MODAL POPUP
          var title="Doctor";
          const modal = await modalController
          .create({
            component: DCRdetailcomponent,
            cssClass: 'my-custom-class',
            componentProps: {
              title: title,
            },
          });

          modal.onDidDismiss().then((data) => {
            console.log(data);
             // getdate(data,date_picker_name);
          });

        return modal.present();


         // router.push('/DCRdetail/');
        }
        catch(e)
        {

              loading.dismiss()
              //Toast START
                const toast = await toastController
                  .create({
                    message: e,
                    duration: 3000
                  })
                toast.present();
        }

      // await store.dispatch('DCRdetail',param);
      // loading.dismiss()
      // router.push('/DCRdetail/');

    }


    return{router,store,param,DCRdetail,LoadList,formating_date,update_visit};
  }

});
</script>