<template>
  <ion-page>

   <ion-header>
      <ion-toolbar>
        <ion-title>{{param.doctor_name}}</ion-title>
        <!-- <img  slot="end" @click="LoadList" src="assets/imgs/reload_small.png"  /> -->
         <ion-button @click="closemodal" slot="end">X</ion-button>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
        <!-- <ion-item> Area Index:  {{$store.state.general_purpose_indx}} -- Doc Index  {{$store.state.general_purpose_indx2}}</ion-item> -->
        <!-- {{$store.state.DoctorList.DOCTORS.ACTIVE_DOCTORS[$store.state.general_purpose_indx]['doctors'][$store.state.general_purpose_indx2]}} -->

                <ion-item>
                    <ion-label position="floating">Doctor Name</ion-label>
                    <ion-input  type="text" v-model="param.name" ></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label>Location</ion-label>
                        <ion-select v-model="param.headq">
                        <ion-select-option
                        v-for="list in $store.state.DoctorList.DOCTORS.field_list" :key="list.id"
                        :value="list.id"  >{{list.hierarchy_name}}
                        </ion-select-option>
                        </ion-select>
                </ion-item>

                <ion-item>
                    <ion-label>Doctor Category</ion-label>
                        <ion-select v-model="param.doc_type">
                        <ion-select-option
                        v-for="list in $store.state.DoctorList.DOCTORS.doc_type" :key="list"
                        :value="list"  >{{list}}
                        </ion-select-option>
                        </ion-select>
                </ion-item>
               <!-- doc_type,qualification,speciality,contactno,email,address -->
                 <ion-item>
                    <ion-label position="floating">Qualification</ion-label>
                    <ion-input  type="text" v-model="param.qualification" ></ion-input>
                </ion-item>

                 <ion-item>
                    <ion-label>Speciality</ion-label>
                        <ion-select v-model="param.speciality">
                        <ion-select-option
                        v-for="list in $store.state.DoctorList.DOCTORS.speciality_list" :key="list.id"
                        :value="list.id">{{list.brand_name}}
                        </ion-select-option>
                        </ion-select>
                </ion-item>

                 <ion-item>
                    <ion-label position="floating">Contact No </ion-label>
                    <ion-input  type="text" v-model="param.contactno" ></ion-input>
                </ion-item>

                 <ion-item>
                    <ion-label position="floating">Email Id</ion-label>
                    <ion-input  type="text" v-model="param.email" ></ion-input>
                </ion-item>

                <ion-item>
                        <ion-label position="floating">Address</ion-label>
                        <ion-textarea v-model="param.address"></ion-textarea>
                </ion-item>

                 <ion-item>
                    <ion-label>Status</ion-label>
                        <ion-select v-model="param.ACTIVITY_STATUS">
                        <ion-select-option
                        v-for="list in $store.state.DoctorList.DOCTORS.ACTIVITY_STATUS" :key="list"
                        :value="list"  >{{list}}
                        </ion-select-option>
                        </ion-select>
                </ion-item>

                <ion-button expand="block" @click="SaveDoctor">Save Doctor</ion-button>

            <!-- <div padding>  {{$store.state.DCRdetail.DCRdetail_data}} </div> -->
    </ion-content>

  </ion-page>
</template>

<script>



//IonButton,
import {
 IonContent,IonHeader,IonPage,IonTitle,IonToolbar,IonItem,
 IonButton,IonLabel,
  IonInput,IonSelect,IonSelectOption,IonTextarea,
 //IonMenuButton, IonItemGroup,IonLabel,IonItemDivider,
 //IonButton
 modalController,toastController,loadingController,
} from '@ionic/vue';

import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex';
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue';
// import DCRdetailcomponent from '@/components/dcr_section/DCRdetail.vue';
// import datepickermodal from '@/components/datepickermodal.vue';
// import { Geolocation } from '@capacitor/geolocation';

export default defineComponent({
  components: { IonContent,IonHeader,IonPage,IonTitle,IonToolbar,IonItem,
 IonButton,IonLabel,
  IonInput,IonSelect,IonSelectOption,IonTextarea,
  //IonMenuButton,IonItemGroup,IonLabel,IonItemDivider
  //IonButton
  },

  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

     const param=reactive({

          REDIRECT_FROM_PAGE:'',
          ENTRY_TYPE:'',
          tbl_hierarchy_org_id:'',
          data_type:'',
          id:'',
          name:'',
          headq:0,
          hq_id:0,
          doc_type:'',
          qualification:'',
          speciality:0,
          contactno:'',
          email:'',
          address:'',
          ACTIVITY_STATUS:'ACTIVE',
          field_index:0,
          doctor_retailer_stockist_index:0,
          doctor_name:'New Doctor',
          hostname:config.hostname,
          hostconfig: config.config
    });

      param.field_index= store.state.general_purpose_indx;
      param.doctor_retailer_stockist_index= store.state.general_purpose_indx2;
      param.ENTRY_TYPE= store.state.REPORT_TYPE;
      param.hq_id=store.state.hq_id;
      param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;

      param.REDIRECT_FROM_PAGE=store.state.REDIRECT_FROM_PAGE;


      if(param.ENTRY_TYPE=='ADD_DOCTOR')
      {
          param.id=0;
          param.name='';
          param.headq='';
          param.doc_type='';
          param.qualification='';
          param.speciality='';
          param.contactno='';
          param.email='';
          param.address='';

      }
      else
      {
          if(param.REDIRECT_FROM_PAGE=='ACTIVE')
          {
              param.id=store.state.DoctorList.DOCTORS.ACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].id;
              param.doctor_name=param.name=store.state.DoctorList.DOCTORS.ACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].name;
              param.headq=store.state.DoctorList.DOCTORS.ACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].headq;
              param.hq_id=store.state.DoctorList.DOCTORS.ACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].hq_id;

              param.doc_type=store.state.DoctorList.DOCTORS.ACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].doc_type;
              param.qualification=store.state.DoctorList.DOCTORS.ACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].qualification;
              param.speciality=store.state.DoctorList.DOCTORS.ACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].speciality;
              param.contactno=store.state.DoctorList.DOCTORS.ACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].contactno;
              param.email=store.state.DoctorList.DOCTORS.ACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].email;
              param.address=store.state.DoctorList.DOCTORS.ACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].address;
              param.ACTIVITY_STATUS=store.state.DoctorList.DOCTORS.ACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].ACTIVITY_STATUS;

          }
          else
          {

              param.id=store.state.DoctorList.DOCTORS.INACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].id;
              param.doctor_name=param.name=store.state.DoctorList.DOCTORS.INACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].name;
              param.headq=store.state.DoctorList.DOCTORS.INACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].headq;
              param.hq_id=store.state.DoctorList.DOCTORS.INACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].hq_id;

              param.doc_type=store.state.DoctorList.DOCTORS.INACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].doc_type;
              param.qualification=store.state.DoctorList.DOCTORS.INACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].qualification;
              param.speciality=store.state.DoctorList.DOCTORS.INACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].speciality;
              param.contactno=store.state.DoctorList.DOCTORS.INACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].contactno;
              param.email=store.state.DoctorList.DOCTORS.INACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].email;
              param.address=store.state.DoctorList.DOCTORS.INACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].address;
              param.ACTIVITY_STATUS=store.state.DoctorList.DOCTORS.INACTIVE_DOCTORS[param.field_index]['doctors'][param.doctor_retailer_stockist_index].ACTIVITY_STATUS;
          }


         // console.log(param.name);
         // console.log(param.headq);

      }


    const SaveDoctor= async ()=>{


      //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Report Saving. Please wait...',

        });
        await loading.present();

      // PAYLOAD END


        try{
                //SAVING SECTION
                await store.dispatch('SaveDoctor',param);
                loading.dismiss();

                //Toast START
                const toast = await toastController
                  .create({
                    message: store.state.SaveServerResponse.message,
                    duration: 3000
                  })
                toast.present();
                //Toast END

                closemodal();
        }
        catch (e)
        {
            //param.gps_error = e;
               loading.dismiss()
             //Toast START
              const toast = await toastController
                .create({
                  message: e,
                  duration: 3000
                })
              toast.present();

        }

    }

    const closemodal= async ()=>
    { await modalController.dismiss();}

    return{router,store,param,closemodal,SaveDoctor};
  }

});
</script>