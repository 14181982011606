<template>
  <ion-page>

   <ion-header>
      <ion-toolbar>
        <ion-title>Doctor Entry</ion-title>
        <ion-buttons slot="start">
          <ion-menu-button menu="custom"></ion-menu-button>
        </ion-buttons>
        <img  slot="end" @click="LoadList" src="assets/imgs/reload_small.png"  />
         <!-- <ion-button class="reloadbutton" slot="end"  @click="LoadList">

         </ion-button> -->
      </ion-toolbar>
    </ion-header>

        <ion-item>
            <ion-label fixed>Date </ion-label>
            <ion-label fixed>:{{param.trandate}}</ion-label>
            <ion-button fill="clear"  @click="formating_date('trandate')">Select Date </ion-button>
            <ion-button expand="block" @click="LoadList">View</ion-button>
       </ion-item>


    <!-- Status: {{$store.state.JWT_Token_status}} -->

    <ion-content class="ion-padding">
      <!-- <div padding>{{$store.state.user.tbl_designation_id}} </div> -->
          <!-- <div padding>{{$store.state.user}} </div>
          <div padding>{{$store.state.user.tbl_hierarchy_org_id}}</div> -->
          <!-- <div padding>POULATE DATA : {{$store.state.populate_data.hq_list[0].field_list[0].doctor_list[0]}}</div> -->


                <ion-item-group  v-for="(hqlist,hq_index) in $store.state.populate_data.hq_list" :key="hqlist.id">

                    <ion-item-divider class="ionheader"> <ion-label>{{hq_index+1}}. Hq: {{hqlist.hq_name}} </ion-label> </ion-item-divider>

                        <ion-item-group  v-for="(field_list,field_index) in hqlist.field_list" :key="field_list.id" >

                          <ion-item-divider class="ionfield"> <ion-label>{{field_index+1}}.Field: {{field_list.field_name}} </ion-label> </ion-item-divider>

                                <div     v-for="(doctor_list,doctor_index)  in field_list.doctor_list" :key="doctor_list.id"
                                 @click="()=>DCRdetail(hq_index,field_index,doctor_index,'doctor_list')"  >

                                   <ion-item v-if="doctor_list.color_code==='NOT_OK'" class="lessvisit">
                                   {{doctor_index+1}}.{{doctor_list.name}} ( {{doctor_list.visitdates}} ) -({{doctor_list.lat_long_reg_details}})

                                         <!--<ion-label v-if="doctor_list.color_code==='NOT_OK'" color="danger" >{{doctor_index+1}}.{{doctor_list.name}} ( {{doctor_list.visitdates}} ) </ion-label>
                                         <ion-label v-if="doctor_list.color_code==='NOT_OK'" class="lessvisit">( {{doctor_list.color_code}} )</ion-label> -->

                                        <ion-label v-if="doctor_list.reporting_activity_details === 'REPORTED'" slot="end">
                                        <img src="assets/imgs/correct.png"  />
                                        </ion-label>
                                  </ion-item>

                                  <ion-item v-if="doctor_list.color_code==='OK'" class="goodvisit" >
                                   {{doctor_index+1}}.{{doctor_list.name}} ( {{doctor_list.visitdates}}) -({{doctor_list.lat_long_reg_details}})

                                         <!--<ion-label v-if="doctor_list.color_code==='NOT_OK'" color="danger" >{{doctor_index+1}}.{{doctor_list.name}} ( {{doctor_list.visitdates}} ) </ion-label>
                                         <ion-label v-if="doctor_list.color_code==='NOT_OK'" class="lessvisit">( {{doctor_list.color_code}} )</ion-label> -->

                                        <ion-label v-if="doctor_list.reporting_activity_details === 'REPORTED'" slot="end">
                                        <img src="assets/imgs/correct.png"  />
                                        </ion-label>
                                  </ion-item>

                                </div>

                        </ion-item-group>
                  </ion-item-group>

            <!-- <div padding>  {{$store.state.DCRdetail.DCRdetail_data}} </div> -->
    </ion-content>

  </ion-page>
</template>

<script>



//IonButton,
import {
 IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonItem,
 IonButtons,IonMenuButton,IonButton,
 IonItemGroup,  IonLabel,
 IonItemDivider,loadingController, toastController,modalController,
} from '@ionic/vue';

import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex';

//import {watchEffect} from 'vue'
//import {ref} from 'vue'
import {computed} from 'vue'
import {reactive,onMounted} from 'vue';
import DCRdetailcomponent from '@/components/dcr_section/DCRdetail.vue';
import datepickermodal from '@/components/others/datepickermodal.vue';
import { Geolocation } from '@capacitor/geolocation';
//import { Geofence } from '@ionic-native/geofence';

export default defineComponent({
  components: { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonItem,
  IonButtons,IonMenuButton,IonButton,
   IonItemGroup,IonLabel,IonItemDivider  },

  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

     const param=reactive({
          tbl_hierarchy_org_id:'',
          tbl_designation_id:'',
          data_type:'',
          trandate:'',
          id:'',
          gps_chkpermission_status:'',
          gps_error:'',
          latitude:'0',
          longitude:'0',
          doctor_save_latitude:'0',
          doctor_save_longitude:'0',
          isInside :true,
          radius:100,
          hostname:config.hostname,
          hostconfig: config.config
    });


      var initial_date=  new Date().toLocaleString();  //06/04/2022
      initial_date=initial_date.slice(6, 10)+'-'+initial_date.slice(3, 5)+'-'+initial_date.slice(0, 2);
      param.trandate=initial_date;

    //GEOFENCING RELATED CODE HERE

     const CheckGPS= async ()=>
     {
           try
           {
                const chkpermission = await Geolocation.checkPermissions();
                param.gps_chkpermission_status=chkpermission.coarseLocation;
                console.log('permission '+param.gps_chkpermission_status);
                const json = await  Geolocation.getCurrentPosition({ enableHighAccuracy: true,timeout: 5000});
                param.latitude = json.coords.latitude;
                param.longitude = json.coords.longitude;
                //console.log('lat1123 '+param.latitude+' lon1321 '+param.longitude);
            }
            catch (e)
            {
              param.gps_error = e;
            }
      }

      const formating_date= async (date_picker_name)=>
      {

           const modal = await modalController
            .create({
              component: datepickermodal,
              cssClass: 'my-custom-class',
              componentProps: {
                title: 'Date Pick',
                date_picker_name: date_picker_name
              },
            });

            modal.onDidDismiss().then((data) => {
                getdate(data,date_picker_name);
            });

          return modal.present();
      }

      const getdate= async (dates,date_picker_name)=>
      {
          if(date_picker_name=='trandate')
          {param.trandate=dates.data;}
      }


      const update_visit= async ()=>
      {

      }

      update_visit();

    const view_update = computed(() => {
      //return author.books.length > 0 ? 'Yes' : 'No'
     // update_visit();
      return '';
    })

    const LoadList= async ()=>{

        console.log('trandate '+param.trandate);

        const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',
          duration: 3000, // Maximum duration in milliseconds
        });
        await loading.present();

        param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
        param.tbl_designation_id=store.state.user.tbl_designation_id;
        await store.dispatch('populate_data',param);

        update_visit();
        loading.dismiss();

    }

     const DCRdetail= async (hq_index_save,field_index_save,doctor_retailer_stockist_index_save,data_type_save)=>{

       //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',

        });
        await loading.present();

      store.state.DCRdetail.trandate=param.trandate;
      store.state.DCRdetail.hq_index=hq_index_save;
      store.state.DCRdetail.field_index=field_index_save;
      store.state.DCRdetail.doctor_retailer_stockist_index=doctor_retailer_stockist_index_save;
      store.state.DCRdetail.data_type=data_type_save;
      param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
      param.data_type=data_type_save;
      param.id=store.state.populate_data.hq_list[hq_index_save].field_list[field_index_save].doctor_list[doctor_retailer_stockist_index_save].id;
      param.doctor_save_latitude=store.state.populate_data.hq_list[hq_index_save].field_list[field_index_save].doctor_list[doctor_retailer_stockist_index_save].latitude;
      param.doctor_save_longitude=store.state.populate_data.hq_list[hq_index_save].field_list[field_index_save].doctor_list[doctor_retailer_stockist_index_save].longitude;

      CheckGPS();

        try{

          //var distance = getDistanceFromLatLonInM(param.latitude,param.longitude,param.doctor_save_latitude,param.doctor_save_longitude);
          //console.log('distance '+distance);

          await store.dispatch('checking_geotaging',param);

         if(store.state.geotaging_data.geofence_status)
          {
              await store.dispatch('DCRdetail',param);
              loading.dismiss();

              //LOAD DCR DETAIL MODAL POPUP
              var title="Doctor";
              const modal = await modalController
              .create({
                component: DCRdetailcomponent,
                cssClass: 'my-custom-class',
                componentProps: {
                  title: title,
                },
              });

              modal.onDidDismiss().then((data) => {
                console.log(data);
                // getdate(data,date_picker_name);
              });

            return modal.present();

          }
          else
          {

              loading.dismiss()
              //Toast START
                const toast = await toastController
                  .create({
                    message: store.state.geotaging_data.geofence_distance,
                    duration: 3000
                  })
                toast.present();
          }

         // router.push('/DCRdetail/');
        }
        catch(e)
        {

              loading.dismiss()
              //Toast START
                const toast = await toastController
                  .create({
                    message: e,
                    duration: 3000
                  })
                toast.present();
        }

    }


    // watchEffect(() => {
    //         CheckGPS();
    //     store.dispatch('checking_geotaging',param);
    //     if(store.state.DCRdetail.hq_index>0 && store.state.DCRdetail.field_index>0 && store.state.DCRdetail.doctor_retailer_stockist_index>0)
    //     {
    //        store.state.populate_data.hq_list[store.state.DCRdetail.hq_index].field_list[store.state.DCRdetail.field_index].doctor_list[store.state.DCRdetail.doctor_retailer_stockist_index].current_distance=
    //        store.state.geotaging_data.geofence_distance;
    //     }
    // });

    // const currentLocation = ref(null);
    // const isInsideFence = ref(false);

    // Geofence.initialize().then(() => {
    //   Geofence.addOrUpdate({
    //     id: 'myGeofence',
    //     latitude: 37.7898,
    //     longitude: -122.3942,
    //     radius: 100,
    //     transitionType: Geofence.TransitionType.ENTER,
    //     notification: {
    //       title: 'Entered Fence',
    //       text: 'You have entered the geofence.',
    //     },
    //   });
    // }).catch(err => console.error(err));

    // watchEffect(() => {
    //   Geolocation.getCurrentPosition().then(position => {
    //     currentLocation.value = position.coords.latitude + ',' + position.coords.longitude;
    //     Geofence.getWatched().then(geofences => {
    //       const myGeofence = geofences.find(geofence => geofence.id === 'myGeofence');
    //       if (myGeofence) {
    //         const distance = Geofence.distanceBetween(currentLocation.value, myGeofence.latitude + ',' + myGeofence.longitude);
    //         if (distance <= myGeofence.radius) {
    //           isInsideFence.value = true;
    //         } else {
    //           isInsideFence.value = false;
    //         }
    //       }
    //     });
    //   }).catch(err => console.error(err));
    // });

//     return {
//       currentLocation,
//       isInsideFence,
//     };
//   },
// });





     //GOT THE CODE FROM CHATGPT OF MY LOGIN
    onMounted(() => {
      console.log('Component loaded');
      LoadList();
      // Call any other initialization functions here
    });

    //getDistanceFromLatLonInM,deg2rad,
    return{router,store,param,DCRdetail,LoadList,formating_date,update_visit,view_update,CheckGPS};
  }



});
</script>