<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{currentdate}}</ion-title>

            <ion-buttons slot="primary">
                <ion-button @click="closemodal">X</ion-button>
            </ion-buttons>

    </ion-toolbar>
  </ion-header>

  <ion-content class="ion-padding">

          <!-- {{$store.state.tour_plan[0]['other_plan']}} -->

        <ion-card>
         <ion-item>
              <ion-label>Other Plan (if Any)</ion-label>
                <ion-select v-model="$store.state.tour_plan[0]['other_plan'].other_activity_id">
                  <ion-select-option
                  v-for="other_activity_list in $store.state.user.other_activity_list" :key="other_activity_list.id"
                  :value="other_activity_list.id"  >{{other_activity_list.name}}
                  </ion-select-option>
                </ion-select>

           </ion-item>

         <ion-item>
                <ion-label position="floating">Details  </ion-label>
                <ion-textarea v-model="$store.state.tour_plan[0]['other_plan'].other_activity_details"></ion-textarea>
          </ion-item>

        </ion-card>


              <ion-card v-for="(alllist,list_index)  in $store.state.tour_plan" :key="list_index" >
                  <ion-card-header  v-if="alllist.tbl_designation_id!= 5" class="ion-card-manager" >
                      <img  src="assets/imgs/manager.png" style="float: right; " />
                      <ion-card-title  >{{alllist.emp_name}} </ion-card-title>
                      <ion-card-subtitle color="ion-color-tertiary-shade">({{alllist.hq_name}}) <br><strong>Current Plan as Follows
                        </strong></ion-card-subtitle>
                  </ion-card-header>

                    <ion-card-header  v-if="alllist.tbl_designation_id==5" class="ion-card-mr">
                      <img  src="assets/imgs/manager.png" style="float: right; " />
                      <ion-card-title  >{{alllist.emp_name}} </ion-card-title>
                      <ion-card-subtitle color="ion-color-tertiary-shade">({{alllist.hq_name}}) <br><strong>Current Plan as Follows </strong></ion-card-subtitle>
                  </ion-card-header>


                  <ion-card-content>

                   <h4 v-for="(field_list,field_index) in alllist['view_plan']" :key="field_index"
                   style="padding-top:5px; white-space: pre-wrap;">
                    {{field_list.hierarchy_name}}<span v-if="alllist.tbl_designation_id != 5">
                      ({{field_list.HqEmpNmae}})</span>,
                   </h4>

                    <ion-item  class="ion-card-title" v-if="alllist.tbl_designation_id == 5 && $store.state.hq_desig_id_from_list != 5" >Add/Edit Join Plan with {{alllist.emp_name}}</ion-item>
                    <ion-item  class="ion-card-title" style="white-space: pre-wrap; "
                    v-if="alllist.tbl_designation_id == 5 && $store.state.hq_desig_id_from_list == 5" >Add/Edit  Plan of {{alllist.emp_name}}</ion-item>

                    <ion-list slot="content">
                              <ion-item v-for="(field_list,field_index) in alllist['working_plan']" :key="field_index">
                                <ion-label>{{field_list.hierarchy_name}}  </ion-label>
                                <ion-checkbox slot="end"  v-model="field_list.selected" ></ion-checkbox>
                              </ion-item>
                    </ion-list>

                  </ion-card-content>
            </ion-card>

              <!-- {{$store.state.tour_plan['working_plan'][hq_index]}} -->
              <!-- Selected : {{$store.state.tour_plan_save}} -->
           <ion-button expand="block" @click="SaveTourPlan_updated">Save Plan</ion-button>



  </ion-content>


</template>

<script>


// import { IonContent, IonHeader, IonTitle, IonToolbar ,IonButtons,
// IonButton,IonLabel,IonCheckbox,IonItem,
// IonTextarea, IonSelect,  IonSelectOption,
//  modalController} from '@ionic/vue';


//IonItemGroup,IonItemDivider,
import { IonContent, IonHeader, IonTitle, IonToolbar ,IonButtons,
IonButton, IonItem,IonList, IonLabel,IonCheckbox,IonTextarea, IonSelect,  IonSelectOption,
IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
loadingController,toastController,modalController} from '@ionic/vue';


import { defineComponent } from 'vue';
import config from '@/config.js'
import { useStore  } from 'vuex'
//import {ref} from 'vue'
import {reactive} from 'vue'

export default defineComponent({
  name: 'Modal',
  props: {
    currentdate: { type: String, default: 'Super Modal' },
    hq_id: { type: String  , default: 'Super Modal' },
    currenthq_designation_id: { type: String  , default: 'Super Modal' },

  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar,IonButtons,IonButton, IonItem,IonList,IonTextarea, IonSelect,  IonSelectOption, IonLabel,IonCheckbox,
  IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,},

  setup(props)
  {

        const store = useStore();

        const param=reactive({
            employee_id:'',
            tbl_hierarchy_org_id:'',
            tbl_designation_id:'',
            hq_id:'',
            currentdate:'',
            other_activity_id:'',
            other_activity_details:'',
            tour_plan_data:{},
            hostname:config.hostname,
            hostconfig: config.config
        });



    param.hq_id= JSON.parse(JSON.stringify(props.hq_id));
    param.currentdate= JSON.parse(JSON.stringify(props.currentdate));
    param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
    param.tbl_designation_id=store.state.user.tbl_designation_id;
    param.employee_id=store.state.user.id;




      const SaveTourPlan_updated= async ()=>
      {

              param.other_activity_id=store.state.tour_plan[0]['other_plan'].other_activity_id;
              param.other_activity_details=store.state.tour_plan[0]['other_plan'].other_activity_details;

               console.log('other_activity_id  '+param.other_activity_id);
              console.log('other_activity_details  '+param.other_activity_details);

              //LOADING SECTION
               const loading = await loadingController
                .create({
                cssClass: 'my-custom-class',
                message: 'Saving ..... Please wait...',

                });
                await loading.present();


                //CREATE PAYLOAD
                      var saveindex=0;
                      store.state.tour_plan.forEach((hq_list, hq_index) =>
                      {
                          store.state.tour_plan[hq_index]['working_plan'].forEach((working_list, working_index) =>
                          {


                              if(working_list['selected'])
                              {
                                //param.tour_plan_data[working_index]=working_list;

                                store.state.tour_plan_save[saveindex]=working_list;
                                saveindex=saveindex+1;

                                console.log(working_index+' working_list'+working_list['selected']);
                                console.log('==========================');
                              }
                          })
                    });

                    param.tour_plan_data=store.state.tour_plan_save;
                // PAYLOAD END

                //SAVING DATA

                try{

                       await store.dispatch('SaveTourPlan_updated',param);

                       loading.dismiss();

                        //Toast START
                        const toast = await toastController
                        .create({
                            message: store.state.SaveServerResponse.message,
                            duration: 3000
                        })
                        toast.present();
                        //Toast END

                       closemodal();


                }
                catch (e)
                {

                    loading.dismiss()
                    //Toast START
                    const toast = await toastController
                        .create({
                        message: e,
                        duration: 3000
                        })
                    toast.present();

                }

      }

    const closemodal= async ()=>
    { await modalController.dismiss();}

    return { content: 'Content123654',closemodal,SaveTourPlan_updated, }
  }

});
</script>