<template>

  <ion-page>

      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start" >
            <ion-menu-button menu="custom"></ion-menu-button>
            <ion-back-button ></ion-back-button>
          </ion-buttons>
          <ion-title>Sales Analysis New Format</ion-title>
        </ion-toolbar>
      </ion-header>

     <ion-content>
              <!-- {{$store.state.user.hqlist.hq_id}} -->
              <ion-item>
                <ion-label fixed>Select HQ</ion-label>
                  <ion-select v-model="param.hq_id">
                      <ion-select-option  v-for="hqs in $store.state.user.hqlist.hq_list" :key="hqs.hq_id" :value="hqs.hq_id">
                      {{hqs.hq_name}}
                      </ion-select-option>
                  </ion-select>
              </ion-item>

              <ion-item>
                <ion-label fixed>Month Year</ion-label>
                  <ion-select v-model="param.monthyear">
                      <ion-select-option  v-for="monthyr in $store.state.user.month_year" :key="monthyr.month_year_numeric" :value="monthyr.month_year_numeric">
                      {{monthyr.monthyear}}
                      </ion-select-option>
                  </ion-select>
              </ion-item>


              <ion-button expand="block" @click="LoadDoctorSaleList">Load Doctor</ion-button>

                <!-- {{$store.state.user}} -->
               <!-- {{$store.state.SaveServerResponse}} -->

              <!-- {{$store.state.DoctorSales.list}} -->


             <ion-item  color="danger"><ion-label>ALL DOCTORS </ion-label></ion-item>

              <ion-item-group v-for="(area_list,area_index) in $store.state.DoctorSales.list" :key="area_list.area_id">
                        <ion-item-divider> <ion-label>{{area_index+1}}. Area/Location : {{area_list.area_name}} </ion-label> </ion-item-divider>
                        <ion-item-group  v-for="(doctor_list,doctor_index) in area_list.doctors" :key="doctor_list.id" >

                                <ion-grid >
                                    <ion-row><ion-col size="12" size-sm="12" style="background:#eb445a">{{doctor_index+1}}. {{doctor_list.name}} </ion-col></ion-row>
                                    <ion-row>
                                        <ion-col size="3" size-sm="3" >Week-1</ion-col>
                                        <ion-col size="3" size-sm="3" >Week-2</ion-col>
                                        <ion-col size="3" size-sm="3" >Week-3</ion-col>
                                        <ion-col size="3" size-sm="3" >Actual</ion-col>
                                    </ion-row>
                                     <ion-row>
                                        <ion-col size="3" size-sm="3"  > <ion-input v-model="doctor_list.sale_week1"></ion-input></ion-col>
                                        <ion-col size="3" size-sm="3" > <ion-input v-model="doctor_list.sale_week2"></ion-input></ion-col>
                                        <ion-col size="3" size-sm="3" > <ion-input v-model="doctor_list.sale_week3"></ion-input></ion-col>
                                        <ion-col size="3" size-sm="3" > <ion-input v-model="doctor_list.total_actual_sale"></ion-input></ion-col>
                                    </ion-row>

                                </ion-grid>

                        </ion-item-group>
              </ion-item-group>


              <ion-item  color="danger"><ion-label>ALL RETAILER </ion-label></ion-item>

              <ion-item-group v-for="(area_list,area_index) in $store.state.DoctorSales.list" :key="area_list.area_id">
                        <ion-item-divider> <ion-label>{{area_index+1}}. Area/Location : {{area_list.area_name}} </ion-label> </ion-item-divider>
                        <ion-item-group   v-for="(retailer_list,retailer_index) in area_list.retailer" :key="retailer_list.id" >

                                <ion-grid >
                                    <ion-row><ion-col size="12" size-sm="12" style="background:#eb445a">{{retailer_index+1}}. {{retailer_list.name}} </ion-col></ion-row>
                                    <ion-row>
                                        <ion-col size="3" size-sm="3" >Week-1</ion-col>
                                        <ion-col size="3" size-sm="3" >Week-2</ion-col>
                                        <ion-col size="3" size-sm="3" >Week-3</ion-col>
                                        <ion-col size="3" size-sm="3" >Actual</ion-col>
                                    </ion-row>
                                     <ion-row>
                                        <ion-col size="3" size-sm="3" > <ion-input v-model="retailer_list.sale_week1"></ion-input></ion-col>
                                        <ion-col size="3" size-sm="3" > <ion-input v-model="retailer_list.sale_week2"></ion-input></ion-col>
                                        <ion-col size="3" size-sm="3" > <ion-input v-model="retailer_list.sale_week3"></ion-input></ion-col>
                                        <ion-col size="3" size-sm="3" > <ion-input v-model="retailer_list.total_actual_sale"></ion-input></ion-col>
                                    </ion-row>

                                </ion-grid>

                        </ion-item-group>
              </ion-item-group>


               <!--
              <ion-item  color="danger"><ion-label>ALL RETAILER </ion-label></ion-item>
              <ion-item-group v-for="(area_list,area_index) in $store.state.DoctorSales.list" :key="area_list.area_id">

                    <ion-item-divider> <ion-label>{{area_index+1}}. Area/Location : {{area_list.area_name}} </ion-label> </ion-item-divider>

                        <ion-item-group  v-for="(retailer_list,retailer_index) in area_list.retailer" :key="retailer_list.id"
                        @click="()=>sale_entry(area_index,retailer_index,'retailer')" >


                                <ion-item  color="medium">
                                  <ion-label>{{retailer_index+1}}. {{retailer_list.name}} </ion-label>
                                </ion-item>

                                <ion-item lines="none">
                                  <ion-label>Wk1= {{retailer_list.sale_week1}}</ion-label>
                                  <ion-label>Wk2= {{retailer_list.sale_week2}}</ion-label>
                                  <ion-label>Wk3= {{retailer_list.sale_week3}}</ion-label>

                                </ion-item>

                            <ion-item lines="none"><ion-label>Total Estimated Sale</ion-label><ion-label>{{retailer_list.total_sale}}</ion-label></ion-item>
                            <ion-item lines="none"><ion-label>Total Actual Sale</ion-label><ion-label>{{retailer_list.total_actual_sale}}</ion-label></ion-item>
                            <ion-item lines="none"><ion-label>Sale Difference</ion-label><ion-label>{{retailer_list.sale_diff}}</ion-label></ion-item>
                            <ion-item lines="none"><ion-label>Sale Difference %</ion-label><ion-label>{{retailer_list.sale_diff_percentage}}</ion-label></ion-item>

                        </ion-item-group>
              </ion-item-group>

            -->

                <!-- test : {{$store.state.DoctorSales}} -->

                <!-- <ion-item  color="danger"><ion-label>Total </ion-label></ion-item>

                    <ion-item lines="none">
                      <ion-label>Wk1= {{$store.state.DoctorSales.list_sum.sale_week1}}</ion-label>
                      <ion-label>Wk2= {{$store.state.DoctorSales.list_sum.sale_week2}}</ion-label>
                      <ion-label>Wk3= {{$store.state.DoctorSales.list_sum.sale_week3}}</ion-label>

                    </ion-item>

                      <ion-item lines="none"><ion-label>Total Estimated Sale</ion-label><ion-label>{{$store.state.DoctorSales.list_sum.total_sale}}</ion-label></ion-item>
                      <ion-item lines="none"><ion-label>Total Actual Sale</ion-label><ion-label>{{$store.state.DoctorSales.list_sum.total_actual_sale}}</ion-label></ion-item>
                      <ion-item lines="none"><ion-label>Sale Difference</ion-label><ion-label>{{$store.state.DoctorSales.list_sum.sale_diff}}</ion-label></ion-item>
                      <ion-item lines="none"><ion-label>Sale Difference %</ion-label><ion-label>{{$store.state.DoctorSales.list_sum.sale_diff_percentage}}</ion-label></ion-item>

                -->
             <ion-button expand="block" @click="save_monthly_sale">Save Sale</ion-button>

    </ion-content>

  </ion-page>
</template>

<script>

import {
  IonPage,IonHeader,IonContent,IonToolbar,IonTitle,
  IonItem,IonSelect,IonSelectOption,
  IonButtons,IonMenuButton,
  IonButton,IonItemGroup,  IonLabel, IonItemDivider,
  IonGrid,IonRow,IonCol,IonInput,
  loadingController,
  toastController, }
  from "@ionic/vue";


import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex'
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue'
//import doctor_wise_monthly_sale_entry from '@/components/others/doctor_wise_monthly_sale_entry.vue';


export default defineComponent({

  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonButtons,IonMenuButton,
    IonItem,IonItemGroup,  IonLabel, IonItemDivider,
    IonGrid,IonRow,IonCol,IonInput,
    IonSelect,IonSelectOption,IonButton,
  },


  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

    const param=reactive({

      monthyear:'',
      hq_id:'',
      subtype:'DOC_LIST_SALE',
      save_data:{},
      hostname:config.hostname,
      hostconfig: config.config
    });

     param.hq_id=store.state.user.tbl_hierarchy_org_id;

     const LoadDoctorSaleList= async ()=>{

        console.log(param.monthyear);
        //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Loading ..... Please wait...',

        });
        await loading.present();


      //LOADING FUNCTION
      await store.dispatch('LoadDoctorSaleList',param);
      loading.dismiss();

      //  //Toast START
      const toast = await toastController
        .create({
          message: 'Doctor Loaded..',
          duration: 1000
        })
      toast.present();
      // //Toast END

    }
    //LOAD TOUR PLAN END


    //save

      const save_monthly_sale= async ()=>
      {
                //LOADING SECTION
            const loading = await loadingController
                .create({
                cssClass: 'my-custom-class',
                message: 'Saving ..... Please wait...',

                });
                await loading.present();

                //CREATE PAYLOAD
                param.save_data=store.state.DoctorSales.list;

                console.log('param.save_data :'+param.save_data);
                //PAYLOAD END

                //SAVING DATA

                try{
                       //console.log(param.hq_id + ' - '+ ' - '+param.monthyear+ ' - '+param.sale_week1);

                       var displaymsg='';

                        if(param.hq_id==store.state.user.tbl_hierarchy_org_id)
                        {
                          //await store.dispatch('save_doctor_sale',param);
                          await store.dispatch('save_monthly_sale',param);
                          //save_monthly_sale
                          displaymsg=store.state.SaveServerResponse.server_msg;
                         // console.log('param :'+param);
                        }
                        else
                        {
                          displaymsg='Only MR can Enter!';
                        }

                        loading.dismiss();

                        //Toast START
                        const toast = await toastController
                        .create({
                            message: displaymsg,
                            duration: 3000
                        })
                        toast.present();
                        //Toast END


                }
                catch (e)
                {

                    loading.dismiss()
                    //Toast START
                    const toast = await toastController
                        .create({
                        message: e,
                        duration: 3000
                        })
                    toast.present();

                }

      }


    return{router,store,param,LoadDoctorSaleList,save_monthly_sale};

  }
});
</script>