<template>
  <ion-app>
    <!-- <ion-router-outlet /> -->

    <!-- MAIN MENU IMPLEMENT -->
    <ion-menu side="start" menu-id="custom" class="my-custom-menu" content-id="main" :dismiss-on-select="true">
      <ion-header>
        <ion-toolbar >
           <ion-item >
            <ion-avatar slot="start">
              <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y">
            </ion-avatar>
            <ion-label>{{$store.state.user.name}}</ion-label>
          </ion-item>
          <!-- <ion-title>Custom Menu</ion-title> -->
        </ion-toolbar>
      </ion-header>
      <ion-content>

        <!-- AITIANA SECTION -->

         <!--
           <ion-item-group >
          <ion-item-divider style="text-align: center;background: #399F8B;color:black;"> <ion-label>Report</ion-label> </ion-item-divider>
            <ion-list >
              <ion-item  @click="() =>openPage('/bus_doc_expiry/')" class="grid_body"><ion-icon :icon="home"></ion-icon> Bus Doc Expiry</ion-item>
               <ion-item  @click="() =>openPage('/truck_doc_wise_previous_list/')" class="grid_body"><ion-icon :icon="home"></ion-icon> Bus Doc Wise Previous List</ion-item>
             </ion-list>
         </ion-item-group>
         -->




        <!-- PHARMA SECTION -->



        <ion-item  @click="() =>openPage('/HomePage/')" class="grid_body"><ion-icon :icon="home"></ion-icon> Home</ion-item>

        <ion-item-group >
          <ion-item-divider style="text-align: center;background: #399F8B;color:black;"> <ion-label>Main Section</ion-label> </ion-item-divider>
            <ion-list >
              <ion-item @click="() =>openPage('/TourPlanExperiment/')" class="grid_body"><ion-icon :icon="menu"></ion-icon>Tour Plan</ion-item>
              <ion-item @click="() =>openPage('/dailyPlanDetail/')" class="grid_body"><ion-icon :icon="menu"></ion-icon>Daily Plan</ion-item>
              <ion-item @click="() =>openPage('/DCREntry/Doctor/')" class="grid_body"><ion-icon :icon="construct"></ion-icon>DCR Entry</ion-item>
              <!-- FOR SAVIOUR ONLY -->
              <ion-item @click="() =>openPage('/doctor_wise_monthly_sale/')" class="grid_body"><ion-icon :icon="menu"></ion-icon>Sales Analysis</ion-item>
            </ion-list>
        </ion-item-group>

         <ion-item-group >
          <ion-item-divider style="text-align: center;background: #399F8B;color:black;"> <ion-label>Master Section</ion-label> </ion-item-divider>
            <ion-list>
              <ion-item @click="() =>openPage('/location_list/')" class="grid_body"><ion-icon :icon="menu"></ion-icon>Location Add/Edit</ion-item>
              <ion-item @click="() =>openPage('/Doctor_master/Doctor_master_active/')" class="grid_body"><ion-icon :icon="construct"></ion-icon>Doctor Add/Edit</ion-item>
              <ion-item @click="() =>openPage('/Retailer_master/Retailer_master_active/')" class="grid_body"><ion-icon :icon="construct"></ion-icon>Retailer Add/Edit</ion-item>
            </ion-list>
        </ion-item-group>


          <ion-item-group v-if="$store.state.user.tbl_designation_id!=5">
          <ion-item-divider style="text-align: center;background: #399F8B;color:black;"> <ion-label>Approval Section</ion-label> </ion-item-divider>
            <ion-list>

              <ion-item @click="() =>openPage('/Doctor_Approval/')" class="grid_body"><ion-icon :icon="construct"></ion-icon>Doctor Approval</ion-item>
              <ion-item @click="() =>openPage('/Location_Approval/')" class="grid_body"><ion-icon :icon="menu"></ion-icon>Location Approval</ion-item>
              <ion-item @click="() =>openPage('/Retailer_Approval/')" class="grid_body"><ion-icon :icon="construct"></ion-icon>Retailer Approval</ion-item>
              <ion-item @click="() =>openPage('/Monthly_tour_plan_Approval/')" class="grid_body"><ion-icon :icon="construct"></ion-icon>Monthly Tour Plan</ion-item>
              <ion-item @click="() =>openPage('/Daily_tour_plan_Approval/')" class="grid_body"><ion-icon :icon="construct"></ion-icon>Daily Plan</ion-item>

            </ion-list>
        </ion-item-group>


         <ion-item-group >
          <ion-item-divider style="text-align: center;background: #399F8B;color:black;"> <ion-label>Report Section</ion-label> </ion-item-divider>
            <ion-list >
              <ion-item @click="() =>openPage('/DCR_Report/')" class="grid_body"><ion-icon :icon="pulse" ></ion-icon>Daily DCR Detail</ion-item>
              <ion-item @click="() =>openPage('/monthly_doctor_visit_report/')" class="grid_body"><ion-icon :icon="pulse" ></ion-icon>Monthly DCR Summ</ion-item>
              <ion-item @click="() =>openPage('/quaterly_doctor_visit_report/')" class="grid_body"><ion-icon :icon="pulse" ></ion-icon>Quaterly DCR Summ</ion-item>
              <ion-item @click="() =>openPage('/Miss_call_Report/')" class="grid_body"><ion-icon :icon="pulse"></ion-icon>Missed Call Report</ion-item>
              <ion-item @click="() =>openPage('/Doctor_list/')" class="grid_body"><ion-icon :icon="menu"></ion-icon>HQ Wise Doctor List</ion-item>
              <ion-item @click="() =>openPage('/Retailer_list/')" class="grid_body"><ion-icon :icon="menu"></ion-icon>HQ Wise Retailer List</ion-item>
              <ion-item @click="() =>openPage('/view_expense/')" class="grid_body"><ion-icon :icon="menu"></ion-icon>View Expense</ion-item>
              <ion-item @click="() =>openPage('/product_summary/')" class="grid_body"><ion-icon :icon="menu"></ion-icon>Product Display</ion-item>
            </ion-list>
         </ion-item-group>



         <ion-item @click="() =>openPage('/')" class="grid_body"><ion-icon :icon="menu"></ion-icon> Logout</ion-item>

      </ion-content>
  </ion-menu>






    <!-- <ion-item @click="() =>openPage('/gmail_login_test/')" ><ion-icon :icon="menu"></ion-icon>Gmail Login Test</ion-item> -->
`       <!-- PROPOSAL SECTION -->
    <!-- <ion-item @click="() =>openPage('/proposal_entry/')" ><ion-icon :icon="menu"></ion-icon>Proposal Entry</ion-item>
    <ion-item @click="() =>openPage('/doctor_wise_product_wise_sale_entry/')" ><ion-icon :icon="menu"></ion-icon>Doctor Wise Return</ion-item> -->
      <!-- PROPOSAL SECTION END -->
    <!-- <ion-item @click="() =>openPage('/ActivityEntry/')"><ion-icon :icon="menu"></ion-icon> Code Example</ion-item> -->
    <!-- <ion-item @click="() =>openPage('/AutoComplete/')"><ion-icon :icon="menu"></ion-icon>Auto complete</ion-item> -->
      <!-- <ion-item @click="() =>openPage('/TourPlan/')"><ion-icon :icon="alarm"></ion-icon> Tour Plan Old</ion-item> -->
      <!-- <ion-item @click="() =>openPage('/Doctor_wise_sale/')" ><ion-icon :icon="menu"></ion-icon> Log Out</ion-item> -->
    <!-- <ion-item ><ion-icon :icon="receipt"></ion-icon> Expense Create</ion-item>
    <ion-item @click="() =>openPage('/ActivityEntry/')"><ion-icon :icon="menu"></ion-icon> Code Example</ion-item> -->



    <ion-router-outlet  id="main"></ion-router-outlet>
  </ion-app>
</template>

<style>
.my-custom-menu {
  --width: 50%;
}
</style>

<script>
import {
  IonApp,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonToolbar,
  menuController,
  IonAvatar,
  IonItemGroup,
  IonLabel,
  IonItemDivider

} from '@ionic/vue';
import { defineComponent } from 'vue';
import { home,construct,alarm,menu,pulse,receipt } from 'ionicons/icons';
import { useRouter } from 'vue-router';


export default defineComponent({
  name: 'App',
  components: {
      IonApp,
      IonContent,
      IonHeader,
      IonItem,
      IonList,
      IonMenu,
      IonRouterOutlet,
      IonToolbar,
      IonAvatar,
      IonItemGroup,
      IonLabel,
      IonItemDivider

  },
  setup()
  {
        const router = useRouter();

        const openFirst=async ()=>
        {
          menuController.enable(true, 'first');
          menuController.open('first');
        };

        const openEnd=async ()=>
        {
          menuController.open('end');
        };

        const openCustom=async ()=>
        {
          menuController.enable(true, 'custom');
          menuController.open('custom');
        };

        const openPage=async(pagename)=>
        {
           menuController.close();
           router.push(pagename);
          // menuController.enable(false, 'first');


        }

      return {router,menuController,openCustom,openEnd,openFirst,home,construct,alarm,menu,pulse,receipt,openPage}
  }

});
</script>