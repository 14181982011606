<template>

<ion-page>

     <ion-header>
        <ion-toolbar>
            <ion-title>Sale Entry -{{param.doctor_name}}</ion-title>
            <ion-buttons slot="start">
            <!-- <ion-menu-button menu="custom"></ion-menu-button> -->
            </ion-buttons>
            <ion-button @click="closemodal" slot="end">X</ion-button>
        </ion-toolbar>
    </ion-header>

        <ion-content class="ion-padding">


            <!-- {{$store.state.DoctorSales.list[$store.state.DCRdetail.field_index]['doctors'][$store.state.DCRdetail.doctor_retailer_stockist_index]['products']}} -->

              <!-- <ion-item>
                <ion-label  >Week 1 {{$store.state.DCRdetail.field_index}}</ion-label>
                <ion-input v-model="param.sale_week1" @change="calculate_sale"></ion-input>
              </ion-item> -->


               <ion-item v-for="(product_list,product_index)
               in $store.state.DoctorSales.list[$store.state.DCRdetail.field_index]['doctors'][$store.state.DCRdetail.doctor_retailer_stockist_index]['products']"
               :key="product_list.product_id">
                            <ion-label position="floating">{{product_index+1}}-{{product_list.productname}}</ion-label>
                            <ion-input v-model="product_list.qnty" ></ion-input>

                  </ion-item>

                <ion-button expand="block" @click="save_doctor_sale">Save Sale</ion-button>
        </ion-content>

</ion-page>

</template>

<script>
import {IonPage, IonContent, IonHeader, IonTitle, IonToolbar ,
IonButtons,
IonButton,IonLabel,IonItem,IonInput,
loadingController,toastController,modalController
} from '@ionic/vue';

import config from '@/config.js'
import { defineComponent } from 'vue';
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex'
//import {ref} from 'vue'
import {reactive} from 'vue'


export default defineComponent({
  name: 'Modal',
  props: {
    doctor_index: { type: String, default: 'Super Modal' },
    area_index:  { type: String, default: 'Super Modal' },
    monthyear:  { type: String, default: 'Super Modal' },
    hq_id:      { type: Number, default: 0 },
    TYPE_ENTRY: { type: String, default: 'DOCTOR' },
  },
  components: {IonPage, IonContent, IonHeader, IonTitle, IonToolbar,
  IonButtons,
  IonButton,IonLabel,IonInput,
  IonItem,},
  setup(props,context)
  {
        const router=useRouter();
        const store = useStore();


        const param=reactive({
            doctor_name:'',
            hq_id:'',
            employee_id:'',
            doctor_retailer_id:'',
            TYPE_ENTRY:'',
            monthyear:'',
            area_index:0,
            doctor_index:0,
            products:'0=0',
            sale_week2:'',
            sale_week3:'',
            sale_week4:'',
            total_sale:'',
            total_actual_sale:'',
            sale_diff:'',
            sale_diff_percentage:'',
            hostname:config.hostname,
            hostconfig: config.config
        });

        if(props.TYPE_ENTRY=='doctors')
        {

            param.doctor_name=store.state.DoctorSales.list[props.area_index].doctors[props.doctor_index].name;
            param.doctor_retailer_id=store.state.DoctorSales.list[props.area_index].doctors[props.doctor_index].id;
            param.monthyear= JSON.parse(JSON.stringify(props.monthyear));
            param.TYPE_ENTRY='DOCTOR';

        }

        const calculate_sale = (event) =>
        {
              context.emit("calculate_sale", event.target.value);

               //console.log(event);

              param.total_sale=Number(param.sale_week1)+Number(param.sale_week2)+Number(param.sale_week3)+Number(param.sale_week4);
              param.sale_diff=Number(param.total_actual_sale)-Number(param.total_sale);

              if(Number(param.total_actual_sale)>0)
              {
                  param.sale_diff_percentage=(param.sale_diff/Number(param.total_actual_sale))*100;
              }

         }



      const save_doctor_sale= async ()=>
      {


      //store.state.DoctorSales.list[store.state.DCRdetail.field_index]['doctors'][store.state.DCRdetail.doctor_retailer_stockist_index]['products'][0]
      //console.log(store.state.DoctorSales.list[store.state.DCRdetail.field_index]['doctors'][store.state.DCRdetail.doctor_retailer_stockist_index]['products'][0]);

      store.state.DoctorSales.list[store.state.DCRdetail.field_index]['doctors'][store.state.DCRdetail.doctor_retailer_stockist_index]['products'].forEach((value, index) =>
      {
          if(store.state.DoctorSales.list[store.state.DCRdetail.field_index]['doctors'][store.state.DCRdetail.doctor_retailer_stockist_index]['products'][index].qnty>0)
          {
            param.products=param.products+'|'+
            store.state.DoctorSales.list[store.state.DCRdetail.field_index]['doctors'][store.state.DCRdetail.doctor_retailer_stockist_index]['products'][index].product_id+
            '='+Number(store.state.DoctorSales.list[store.state.DCRdetail.field_index]['doctors'][store.state.DCRdetail.doctor_retailer_stockist_index]['products'][index].qnty);
          }

      });



             console.log(param.products);




               //LOADING SECTION
               const loading = await loadingController
                .create({
                cssClass: 'my-custom-class',
                message: 'Loading ..... Please wait...',

                });
                await loading.present();

                //CREATE PAYLOAD
                param.hq_id=JSON.parse(JSON.stringify(props.hq_id));
                param.employee_id=store.state.user.id;
                // PAYLOAD END

                //SAVING DATA

                try{
                       console.log(param.hq_id + ' - '+ ' - '+param.employee_id+ ' - '+param.doctor_retailer_id+
                       ' - '+param.TYPE_ENTRY+ ' - '+param.monthyear+ ' - '+param.products);
                       var displaymsg='';

                        if(param.hq_id==store.state.user.tbl_hierarchy_org_id)
                        {
                          await store.dispatch('doctor_wise_product_wise_sale_entry_SAVE',param);
                          displaymsg=store.state.SaveServerResponse.server_msg;
                         // console.log('param :'+param);
                        }
                        else
                        {
                          displaymsg='Only MR can Enter!';
                        }

                        loading.dismiss();

                        //Toast START
                        const toast = await toastController
                        .create({
                            message: displaymsg,
                            duration: 3000
                        })
                        toast.present();
                        //Toast END

                        closemodal();

                }
                catch (e)
                {

                    loading.dismiss()
                    //Toast START
                    const toast = await toastController
                        .create({
                        message: e,
                        duration: 3000
                        })
                    toast.present();

                }



      }

    const closemodal= async ()=>
    { await modalController.dismiss();}


    return { content: 'Content123654',router,param,save_doctor_sale,closemodal,calculate_sale }

  }

});
</script>