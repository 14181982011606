import { createStore } from "vuex";
import axios from 'axios';
import jwtInterceptor from '@/jwtInterceptor';


export default createStore({

    plugins: [jwtInterceptor],

    state : {

         JWT_Token:'',
         JWT_Token_status : '',
      //   header_config:'',

        tbl_hierarchy_org_id:'',
        hq_id : 0,
        monthyear : '',
        REPORT_TYPE : '',
        REDIRECT_FROM_PAGE : '',
        username : '',
        name : 'Ashoke Das123',
        Address:'Baguihati',
        mobno :'9804152658',
        fromdate : '',
        toate : '',
        trandate : '',
        hq_desig_id_from_list : 0,
        user:{},
        general_purpose_indx :0,
        general_purpose_indx2 :0,
        general_purpose_image:'',
        populate_data:{},
        geotaging_data:{},
        DoctorList:
        {
            tbl_hierarchy_org_id:'',
            DOCTORS:{},
            RETAILERS:{
               RETAILERS:{}
            }
        },
        DCRdetail:
        {
            name:'',
            doctor_retailer_id:'',
            hq_index:'',
            field_index:'',
            doctor_retailer_stockist_index:'',
            data_type:'',
            trandate:'',
            DCRdetail_data:{}
        },
        SaveServerResponse:{},
        SaveServerResponse2:{},
        DoctorSales:
        {
         list:{},
         list_sum:{}
        },
        tour_plan:{},
        tour_plan_save:{},
        dailytour_plan:{
         other_plan:{
            other_activity_id:0,
            other_activity_details:''
         },
         view_plan:{},
         working_plan:{}

        },
        Report_DCR:
        {
            DCR_REPORT:{},
            RETAILER_DCR_LIST:{},
            STOCKIST_DCR_LIST:{},
            OTHER_REPORT:{},

        },

    },
    actions:{

      //AITIANA SECTION
      async  login_aitiana(context,payload)
      {
         const data_link=payload.hostname+"employee_login/";
         console.log('data_link '+data_link);
         let data_save = {'userid': payload.username,'PWD': payload.password};
         var response = await  axios.post(data_link,data_save,payload.hostconfig);
         context.commit('login_aitiana',response.data);
      },
      async  aitiana_load_doc_expiry_list(context,payload)
      {
         const data_link=payload.hostname+"bus_document_expiry/";
         console.log('data_link '+data_link);
         let data_save = {'vno': payload.vno,'report_name': payload.report_name};
         var response = await  axios.post(data_link,data_save,payload.hostconfig);
         context.commit('aitiana_load_doc_expiry_list',response.data);
      },
      async  aitiana_load_truck_doc_expiry_details(context,payload)
      {
         const data_link=payload.hostname+"bus_document_expiry/";
         console.log('data_link '+data_link);
         let data_save = {'vno': payload.vno,'doc_id': payload.doc_id,'report_name': payload.report_name};
         var response = await  axios.post(data_link,data_save,payload.hostconfig);
         context.commit('aitiana_load_truck_doc_expiry_details',response.data);
      },






      //AITIANA SECTION END

      // LOGIN ACTION
      async  login(context,payload)
        {
           const data_link=payload.hostname+"employee_login/";
           console.log('data_link '+data_link);
           let data_save = {'userid': payload.username,'PWD': payload.password};
           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('login',response.data);
        },

        async  checkjwt(context,payload)
        {

            //PROBLEM IN Authorization HEADER

               // const data_link=payload.hostname+"sample_post_api_with_jwt/";
               // //console.log('data_link '+data_link);
               // let data_save = {'userid': payload.username,'PWD': payload.password};
               // //var response = await  axios.post(data_link,data_save,payload.hostconfig);
               // var response = await  axios.post(data_link,data_save,{
               //    headers: {
               //    //'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;',
               //    'Content-Type': 'application/json',
               //    'Authorization': 'Bearer ' + payload.JWT_Token // Set the authorization header with JWT token
               //    }
               // });
               // //console.log('payload.JWT_Token '+payload.JWT_Token);
               // context.commit('sample_post_api_with_jwt',response.data);

             //PROBLEM IN Authorization HEADER


            const data_link=payload.hostname+"checkjwt/";
            console.log('data_link '+data_link);
            let data_save = {'tbl_hierarchy_org_id': payload.tbl_hierarchy_org_id,'JWT_Token': payload.JWT_Token};
            var response = await  axios.post(data_link,data_save,payload.hostconfig);
            context.commit('checkjwt',response.data);

        },


        // DCR ENTRY SECTION
        async  populate_data(context,payload)
        {
           const data_link=payload.hostname+"activity_data/populate_data/";
           let data_save = {'trandate':payload.trandate,'tbl_hierarchy_org_id':payload.tbl_hierarchy_org_id,'login_key':123}; //'trandate':payload.trandate,
           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('populate_data',response.data);
        },
        async  checking_geotaging(context,payload)
        {
           const data_link=payload.hostname+"activity_data/checking_geotaging/";
           let data_save = {'data_type':payload.data_type,'tbl_hierarchy_org_id':payload.tbl_hierarchy_org_id,
           'trandate':payload.trandate,'id':payload.id,'latitude':payload.latitude,'longitude':payload.longitude,'login_key':123};
           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('checking_geotaging',response.data);
        },
        async  DCRdetail(context,payload)
        {
           const data_link=payload.hostname+"activity_data/populate_sample_gift/";
           let data_save = {'data_type':payload.data_type,'tbl_hierarchy_org_id':payload.tbl_hierarchy_org_id,
           'trandate':payload.trandate,'id':payload.id,'login_key':123};
           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('DCRdetail',response.data);
        },
        async  SaveDCR(context,payload)
        {
           const data_link=payload.hostname+"activity_data/SAVE/";

           let data_save = {'trandate':payload.trandate,'data_type':payload.data_type,'latitude':payload.latitude,'longitude':payload.longitude,'login_key':123,
           'id':payload.id,'samplevalues':payload.samplevalues,'promotevalues':payload.promotevalues,'giftvalues':payload.giftvalues,'work_with_id':payload.work_with_id
           ,'tbl_hierarchy_org_id':payload.tbl_hierarchy_org_id,'hq_id':payload.hq_id,'field_id':payload.field_id,'visit_details':payload.visit_details};

           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('SaveDCR',response.data);
        },
        async  save_other_activity(context,payload)
        {
           const data_link=payload.hostname+"other_activity/";

           let data_save = {'subtype':'SAVE_DATA',
           'id':payload.other_activity_id,
           'tbl_employee_mstr_id':payload.tbl_employee_mstr_id,
           'tran_date':payload.tran_date,
           'tran_type':payload.tran_type,
           'tran_desc':payload.tran_desc,
           'other_expense':payload.other_expense};

           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('save_other_activity',response.data);
        },
        async  dcr_today(context,payload)
        {
         const data_link=payload.hostname+"activity_data/DCR_TODAY/";
           let data_save = {'tran_type':'DCR_TODAY','login_key':123,'tbl_hierarchy_org_id':payload.tbl_hierarchy_org_id};
           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('dcr_today',response.data);
        },
        // DCR ENTRY SECTION END

        //TOUR PLAN RELATED FUNCTIONS START
         //NEW SEGMENT
        async  tour_plan_summary(context,payload)
        {
           const data_link=payload.hostname+"tour_plan/";

         //   let data_save = {'tran_type':'TOUR_PLAN_SUMMARY',
         //   'fromdate':payload.fromdate,'todate':payload.todate,
         //   'login_key':123,'tbl_hierarchy_org_id':payload.hq_id,
         //   'hq_id_for_tp':payload.hq_id};

           let data_save = {'tran_type':'TOUR_PLAN_SUMMARY',
           'monthyear':payload.monthyear,'login_key':123,'tbl_hierarchy_org_id':payload.hq_id,
           'hq_id_for_tp':payload.hq_id};

           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('tour_plan_summary',response.data);
        },
        async  display_tour_plan_updated(context,payload)
        {
           const data_link=payload.hostname+"tour_plan/";

           let data_save = {'tran_type':'DISPLAY_TOUR_PLAN_UPDATED-1','login_key':123,
           'currentdate':payload.currentdate,'tbl_hierarchy_org_id':payload.hq_id};

            console.log('data_link '+data_link+'tran_type :DISPLAY_TOUR_PLAN_UPDATED-1'+' payload.currentdate '+payload.currentdate+' tbl_hierarchy_org_id '+payload.hq_id);

           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('display_tour_plan_updated',response.data);
        },
        async  SaveTourPlan_updated(context,payload)
        {
           const data_link=payload.hostname+"tour_plan/";
           var data = JSON.stringify(payload.tour_plan_data);

           let data_save =
           {
              'tran_type':'SaveTourPlan_updated','login_key':123,'raw_data':data,
              'employee_id':payload.employee_id,'tbl_hierarchy_org_id':payload.tbl_hierarchy_org_id,
              'tbl_designation_id':payload.tbl_designation_id,
              'hq_id':payload.hq_id,
              'currentdate':payload.currentdate,
              'other_activity_id':payload.other_activity_id,
              'other_activity_details':payload.other_activity_details
           };

           console.log('data :'+data_save);

           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('SaveTourPlan_updated',response.data);
        },

        async  display_daily_plan(context,payload)
        {
           const data_link=payload.hostname+"tour_plan/";
           let data_save = {'tran_type':'DISPLAY_DAILY_PLAN','login_key':123,
           'currentdate':payload.currentdate,'tbl_hierarchy_org_id':payload.hq_id};

           console.log('currentdate '+payload.currentdate+' tbl_hierarchy_org_id '+payload.hq_id);

           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('display_daily_plan',response.data);
        },
        async  SavedailyPlan(context,payload)
        {
           const data_link=payload.hostname+"tour_plan/";
           var data = JSON.stringify(payload.tour_plan_data);
           let data_save =
           {
              'tran_type':'SAVE_DAILY_PLAN','login_key':123,'raw_data':data,
              'employee_id':payload.employee_id,'tbl_hierarchy_org_id':payload.tbl_hierarchy_org_id,
              'tbl_designation_id':payload.tbl_designation_id,
              'hq_id':payload.hq_id,
              'currentdate':payload.currentdate,
              'other_activity_id':payload.other_activity_id,
              'other_activity_details':payload.other_activity_details
           };
           console.log('data :'+data_save);
           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('SavedailyPlan',response.data);
        },


        //NEW SEGMENT END
        //TOUR PLAN RELATED FUNCTIONS END

        //TOUR EXPENSE RELATED FUNCTIONS
      //   async  display_tour_plan(context,payload)
      //   {
      //      const data_link=payload.hostname+"tour_plan/";

      //      let data_save = {'tran_type':'DISPLAY_TOUR_PLAN','fromdate':payload.fromdate,
      //      'todate':payload.todate,'login_key':123,'tbl_hierarchy_org_id':payload.tbl_hierarchy_org_id,
      //      'hq_id_for_tp':payload.hq_id};

      //      var response = await  axios.post(data_link,data_save,payload.hostconfig);
      //      context.commit('display_tour_plan',response.data);
      //   },

      //   async  SaveTourPlan(context,payload)
      //   {
      //      const data_link=payload.hostname+"tour_plan/";
      //      var data = JSON.stringify(payload.tour_plan_data);
      //      let data_save = {'tran_type':'SAVE','login_key':123,'raw_data':data};

      //      var response = await  axios.post(data_link,data_save,payload.hostconfig);
      //      context.commit('SaveTourPlan',response.data);
      //   },
         //TOUR PLAN RELATED FUNCTIONS END



       //DOCTOR WISE MONTHLY SALE ENTRY FOR SAVIOUR ONLY
       async  save_monthly_sale(context,payload)
       {
          const data_link=payload.hostname+"doctor_database/";
          let data_save =
          {
               'subtype':'DOC_SALE_SAVE',
               'hq_id':payload.hq_id,
               'monthyear':payload.monthyear,
               'save_data':payload.save_data,

         };
          var response = await  axios.post(data_link,data_save,payload.hostconfig);
          context.commit('save_monthly_sale',response.data);
          console.log('response.data '+response.data);
       },


        async  save_doctor_sale(context,payload)
        {
           const data_link=payload.hostname+"doctor_database/";

           let data_save =
           {
                'subtype':'DOC_SALE_SAVE',
                'hq_id':payload.hq_id,
                'employee_id':payload.employee_id,
                'doctor_retailer_id':payload.doctor_retailer_id,
                'TYPE_ENTRY':payload.TYPE_ENTRY,
                'monthyear':payload.monthyear,
                'sale_week1':payload.sale_week1,
                'sale_week2':payload.sale_week2,
                'sale_week3':payload.sale_week3,
                'sale_week4':payload.sale_week4,
                'total_sale':payload.total_sale,
                'total_actual_sale':payload.total_actual_sale,
                'sale_diff':payload.sale_diff,
                'sale_diff_percentage':payload.sale_diff_percentage
          };

           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('save_doctor_sale',response.data);

           console.log('response.data '+response.data);
        },
        async  doctor_wise_product_wise_sale_entry_SAVE(context,payload)
        {

            const data_link=payload.hostname+"doctor_database/";
           let data_save =
           {
                'subtype':'doctor_wise_product_wise_sale_entry_SAVE',
                'hq_id':payload.hq_id,
                'employee_id':payload.employee_id,
                'doctor_retailer_id':payload.doctor_retailer_id,
                'TYPE_ENTRY':payload.TYPE_ENTRY,
                'monthyear':payload.monthyear ,
                'products':payload.products
          };

           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('doctor_wise_product_wise_sale_entry_SAVE',response.data);
           console.log('response.data '+response.data);

        },
        //DOCTOR WISE MONTHLY SALE ENTRY FOR SAVIOUR ONLY

        async  proposalDoctors(context,payload)
        {
           const data_link=payload.hostname+"proposal_section/";
           let data_save = {'subtype':payload.subtype,'hq_id':payload.hq_id	};
           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('proposalDoctors',response.data);
        },
        async  save_proposal(context,payload)
        {
           const data_link=payload.hostname+"proposal_section/";
           let data_save = {'subtype':'SAVE_DATA',
           'id':payload.other_activity_id,
           'tbl_employee_mstr_id':payload.tbl_employee_mstr_id,
           'tbl_hierarchy_org_id':payload.tbl_hierarchy_org_id,
           'doctor_retailer_id':payload.doctor_retailer_id,
           'tran_date':payload.tran_date,
           'tran_type':payload.tran_type,
           'tran_desc':payload.tran_desc,
           'proposal_amt':payload.other_expense};
           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('save_proposal',response.data);
        },

        //REPORT SECTION

            //ALL ARE MONTHLY REPORT CATEGORY
            async  team_report(context,payload) //HOME PAGE REPORT
            {
               const data_link=payload.hostname+"reports/";
               let data_save = {'subtype':'TEAM_REPORT','hq_id':payload.hq_id,'monthyear':payload.monthyear	};
               var response = await  axios.post(data_link,data_save,payload.hostconfig);
               context.commit('team_report',response.data);
            },
            async  team_report_detail(context,payload) //HOME PAGE REPORT
            {
               const data_link=payload.hostname+"reports/";
               let data_save = {'subtype':payload.REPORT_TYPE,'hq_id':payload.hq_id,'monthyear':payload.monthyear	};
               // console.log('subtype:'+payload.REPORT_TYPE+' hq_id '+payload.hq_id+' monthyear '+payload.monthyear);
               var response = await  axios.post(data_link,data_save,payload.hostconfig);
               context.commit('team_report_detail',response.data);
            },
            async  LoadDoctorSaleList(context,payload)
            {
               const data_link=payload.hostname+"doctor_database/";
               let data_save = {'subtype':payload.subtype,'hq_id':payload.hq_id,'monthyear':payload.monthyear};
               var response = await  axios.post(data_link,data_save,payload.hostconfig);
               context.commit('LoadDoctorSaleList',response.data);
            },
            async  doctor_wise_product_wise_sale_entry(context,payload)
            {
               const data_link=payload.hostname+"doctor_database/";
               let data_save = {'subtype':payload.subtype,'hq_id':payload.hq_id,'monthyear':payload.monthyear};
               var response = await  axios.post(data_link,data_save,payload.hostconfig);
               context.commit('doctor_wise_product_wise_sale_entry',response.data);
            },
            //ALL ARE MONTHLY REPORT CATEGORY

           //ALL ARE PERIODICAL REPORT CATEGORY
            async  Load_Expense(context,payload)  //TOUR EXPENSE RELATED FUNCTIONS
            {
               const data_link=payload.hostname+"tour_plan/";

               let data_save = {'tran_type':'LOAD_EXPENSE','fromdate':payload.fromdate,
               'todate':payload.todate,'login_key':123,'tbl_hierarchy_org_id':payload.hq_id,
               'hq_id_for_tp':payload.hq_id};
               var response = await  axios.post(data_link,data_save,payload.hostconfig);
               context.commit('Load_Expense',response.data);
            },


            //ALL ARE PERIODICAL REPORT CATEGORY

            //ALL OTHER REPORT CATEGORY


         //REPORT SECTION






       //ALL OK REPORTS ARE HERE

        async  LoadDCR(context,payload)
        {
           const data_link=payload.hostname+"reports/";
           //LOAD DOCTOR DCR
           let data_save = {'subtype':'DCR_REPORT','fromdate':payload.fromdate,'todate':payload.todate,'hq_id':payload.hq_id	};
           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('DCR_REPORT',response.data);
        },
        async  LoadDoctorvisitList(context,payload)
        {
         const data_link=payload.hostname+"reports/";
         let data_save = {'subtype':'MONTHLY_DOC_VISIT','hq_id':payload.hq_id,'monthyear':payload.monthyear	};
         var response = await  axios.post(data_link,data_save,payload.hostconfig);
         context.commit('LoadDoctorvisitList',response.data);
        },
        async  LoadMissedDoctorvisitList(context,payload)
        {
         const data_link=payload.hostname+"reports/";
         let data_save = {'subtype':'MONTHLY_MISSED_CALL_REPORT','hq_id':payload.hq_id,'monthyear':payload.monthyear	};
         var response = await  axios.post(data_link,data_save,payload.hostconfig);
         context.commit('LoadMissedDoctorvisitList',response.data);
        },
        async  LoadYearlyDoctorVisit(context,payload)
        {
         const data_link=payload.hostname+"reports/";
         let data_save = {'subtype':'YEARLY_DOC_VISIT','hq_id':payload.hq_id,'monthyear':payload.monthyear	};
         var response = await  axios.post(data_link,data_save,payload.hostconfig);
         context.commit('LoadYearlyDoctorVisit',response.data);
        },
        async  LoadDoctorList(context,payload)
        {
           const data_link=payload.hostname+"reports/";
           let data_save = {'subtype':payload.subtype,'hq_id':payload.hq_id	};
           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('LoadDoctorList',response.data);
        },
        async  LoadLocationList(context,payload)
        {
           const data_link=payload.hostname+"reports/";
           let data_save = {'subtype':payload.subtype,'hq_id':payload.hq_id	};
           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('LoadLocationList',response.data);
        },
        async  LoadRetailerList(context,payload)
        {
           const data_link=payload.hostname+"reports/";
           let data_save = {'subtype':payload.subtype,'hq_id':payload.hq_id	};
           var response = await  axios.post(data_link,data_save,payload.hostconfig);
           context.commit('LoadRetailerList',response.data);
        },
        async  LoadProductList(context,payload)
        {
         const data_link=payload.hostname+"reports/";
         //LOAD DOCTOR DCR
         let data_save = {'subtype':'PRODUCT_LIST'};
         var response = await  axios.post(data_link,data_save,payload.hostconfig);
         context.commit('LoadProductList',response.data);
        },

        //ALL OK REPORTS ARE HERE


         //MASTER SECTION SAVE AND APPROVAL
         async  SaveDoctor(context,payload)
         {
          const data_link=payload.hostname+"master_approval/";
          let data_save =
          {'subtype':'SAVE_DOCTOR','id':payload.id,'name':payload.name,'tbl_hierarchy_org_id':payload.tbl_hierarchy_org_id,'hq_id':payload.hq_id,'headq':payload.headq
          ,'doc_type':payload.doc_type,'qualification':payload.qualification,'speciality':payload.speciality,'contactno':payload.contactno
          ,'email':payload.email,'address':payload.address,'ACTIVITY_STATUS':payload.ACTIVITY_STATUS
          };
          console.log(data_save);
          var response = await  axios.post(data_link,data_save,payload.hostconfig);
          context.commit('SaveDoctor',response.data);
         },
         async  SaveRetailer(context,payload)
         {
          const data_link=payload.hostname+"master_approval/";
          let data_save =
          {'subtype':'SAVE_RETAILER','id':payload.id,'name':payload.retail_name,'tbl_hierarchy_org_id':payload.tbl_hierarchy_org_id,'hq_id':payload.hq_id,
          'retail_field':payload.retail_field ,'retail_address':payload.retail_address,'retail_contact_person':payload.retail_contact_person,
          'retail_contact':payload.retail_contact, 'status':payload.status
          };
          console.log(data_save);
          var response = await  axios.post(data_link,data_save,payload.hostconfig);
          context.commit('SaveRetailer',response.data);
         },
         async  SaveLocation(context,payload)
         {
          const data_link=payload.hostname+"master_approval/";
          let data_save =
          {'subtype':'SAVE_LOCATION','id':payload.id,'name':payload.name,
          'tbl_hierarchy_org_id':payload.tbl_hierarchy_org_id,'hq_id':payload.hq_id
          };
          console.log(data_save);
          var response = await  axios.post(data_link,data_save,payload.hostconfig);
          context.commit('SaveLocation',response.data);
         },
         //MASTER SECTION SAVE  END

         //APPROVAL SECTION
         async  LoadPendingApprovalList(context,payload)
         {
            const data_link=payload.hostname+"master_approval/";
            let data_save = {'subtype':payload.subtype,'tbl_hierarchy_org_id':payload.tbl_hierarchy_org_id,'hq_id':payload.hq_id,};
            var response = await  axios.post(data_link,data_save,payload.hostconfig);
            context.commit('LoadPendingApprovalList',response.data);
         },
         async  SaveApproval(context,payload)
         {
            const data_link=payload.hostname+"master_approval/";
            let data_save = {'subtype':payload.subtype_save,
            'tbl_hierarchy_org_id':payload.tbl_hierarchy_org_id,'hq_id':payload.hq_id,
            'id':payload.id,'approve_by_manager_status':payload.approve_by_manager_status};
            //console.log('param.saverecords :'+data_save.saverecords);
            var response = await  axios.post(data_link,data_save,payload.hostconfig);
            context.commit('SaveApproval',response.data);
         },

         //APPROVAL SECTION END



    },
    mutations:
    {

      //AITIANA SECTION
      login_aitiana(state,payload)
      {state.user=payload;},

      aitiana_load_doc_expiry_list(state,payload)
      {state.SaveServerResponse=payload;},

      aitiana_load_truck_doc_expiry_details(state,payload)
      {state.SaveServerResponse=payload;},

      //AITIANA SECTION END


      //ALL REPORT SECTION

      SaveApproval(state,payload)
      {state.SaveServerResponse2=payload;},

      LoadPendingApprovalList(state,payload)
      {state.SaveServerResponse=payload;},

      LoadLocationList(state,payload)
      {state.SaveServerResponse=payload;},


      LoadRetailerList(state,payload)
      {state.DoctorList.RETAILERS=payload;},

      LoadDoctorList(state,payload)
      {state.DoctorList=payload;},

      DCR_REPORT(state,payload)
      {state.Report_DCR.DCR_REPORT=payload;},

      LoadDoctorvisitList(state,payload)
      {state.DoctorList.DOCTORS=payload;},

      LoadMissedDoctorvisitList(state,payload)
      {state.DoctorList.DOCTORS=payload;},

      LoadYearlyDoctorVisit(state,payload)
      {state.DoctorList.DOCTORS=payload;},


      team_report(state,payload)
      {state.SaveServerResponse=payload;},

      team_report_detail(state,payload)
      {state.populate_data=payload;},

      LoadDoctorSaleList(state,payload)
      {
         state.DoctorSales.list=payload.list;
         state.DoctorSales.list_sum=payload.list_sum;
      },

      //ALL REPORT SECTION END

      SaveDoctor(state,payload)
      {state.SaveServerResponse=payload;},

      SaveRetailer(state,payload)
      {state.SaveServerResponse=payload;},

      SaveLocation(state,payload)
      {state.SaveServerResponse2=payload;},


      get_doctor_list(state,payload)
      {state.SaveServerResponse=payload;},

        login(state,payload)
        {state.user=payload;},

        checkjwt(state,payload)
        {
            //JWT_Token:'',
            state.JWT_Token_status = payload.JWT_Token_status;

            //state.SaveServerResponse=payload;
            //console.log('RESPONSE :'+payload);
        },
        checking_geotaging(state,payload)
        {state.geotaging_data=payload;},

        populate_data(state,payload)
        {state.populate_data=payload;},

        DCRdetail(state,payload)
        {state.DCRdetail.DCRdetail_data=payload;},

        SaveDCR(state,payload)
        {state.SaveServerResponse=payload;},

        //TOUR PLAN RELATED

        //NEW SEGMENT

            tour_plan_summary(state,payload)
            {state.SaveServerResponse=payload;},

            display_tour_plan_updated(state,payload)
            {state.tour_plan=payload;},

            SaveTourPlan_updated(state,payload)
            {state.SaveServerResponse=payload;},

            display_daily_plan(state,payload)
            {state.dailytour_plan=payload;},

            SavedailyPlan(state,payload)
            {state.SaveServerResponse=payload;},

            Load_Expense(state,payload)
            {state.SaveServerResponse=payload;},

         //NEW SEGMENT

      //   display_tour_plan(state,payload)
      //   {state.tour_plan=payload;},

        SaveTourPlan(state,payload)
        {state.SaveServerResponse=payload;},

        //TOUR PLAN RELATED

      //   RETAILER_DCR_LIST(state,payload)
      //   {state.Report_DCR.RETAILER_DCR_LIST=payload;},

      //   STOCKIST_DCR_LIST(state,payload)
      //   {state.Report_DCR.STOCKIST_DCR_LIST=payload;},

      //   OTHER_REPORT(state,payload)
      //   {state.Report_DCR.OTHER_REPORT=payload;},

        dcr_today(state,payload)
        {state.SaveServerResponse=payload;},

        proposalDoctors(state,payload)
        {state.SaveServerResponse=payload;},

        save_other_activity(state,payload)
        {state.SaveServerResponse=payload;},

        save_proposal(state,payload)
        {state.SaveServerResponse=payload;},

        save_doctor_sale(state,payload)
        {state.SaveServerResponse=payload;},

        save_monthly_sale(state,payload)
        {state.SaveServerResponse=payload;},

        doctor_wise_product_wise_sale_entry(state,payload)
        {
           state.DoctorSales.list=payload.list;
           state.DoctorSales.list_sum=payload.list_sum;
        },
        doctor_wise_product_wise_sale_entry_SAVE(state,payload)
        {state.SaveServerResponse=payload;},

        LoadProductList(state,payload)
        {state.SaveServerResponse=payload;},

    },
    getters:
    {
        GetAlluser(state)
        { return state.mobno;}

    }

})