<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-title slot="start">Login</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content  >

            <div style="margin-top: 50px;" >

                  <ion-card style="align-items: center;">
                      <ion-card-header  class="login-box"  >
                        <ion-card-subtitle> </ion-card-subtitle>
                        <ion-card-title  style="text-align: center;">{{login_param.company_name}}</ion-card-title>
                      </ion-card-header>

                      <ion-card-content>
                              <ion-item>
                                  <ion-label floating>Username</ion-label>
                                  <ion-input autocomplete="off" type="text" v-model="login_param.username" ></ion-input>
                              </ion-item>

                              <ion-item>
                                  <ion-label floating>Password</ion-label>
                                  <ion-input autocomplete="off" type="password" v-model="login_param.password" ></ion-input>
                              </ion-item>
                              <div padding style="text-align: center;"><ion-button @click="() => login()">Sign In</ion-button></div>
                              <!--
                              <ion-button expand="block" @click="setName">Set Name</ion-button>
                              <ion-button expand="block" @click="checkName">Get Name</ion-button>
                              <ion-button expand="block" @click="removeName">Remove Name</ion-button>
                              -->

                      </ion-card-content>
                </ion-card>

            </div>




        </ion-content>
    </ion-page>
</template>


<script>

import {IonPage,IonHeader,IonContent,IonToolbar,
IonTitle,IonItem,IonLabel,IonButton,
IonInput,loadingController,toastController,
IonCard,IonCardHeader,IonCardTitle,IonCardSubtitle,IonCardContent } from "@ionic/vue";


import config from '../config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex';
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive,onMounted } from 'vue';

import { Preferences } from '@capacitor/preferences';


export default defineComponent({

  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonItem,IonLabel,IonButton,IonInput,
    IonCard,IonCardHeader,IonCardTitle,IonCardSubtitle,IonCardContent

  },


  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

    const login_param=reactive({
      username:'',
      password:'',
      saved_uid:'',
      saved_pwd:'',
      tbl_hierarchy_org_id:'',
      tbl_designation_id:'',
      hostname:config.hostname,
      hostconfig: config.config,
      hostconfig_JWT_BASED: {},
      JWT_Token:'',
      company_name: config.company_name
    });



     const login= async ()=>
     {

            //console.log('username :'+login_param.username);
            //console.log('password :'+login_param.password);

            const loading = await loadingController
              .create({
                cssClass: 'my-custom-class',
                message: 'Please wait...',

              });
              await loading.present();

              try
              {

                    await store.dispatch('login',login_param);


                    if(store.state.user.login_status=='OK')
                    {

                      //console.log(login_param.JWT_Token);

                     // await store.dispatch('sample_post_api_with_jwt',login_param);

                      //AFTER LOGIN GOT THESE DATA
                      login_param.JWT_Token=store.state.user.login_key;
                      login_param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;
                      login_param.tbl_designation_id=store.state.user.tbl_designation_id;

                      // console.log('rr'+login_param.JWT_Token);
                      // await store.dispatch('checkjwt',login_param);
                      // console.log('state.JWT_Token_status'+store.state.JWT_Token_status);


                      loading.dismiss();
                      router.push('/DCREntry/Doctor/');
                      await store.dispatch('populate_data',login_param);
                      setName();

                    }
                    else
                    {
                          loading.dismiss();
                          //Toast START
                          const toast = await toastController
                            .create({
                              message: 'Invalid Login.Please Try again.',
                              duration: 3000
                            })
                          toast.present();
                          //Toast END
                          console.log("Invalid Login");

                    }
              }
              catch (e)
              {
                  //param.gps_error = e;
                    loading.dismiss()
                  //Toast START
                    const toast = await toastController
                      .create({
                        message: e,
                        duration: 3000
                      })
                    toast.present();
                    //Toast END
              }


      }


       //IF SHARED PREFERENCE DATA EXIST THEN DIRECTLY LOGIN

      //SHARED PREFERENCE

      const setName = async () => {
        await Preferences.set({key: 'tbl_hierarchy_org_id',value:store.state.user.tbl_hierarchy_org_id});
        await Preferences.set({key: 'tbl_designation_id',value: store.state.user.tbl_designation_id});
        await Preferences.set({key: 'name',value: store.state.user.name});
        await Preferences.set({key: 'saved_uid',value: login_param.username});
        await Preferences.set({key: 'saved_pwd',value: login_param.password});
      };

      const checkName = async () => {
        var saved_uid = await Preferences.get({ key: 'saved_uid' });
        //console.log('saved_uid  -- '+saved_uid.value);
        var saved_pwd = await Preferences.get({ key: 'saved_pwd' });
        var tbl_hierarchy_org_id = await Preferences.get({ key: 'tbl_hierarchy_org_id' });

        if(tbl_hierarchy_org_id.value!=null && saved_uid.value!=null && saved_pwd.value!=null)
        {
            login_param.username=saved_uid.value;
            login_param.password=saved_pwd.value;
            login();
        }
      };

      const removeName = async () => {
        await Preferences.remove({ key: 'saved_uid' });
        await Preferences.remove({ key: 'saved_pwd' });
        await Preferences.remove({ key: 'name' });
        await Preferences.remove({ key: 'tbl_hierarchy_org_id' });
        await Preferences.remove({ key: 'tbl_designation_id' });
      };

    //GOT THE CODE FROM CHATGPT OF MY LOGIN
    onMounted(() => {
      console.log('Component loaded');
      checkName();
      // Call any other initialization functions here
    });

    return{router,login,login_param,setName,checkName,removeName,};




  }
});
</script>