<template>

  <ion-page>

      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start" >
            <ion-menu-button menu="custom"></ion-menu-button>
            <ion-back-button ></ion-back-button>
          </ion-buttons>
          <ion-title>View Expense</ion-title>
        </ion-toolbar>
      </ion-header>

     <ion-content>
              <!-- {{$store.state.user.hqlist.hq_id}} -->
              <ion-item>
                <ion-label fixed>Select HQ</ion-label>
                  <ion-select v-model="param.hq_id">
                      <ion-select-option  v-for="hqs in $store.state.user.hqlist.hq_list" :key="hqs.hq_id" :value="hqs.hq_id">
                      {{hqs.hq_name}}
                      </ion-select-option>
                  </ion-select>

              </ion-item>

               <ion-item>
                 <ion-label fixed>From Date </ion-label>
                 <ion-label fixed>:{{param.fromdate}}</ion-label>
                 <ion-button fill="clear"  @click="formating_date('fromdate')">Select Date </ion-button>
              </ion-item>

              <ion-item>
                <ion-label fixed>To Date </ion-label>
                <ion-label fixed>:{{param.todate}}</ion-label>
                <ion-button fill="clear"  @click="formating_date('todate')">Select Date </ion-button>
              </ion-item>

              <ion-button expand="block" @click="Load_Expense">Load Expense</ion-button>

              <!-- {{$store.state.SaveServerResponse}} -->

              <div v-for="(expense_list,field_index) in $store.state.SaveServerResponse" :key="field_index">

                 <ion-card v-if="field_index != 'GRAND_TOTAL' " >

                    <ion-card-header   class="ion-card-manager" >
                      <!-- <img  src="assets/imgs/manager.png" style="float: right; " /> -->
                      <ion-card-title  >{{expense_list.trandate}}</ion-card-title>
                      <ion-card-subtitle color="ion-color-tertiary-shade">(Tour Type:{{expense_list.tour_type}})
                        <br><strong> Visited At:{{expense_list.mr_worked_fields}}
                        </strong></ion-card-subtitle>
                    </ion-card-header>

                     <ion-card-content>
                         <ion-item><ion-label >Fare:{{expense_list.fare}}</ion-label></ion-item>
                          <ion-item><ion-label >Allowance:{{expense_list.allow}}</ion-label> </ion-item>
                          <ion-item><ion-label >Other Expense :{{expense_list.otherexp_details}}</ion-label> </ion-item>
                          <ion-item><ion-label >Other Amt:{{expense_list.other_exp}}</ion-label> </ion-item>
                          <ion-item><ion-label >Day Total:{{expense_list.day_total}}</ion-label> </ion-item>
                    </ion-card-content>

                 </ion-card>

                 <ion-card v-if="field_index == 'GRAND_TOTAL' " >

                    <ion-card-header    class="ion-card-mr" >
                      <!-- <img  src="assets/imgs/manager.png" style="float: right; " /> -->
                      <ion-card-title  >Grand Total</ion-card-title>
                    </ion-card-header>

                     <ion-card-content>
                         <ion-item><ion-label >Fare:{{expense_list.fare}}</ion-label></ion-item>
                          <ion-item><ion-label >Allowance:{{expense_list.allow}}</ion-label> </ion-item>
                          <ion-item><ion-label >Other Expense :{{expense_list.otherexp_details}}</ion-label> </ion-item>
                          <ion-item><ion-label >Other Amt:{{expense_list.other_exp}}</ion-label> </ion-item>
                          <ion-item><ion-label >Grand Total :{{expense_list.day_total}}</ion-label> </ion-item>
                    </ion-card-content>
                 </ion-card>


              </div>


    </ion-content>

  </ion-page>
</template>

<script>

import {
  IonPage,IonHeader,IonContent,IonToolbar,IonTitle,IonCard, IonCardHeader,IonCardSubtitle,
  IonItem, IonSelect,IonSelectOption,
  IonButton,
  loadingController,
  toastController,modalController,
   }
  from "@ionic/vue";


import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex'
//import {computed} from 'vue'
//import {ref} from 'vue'
import {reactive} from 'vue'
//import TourPlanDetail from '@/components/tour_section/TourPlanDetail.vue';
import datepickermodal from '@/components/others/datepickermodal.vue';

export default defineComponent({

  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonItem,IonSelect,IonSelectOption,IonButton,
    IonCard, IonCardHeader,IonCardSubtitle,

  },


  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

    const param=reactive({
      currentdate:'',
      fromdate:'',
      todate:'',
      tbl_hierarchy_org_id:'',
      hq_id:'',
      tour_plan_data:'',
      hostname:config.hostname,
      hostconfig: config.config
    });


      var initial_date=  new Date().toLocaleString();  //06/04/2022
      initial_date=initial_date.slice(6, 10)+'-'+initial_date.slice(3, 5)+'-'+initial_date.slice(0, 2);
      param.fromdate=param.todate=initial_date;
      param.hq_id=store.state.user.tbl_hierarchy_org_id;

      //formating date function
      const formating_date= async (date_picker_name)=>
      {

           const modal = await modalController
            .create({
              component: datepickermodal,
              cssClass: 'my-custom-class',
              componentProps: {
                title: 'Date Pick',
                date_picker_name: date_picker_name
              },
            });

            modal.onDidDismiss().then((data) => {
                getdate(data,date_picker_name);
            });

          return modal.present();
      }

      const getdate= async (dates,date_picker_name)=>
      {
          console.log('received date:'+dates);
          if(date_picker_name=='fromdate')
          {param.fromdate=dates.data;}

          if(date_picker_name=='todate')
          {param.todate=dates.data;}

      }

    //formating date function END


    //LOAD TOUR PLAN

     const Load_Expense= async ()=>{

      //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Loading ..... Please wait...',

        });
        await loading.present();


        //CREATE PAYLOAD
        param.tbl_hierarchy_org_id=store.state.user.tbl_hierarchy_org_id;

         // PAYLOAD END

        console.log(param);

      //LOADING FUNCTION
      await store.dispatch('Load_Expense',param);
      loading.dismiss();

      //  //Toast START
      const toast = await toastController
        .create({
          message: 'Expense Loaded..',
          duration: 1000
        })
      toast.present();
      // //Toast END

    }
    //LOAD TOUR PLAN END

    return{router,store,param,formating_date,Load_Expense,getdate};


  }
});
</script>