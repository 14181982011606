<template>
  <ion-page>

   <ion-header>
      <ion-toolbar>
        <ion-title>Product List</ion-title>
        <ion-buttons slot="start">
          <ion-menu-button menu="custom"></ion-menu-button>
        </ion-buttons>
        <img  slot="end" @click="LoadList" src="assets/imgs/reload_small.png"  />
         <!-- <ion-button class="reloadbutton" slot="end"  @click="LoadList">

         </ion-button> -->
      </ion-toolbar>
    </ion-header>

        <ion-item>
            <ion-button expand="block" @click="LoadList">View</ion-button>
       </ion-item>

        <!-- {{$store.state.SaveServerResponse}} -->
    <ion-content class="ion-padding">

             <ion-item-divider><ion-label >LIST OF PRODUCT</ion-label></ion-item-divider>

                 <ion-item >
                    <ion-label>Product Name</ion-label>
                 </ion-item>
                    <ion-item v-for="(sample_list,sample_index)  in $store.state.SaveServerResponse" :key="sample_list.id">
                                <ion-label @click="load_product_details(sample_index)">
                                <!-- {{sample_list}} -->
                                {{sample_index+1}}-{{sample_list.brand_name}}
                                </ion-label>
                  </ion-item>

    </ion-content>

  </ion-page>
</template>

<script>

//IonButton,
import {
 IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonItem,
 IonButtons,IonMenuButton,IonButton,
     IonLabel,
   IonItemDivider,loadingController, toastController,modalController,
} from '@ionic/vue';

import config from '@/config.js'
import {defineComponent } from "@vue/runtime-core";
import {useRouter} from 'vue-router';
import { useStore  } from 'vuex';
import {reactive} from 'vue';
import product_details from '@/components/reports/product_details.vue';


export default defineComponent({
  components: { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonItem,
   IonButtons,IonMenuButton,IonButton,
  IonLabel,IonItemDivider  },

  setup()
  {
    //VARIABLE PORTION
    const router=useRouter();
    const store = useStore();

     const param=reactive({
          id:'',
          hostname:config.hostname,
          hostconfig: config.config
    });



    const LoadList= async ()=>{


        const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',

        });
        await loading.present();
        await store.dispatch('LoadProductList',param);
        loading.dismiss();
    }

     const load_product_details= async (product_indx)=>{

       //LOADING SECTION
       const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',

        });
        await loading.present();

      store.state.general_purpose_indx=product_indx;
      store.state.general_purpose_image=config.domain_name+'uploads/'+store.state.SaveServerResponse[product_indx]['brand_image'];

      try{

         // await store.dispatch('LoadProduct',param);
          loading.dismiss();

          //LOAD DCR DETAIL MODAL POPUP
          var title="Product";
          const modal = await modalController
          .create({
            component: product_details,
            cssClass: 'my-custom-class',
            componentProps: {
              title: title,
            },
          });

          modal.onDidDismiss().then((data) => {
            console.log(data);
             // getdate(data,date_picker_name);
          });

        return modal.present();


         // router.push('/DCRdetail/');
        }
        catch(e)
        {

              loading.dismiss()
              //Toast START
                const toast = await toastController
                  .create({
                    message: e,
                    duration: 3000
                  })
                toast.present();
        }

    }


    return{router,store,param,load_product_details,LoadList};
  }

});
</script>