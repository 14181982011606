<template>
  <ion-page>

    <!-- <ion-header>
      <ion-toolbar>
        <ion-title>DCR Entry</ion-title>
        <ion-buttons slot="start">
          <ion-menu-button menu="custom">Menu</ion-menu-button>
           <ion-back-button ></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header> -->

    <ion-content>
      <ion-tabs>
        <ion-router-outlet></ion-router-outlet>
        <ion-tab-bar slot="bottom" >
           <!-- <ion-tab-button tab="Doctor" href="/DCREntry/Doctor" @click="() => router.push('/DCREntry/Doctor/')"> -->
          <ion-tab-button  tab="Doctor"   @click="() => router.push('/DCREntry/Doctor/')">
            <!-- <ion-icon :icon="triangle" /> -->
             <!-- <img  src="assets/imgs/doctor_icon.png"  />Doctor -->
             <ion-icon :icon="ellipse" />
             <ion-label>Doctor</ion-label>

          </ion-tab-button>

          <ion-tab-button tab="Retailer" @click="() => router.push('/DCREntry/Retailer/')"  >
            <!-- <ion-icon :icon="ellipse" /> -->
              <!-- <img  src="assets/imgs/retail.png"  /> -->
              <ion-icon :icon="ellipse" />
              <ion-label>Retailer</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="Stockist" @click="() => router.push('/DCREntry/Stockist/')"  >
            <ion-icon :icon="ellipse" />
            <ion-label>Stockist</ion-label>
          </ion-tab-button>

           <ion-tab-button tab="Other" @click="() => router.push('/DCREntry/Other/')"  >
            <ion-icon :icon="ellipse" />
            <ion-label>Other</ion-label>
          </ion-tab-button>

           <ion-tab-button tab="dcr_today" @click="() => router.push('/DCREntry/dcr_today/')"  >
            <ion-icon :icon="ellipse" />
            <ion-label>DCR</ion-label>
          </ion-tab-button>

          <!-- <ion-tab-button tab="tab3" href="/tabs/tab3">
            <ion-icon :icon="square" />
            <ion-label>Tab 3</ion-label>
          </ion-tab-button> -->


        </ion-tab-bar>
      </ion-tabs>
    </ion-content>
  </ion-page>
</template>

<script >
  import {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonContent,
    IonLabel,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  } from '@ionic/vue';
  import { ellipse, square, triangle } from 'ionicons/icons';
  import {useRouter} from 'vue-router';

  export default {
    name: 'DCREntry',
    components: {
      IonContent,
      IonLabel,
      IonTabs,
      IonTabBar,
      IonTabButton,
      IonIcon,
      IonPage,
      IonRouterOutlet,
    },
    setup() {
      const router=useRouter();


      return {router,ellipse,square,triangle,};



    },
  };
</script>