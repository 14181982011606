<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{date_picker_name }}--{{titlename}}</ion-title>
            <ion-buttons slot="primary">
                <ion-button @click="closemodal">X</ion-button>
            </ion-buttons>
    </ion-toolbar>
  </ion-header>

 <!-- {{$store.state.tour_plan.tour_plan[tourplan_index]}} -->
  <ion-content class="ion-padding">
          <!-- <ion-item>
                <ion-datetime locale="en-GB"></ion-datetime>
          </ion-item> -->
           <ion-datetime v-model="param.displaydate"  displayFormat="YYYY-MM-DD" @click="formating_date"></ion-datetime>
  </ion-content>





</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar ,IonButtons,
IonButton,IonDatetime,
 modalController} from '@ionic/vue';
//import config from '../config.js'
import { defineComponent } from 'vue';
import { useStore  } from 'vuex'
import {reactive} from 'vue'

export default defineComponent({
  name: 'Modal',
  props: {
    title: { type: String, default: 'Super Modal' },
    tourplan_index: { type: String  , default: 'Super Modal' },
    date_picker_name: { type: String  , default: '' },
    getdate:Function
  },
  emits:[''],
  components: { IonContent, IonHeader, IonTitle, IonToolbar,IonButtons,IonButton,IonDatetime},
  setup(props)
  {

    const store = useStore();
    const param=reactive({displaydate:'',titlename:''});
    console.log('Prop val : '+props.date_picker_name);


      const formating_date= async ()=>
      {
         if(param.displaydate!='')
         {param.displaydate=param.displaydate.slice(0, 10);}

         if(props.date_picker_name=='fromdate')
         {store.state.fromdate=param.displaydate;}

         if(props.date_picker_name=='todate')
         {store.state.todate=param.displaydate;}

         if(props.date_picker_name=='trandate')
         {store.state.trandate=param.displaydate;}


         //context.emit('getdate',param.displaydate);

      }

        const closemodal= async ()=>
        {

          if(param.displaydate!='')
          {param.displaydate=param.displaydate.slice(0, 10);}

          await modalController.dismiss(param.displaydate);

        }


       return { content: 'Content123654',closemodal,formating_date ,store,param}
  },


});
</script>